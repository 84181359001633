import utilities from "../../support/utilities";
var trigger = require("support/utilities").trigger;

export default function PianoMessageFlow(tpObject) {
	this.tpObject = tpObject;
}

PianoMessageFlow.prototype = {
	
	handle: function() {
		// Placeholder to listen messages from Iframe
		window.onmessage = function(e) {
			var onboarding = document.getElementById("subscriber-onboarding")
			var bodyStyle = document.getElementsByTagName("body")[0]
			
			if (e.data.type == "subscriber-onboarding") {
				onboarding.className += " subs-onboarding-overlay";
				bodyStyle.className += " overflow-for-subs-onboarding";
			}
			
			if (e.data.type == "close-subscriber-onboarding") {
				bodyStyle.className = bodyStyle.className.replace("overflow-for-subs-onboarding", "");
				onboarding.className = onboarding.className.replace("subs-onboarding-overlay","");
				utilities.deleteCookie("_pc_pso");
			}
				
			if (e.data.type == "link") {
				window.open(e.data.destiny, e.data.target)
			}

			if (e.data.type == "analytics") {
				trigger(e.data.eventName, {"screenName": e.data.screenName, "subTier": utilities.readCookie("_pc_pso")});
			}
		}

	}
}
