import React, {Component} from 'react';

class PasswordControl extends Component {
	constructor(props) {
		super(props);
		this.state = { show: false };
	}
	
	toggle() {
		this.setState({ show: !this.state.show });
	}
	
	render() {
		if(this.state.show) {
			return (
				<div class={this.props.error == false ? "password-input" : "password-input input-error"}>
					<div class="password-option view-option">
						<input type="text" name="password"
									 class="password-text"
									 value={this.props.password} onChange={this.props.handleChange }/>
						<img class="password-hide" src="/resources/css/images/password-hide.svg" onClick={ this.toggle.bind(this) } />
					</div>
				</div>
			);
		} else {
			return (
				<div class={this.props.error == false ? "password-input" : "password-input input-error"}>
					<div class="password-option hide-option">
						<input type="password" name="password"
									 class="password"
									 value={this.props.password} onChange={this.props.handleChange}/>
						<img class="password-show"
								 src="/resources/css/images/password-view.svg" onClick={ this.toggle.bind(this) } />
					</div>
				</div>
			);
		}
	}
}

export default PasswordControl;
