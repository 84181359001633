import React, { Component, useState, useEffect } from 'react';
import withFieldValidation from "../Form/withFieldValidation";
import utilities from "../../support/utilities";
import { NoValidation } from './FieldValidators';

function OptInField(props) {
	const [isDomUser, setDomUser] = useState(false)
	useEffect(() => {
		utilities.determineIfUsCaDomesticUser((isDomUser) => {
			setDomUser(isDomUser)
		})
	}, []);
	if(isDomUser) {
		return (
			<label class="sign-up-email flex-row">
				<span className="checkbox">
					<input type="checkbox" 
						class="mbn"
						name="optIn"
						defaultChecked={true}
						value={props.value}
						{...props}/>
				</span>
				<span>By registering, you agree to receive occasional emails from HBR. You may unsubscribe at any time.</span>
			</label>
		)
	} else {
		return (
			<label class="sign-up-email flex-row">
				<span className="checkbox">
					<input type="checkbox" 
						class="mbn"
						name="optIn"
						value={props.value}
						{...props}/>
				</span>
				<span> Sign me up for HBR email. You can unsubscribe at any time.<a href="/privacy-policy" target="_blank" > View our privacy policy.</a></span>
			</label>
		)
	}
}

export default withFieldValidation(
	OptInField,
	NoValidation
);
