import React, { Component} from "react";

function ConfirmationBanner({emailAddress}) {
	return (
		<div class="confirmation-banner do-not-show">
			<h2 class="message">Your account has been created, <strong>{emailAddress}</strong></h2>
		</div>
	)
}

export default ConfirmationBanner;
