import React, { Component, useEffect, useState } from "react";
import FieldGroup from '../Form/FieldGroup';
import CaptChaField from '../Form/CaptchaField';
import SubmitButton from '../Form/SubmitButton';
import OptInField from '../Form/OptInField'
import ErrorMessage from "../Form/ErrorMessage";
import { RequiredTextField, RequiredEmailField, RequiredPasswordField } from "../Form/InputField";
import { Screen } from "./Commons";
import ConditionalLoader from "../Commons/ConditionalLoader";
import registrationStore from "../../stores/authentication/registration";
import captchaStore from "../../stores/authentication/captcha";
import CaptchaService, {CaptchaActions, CaptchaKeys} from "../../services/captcha-service";
import SignIn from "../../components/sign-in/sign-in";
import { trigger } from "../../support/utilities";
import user from "../../models/user";
import utilities from "../../support/utilities";

function RegistrationForm(props) {
	const isValid = registrationStore((state) => state.valid)
	const form = registrationStore((state) => state.form)
	const isLoading = registrationStore((state) => state.isLoading)
	const setLoading = registrationStore((state) => state.setLoading)
	const updateError = registrationStore((state) => state.updateError)
	const updateInitialValue = registrationStore((state) => state.updateInitialValue)
	const reset = registrationStore((state) => state.reset)
	const {widgetID, updateValue} = captchaStore((state) => state)
	const authenticate = (evt) => {
		evt.preventDefault();
		setLoading(true)
		var payload = form
		if (form.hasOwnProperty("reCaptchaToken") && form.reCaptchaToken == "*") {
			const {reCaptchaToken, ...formWithoutCaptcha} = form
			payload = formWithoutCaptcha
		}
		user.pianoRegister(payload)
			.then(
				({user, header}) => {
					if (header) {
						// See HBROPS-3079 for this flow
						SignIn.signInPromise.resolve(user);
						$("body").trigger("piano:registration-success");
					} else {
						SignIn.signInPromise.resolve(user);
						if (user) {
							user.optIn = payload.optIn;
						}
						trigger("metrics:registration_success", {user: user});
						$("body").trigger("register:success", ["Registration - Modal", user]);
						$("body").trigger("piano:reg-success");
					}
					setLoading(false)

					// for paywall registration append query param registration=success in page URL
					if (props.fromPaywall == "true") {
						const hasParams = !!window.location.search;
						const url = window.location.href + `${hasParams ? "&" : "?"}registration=success`;
						window.location.assign(url);
					} else {
						props.sendTo(Screen.PROFILE_SETTINGS)
					}
				}, 
				(data) => {
					setLoading(false)
					if(data.status == 409) {
						updateError("emailAddress", (
							<span id={data.responseJSON.message}>
							   That username is unavailable. <a onClick={(ev) => {ev.preventDefault(); props.sendTo(Screen.SIGN_IN)}}>Sign in</a> or choose another email address.
						   	</span>
					   ), ["emailAddress"])
					} else if(data.status == 422) {
						updateError("emailAddress", (
							<span id={data.responseJSON.message}>
								That username is unavailable. <a onClick={(ev) => {ev.preventDefault(); props.sendTo(Screen.SIGN_IN)}}>Sign in</a> or choose another email address.
							</span>
						), ["emailAddress"])
					} else {
						var errorMessage = data.responseJSON.message
						var field = "password"
						if(data.responseJSON.hasOwnProperty("errors")) {
							errorMessage = data.responseJSON.errors[0].errorMessage
							errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1) + "."
							field = data.responseJSON.errors[0].field	
						}
						updateError(field, (
							<span id={data.responseJSON.message}>
							   {errorMessage}
						   	</span>
						), [field])
					}
					trigger("metrics:registration_failure");
					$("body").trigger("piano:reg-failure");
					CaptchaService.isCaptchaEnabled()
						.then((isEnabled) => {
							if (isEnabled) {
								grecaptcha.enterprise.ready(() => {
									grecaptcha.enterprise.execute(CaptchaKeys.score, {action: CaptchaActions.REGISTER_MODAL});
								});
								grecaptcha.enterprise.reset(widgetID);
								updateValue("captchaToken", null)
								updateError(
									"reCaptchaToken", 
									"This field is required.",
									["reCaptchaToken"]
								)
							}
						});
				}
			);
	}
	useEffect(() => {
		reset
		utilities.determineIfUsCaDomesticUser((isDomUser) => {
			updateInitialValue("optIn", isDomUser)
		})
	}, []);
	return (
        <form class="form auth-content" onSubmit={authenticate} data-metric-tag="registration-form" id="modalRegistrationForm">
            <FieldGroup withLabel="First name" for="firstName" class="half">
				<RequiredTextField
					name="firstName"
					{...props["firstName"]}
					store={registrationStore}
					/>
			</FieldGroup>
            <FieldGroup withLabel="Last name" for="lastName" class="half">
				<RequiredTextField
					name="lastName"
					{...props["lastName"]}
					store={registrationStore}
					/>
			</FieldGroup>
			<FieldGroup withLabel="Email" for="emailAddress">
				<RequiredEmailField
					id="emailAddress"
					name="emailAddress"
					{...props["emailAddress"]}
					store={registrationStore}
					/>
			</FieldGroup>
			<FieldGroup withLabel="Password" for="password">
				<RequiredPasswordField 
					id="password"
					name="password"
					{...props["password"]}
					store={registrationStore}
					showRequirments={true}
					/>
			</FieldGroup>
			<FieldGroup>
				<OptInField
					id="optIn"
					name="optIn"
					{...props["optIn"]}
					store={registrationStore}
				/>
			</FieldGroup>
			<FieldGroup>
				<CaptChaField 
					id="reCaptchaToken"
					name="reCaptchaToken"
					{...props["reCaptchaToken"]}
					store={registrationStore}
					component="registration"/>
			</FieldGroup>
			<FieldGroup>
				<ConditionalLoader isEnabled={isLoading}>
					<SubmitButton
						value="Create Account"
						className="auth-content-submit__enabled"
						enabled={isValid}
					/>
				</ConditionalLoader>
			</FieldGroup>
		</form>
	)
}

export default RegistrationForm
