import React, { Component, useEffect } from "react";
import FieldGroup from '../Form/FieldGroup';
import CaptChaField from '../Form/CaptchaField';
import SubmitButton from '../Form/SubmitButton';
import ErrorMessage from "../Form/ErrorMessage";
import { RequiredEmailField, RequiredPasswordField } from "../Form/InputField";
import ConditionalLoader from "../Commons/ConditionalLoader";
import passwordResetStore from "../../stores/authentication/password-reset";
import auth from "../../services/auth";

function ForgotPasswordForm(props) {
	const isValid = passwordResetStore((state) => state.valid)
	const form = passwordResetStore((state) => state.form)
	const isLoading = passwordResetStore((state) => state.isLoading)
	const setLoading = passwordResetStore((state) => state.setLoading)
	const reset = passwordResetStore((state) => state.reset)
	const onSubmit = (evt) => {
		evt.preventDefault();
		setLoading(true)
		auth.verifyForgotPassword(form.email, form.captcha)
			.then(function () {
				props.petitionAcknowledged()
				setLoading(false)
			}, function(data) {
				if (data.status == 423) {
					props.lockoutError()
					setLoading(false)
				}
			});
	}
	useEffect(reset, []);
	return (
		<form class="form auth-content" onSubmit={onSubmit} id="modalForgotPasswordForm">
			<FieldGroup withLabel="Email" for="email">
				<RequiredEmailField
					name="email"
					{...props["email"]}
					store={passwordResetStore}
					/>
			</FieldGroup>
			<FieldGroup>
				<CaptChaField 
					id="captcha"
					name="captcha"
					{...props["captcha"]}
					store={passwordResetStore}
					component="registration"
					/>
			</FieldGroup>
			<FieldGroup>
				<ConditionalLoader isEnabled={isLoading}>
					<SubmitButton
						value="Continue"
						enabled={isValid}
					/>
				</ConditionalLoader>
			</FieldGroup>
		</form>
	)
}

export default ForgotPasswordForm;
