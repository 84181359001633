import React, { Component } from "react";
import { Screen } from "../Authentication/Commons";
import SignInForm from "../Authentication/SignInForm";

class SignInComponent extends Component {
	constructor(props) {
		super(props)
	}
	
	render() {
		return (
			<div class="paywall-content-wrapper small justify-center">
				<div class="auth-content text-center sign-in-modal">
					<h2 class="title with-mbxs">Sign In</h2>
					<div class="cta create-account">
						Don&apos;t have an account? <a href="#" onClick={(ev) => {ev.preventDefault(); this.props.sendTo(Screen.REGISTRATION)}}>Create one.</a>
					</div>
					<div class="wrapper with-mts">
						<SignInForm
							sendTo={this.props.sendTo.bind(this)}
							onClose={this.props.onClose.bind(this)}
							fromPaywall = {this.props.fromPaywall} />
					</div>
					<div class="cta forgot-password">
						<a href="#" class="" onClick={(ev) => {ev.preventDefault(); this.props.sendTo(Screen.FORGOT_PASSWORD)}}>Forgot your password?</a>
					</div>
				</div>
			</div>
		)
	}
}

export default SignInComponent;
