<h6 class="flyout-share-title flyout-header">&quot;{{ shareTitle }}&quot;</h6>
<div class="field-container">
	<label for="direct-url">URL</label>
	<input js-target="share-url" id="direct-url" type="url" value="{{ setFullPath(shareUrl) }}" readonly="readonly" onclick="select()">
</div>
<hr>
<!-- TAB NAV -->
<ul class="flyout-share-chooser font-size-small" js-target="share-flyout-nav">
	<li class="active" js-target="share-flyout-nav-item" data-share-network="facebook"><a href="#">Facebook</a></li>
	<li js-target="share-flyout-nav-item" data-share-network="twitter"><a href="#">X Corp.</a></li>
	<li js-target="share-flyout-nav-item" data-share-network="linkedin"><a href="#">LinkedIn</a></li>
</ul>
<div js-target="flyout-share-content">
	{{ subTemplate }}
</div>
