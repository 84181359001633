import template from "./template";
import {videoEmbedCode} from "services/video";

exports.render = (data) => Promise.resolve(template(data));

exports.getData = (el) => {
	const item = el.closest("stream-item");
	return {name: item.data("title"), code: videoEmbedCode({id: item.data("video-id"), name: item.data("title")})};
};

exports.getOverrideOptions = () => ({requiresSignIn: false});
