<h6 class="flyout-header">Save to:</h6>

<ul class="flyout-list">
	<li>
		<div class="right hbr-checkbox" aria-label="My Library" role="checkbox">
			<input type="checkbox" checked="checked" disabled="disabled" value="1" id="save001" name="" />
			<label tabindex="0" for="save001"></label>
		</div>
		<label  for="save001">My Library</label>
	</li>
	{% readingLists.forEach(function(list) { %}
		{% if (!list.readOnly) { %}
	<li>
		<div class="right hbr-checkbox" aria-label={{list.name}} role="checkbox">
			<input type="checkbox" value="2" id="save-{{list.id}}" name="" js-target="save-list-checkbox" data-list-name="{{list.name}}" data-list-id="{{list.id}}"/>
			<label tabindex="0" for="save-{{list.id}}"></label>
		</div>
		<label  class="text-overflow" for="save-{{list.id}}" js-target="folder-list-name">{{ list.name }}</label>
	</li>
		{% } %}
	{% }); %}
	<li>
		<a href="#" js-target="save-new-list" tabindex="-1">Create New Folder <i tabindex="0" class="icon icon-plus-square-o"></i></a>
		<div class="right hbr-checkbox is-hidden" js-target="save-new-list-form" aria-label="Create New Folder" role="checkbox">
			<input type="checkbox" disabled="disabled" checked="checked" value="3" name="" id="new-list"/>
			<label tabindex="0" for="new-list" js-target="remove-new-list-checkbox"></label>
		</div>
		<div js-target="save-new-list-form" class="field-container is-hidden">
			<label for="list-form-name">Folder Title</label>
			<input id="list-form-name" type="text" js-target="list-form-name" name="list-form-name" placeholder="Enter Folder Name" required aria-required="true">
			<label for="list-form-description">Folder Description</label>
			<textarea id="list-form-description" rows="3" cols="24" js-target="list-form-description" placeholder="Enter Folder Description"></textarea>
		</div>
	</li>
</ul>

<button class="button--black expand" js-target="save-submit">Save</button>
<label class="error is-hidden" js-target="save-new-list-form-error">There was an error while processing your request, please try again later.</label>
