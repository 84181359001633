var popularTopicJSON = [
	{
		"title": "Managing People",
		"link": "/topic/subject/managing-people",
		"body": "It's harder than it looks."
	},
	{
		"title": "Leadership",
		"link": "/topic/subject/leadership",
		"body": "It takes a lifetime of courage and practice."
	},
	{
		"title": "Strategy",
		"link": "/topic/subject/strategy",
		"body": "Stay ahead of the competition."
	},
	{
		"title": "Business Communication",
		"link": "/topic/subject/business-communication",
		"body": "Get your message across."
	},
	{
		"title": "Managing Yourself",
		"link": "/topic/subject/managing-yourself",
		"body": "Be better at work."
	},
	{
		"title": "Innovation",
		"link": "/topic/subject/innovation",
		"body": "The difficult discipline of newness."
	},
	{
		"title": "Technology and analytics",
		"link": "/topic/subject/technology-and-analytics",
		"body": "Mastering a changing world."
	},
	{
		"title": "Entrepreneurship",
		"link": "/topic/subject/entrepreneurship",
		"body": "In start-ups and established companies."
	},
	{
		"title": "Marketing",
		"link": "/topic/subject/marketing",
		"body": "How well do you know your customers?"
	},
	{
		"title": "Career Planning",
		"link": "/topic/subject/career-planning",
		"body": "Where do you want to be in five years?"
	},
	{
		"title": "Organizational Culture",
		"link": "/topic/subject/organizational-culture",
		"body": "Building a company employees love."
	},
	{
		"title": "Human Resource Management",
		"link": "/topic/subject/human-resource-management",
		"body": "From recruitment to retirement. "
	},
	{
		"title": "Decision Making and Problem Solving",
		"link": "/topic/subject/decision-making-and-problem-solving",
		"body": "Balancing data, experience, and intuition."
	},
	{
		"title": "Personal Productivity",
		"link": "/topic/subject/personal-productivity",
		"body": "We all need to get more done."
	},
	{
		"title": "Work life balance",
		"link": "/topic/subject/work-life-balance",
		"body": "Sanity is possible."
	}
];

var getTopics = function() {
	return popularTopicJSON;
};

export default {
	getTopics: getTopics
};
