import $ from "jquery";
import _memoize from "lodash-amd/modern/functions/memoize";

const accountId = "2071817190001";
const policyKey = "BCpkADawqM0_BkIpNQBw-Lbk2C0lOUfgcCL7h0IEpm58jxNabE-P64QU7qsOjNtrPYlco4OmmJfxnyYzdxLQLWXUkfgnAJdn5tFViIaf5fd5nAqtMdLSLO0NeT6i0pBIG4ZtNsdF278WBMJH";

const apiGet = (suffix) => $.ajax("https://edge.api.brightcove.com/playback/v1/accounts/" + accountId + suffix, {headers: {"Accept": "application/json;pk=" + policyKey}});

export const getPlaylist = _memoize((id) => apiGet("/playlists/" + id));
export const getVideo = _memoize((id) => apiGet("/videos/" + id));

const seoName = (name) => name.toLowerCase().replace(/[^\w ]/g, "").replace(/ /g, "-");

export function videoUrl(video) {
	var url = `/video/${video.id}/${seoName(video.name)}`;
	if (window.location.search.match(/(\?|&)playlist=[a-zA-Z0-9\-]+/)) {
		url = url + window.location.search;
	}
	return url;
}

export function videoEmbedCode(video) {
	return `<div class='blog-video-embed'><iframe width='480' height='270' style='overflow:hidden;' scrolling='no' src='${window.location.origin}/video/embed/${video.id}/${seoName(video.name)}' frameborder='0' allowfullscreen allowtransparency></iframe></div>`;
}

/**
 * isSubOnly
 * evaluate whether a video is subscriber only based upon tag metadata
 * @param {Object} video - video object containing metadata
 * @returns {boolean}
 */
export function isSubOnly(video) {
	const tags = video.tags;

	if($.inArray("harvard business review group>sub", tags) > -1) {
		return true;
	}else{
		return false;
	}
}