import $ from "jquery";

// Component mixin.
import mixinComponent from "../../mixins/component";
import {generateLinkAndUpdateCount} from "../../services/hbr/gift-article";

var selector = "social-network-sharing";

export default function SocialNetworkSharing($el) {
	this.initializeAsComponent($el);
}

SocialNetworkSharing.prototype = {
	facebookShare: function(ev) {
		ev.preventDefault();

		let current_page = $("[js-target='share-url']").val();
		if (!current_page) {
			current_page = window.location.href;
		}

		FB.ui({
			method: "share_open_graph",
			action_type: "og.likes",
			action_properties: JSON.stringify({
				object: current_page,
			})
		}, function(response) {});
	},

	twitterShare: function(ev) {
		let current_page = $("[js-target='share-url']").val();
		if (!current_page) {
			 current_page = window.location.href;
		}
		let currentUrl = encodeURIComponent(location.href),
			twitterIntent = "https://twitter.com/intent/tweet?" +
			 "&original_referer=" + currentUrl +
			 "&ref_src=twsrc%5Etfw" +
			 "&related=twitterapi%2Ctwitter" +
			 "&tw_p=tweetbutton" +
			 "&url=" + current_page;
		return twitterIntent;
	},

	linkedInShare: function(ev) {
		let url = "",
			linkedInSharer = "https://www.linkedin.com/sharing/share-offsite?url=",
			current_page = $("[js-target='share-url']").val();
		
		if (current_page === undefined) {
			current_page = location.href;
		}
		url = linkedInSharer + encodeURIComponent(current_page);
		/* get logged into Linkedin's REST API v2 and share the Post */
		SocialNetworkSharing.prototype.popup(url);
	},

	popup: function(url) {
		var l = (screen.width - 700) / 2, t = (screen.height - 450) / 2;
		window.open(url, "mywindow", "menubar=1,scrollbars=1,resizable=1,width=700,height=450,left=" + l + ",top=" + t);
	},

	attachEventHandlers: function() {
		let shouldCopyGift = true;
		$("[js-target='facebook-share']").on("click.social", SocialNetworkSharing.prototype.facebookShare);
		$("[js-target='twitter-share']").attr("href", SocialNetworkSharing.prototype.twitterShare());
		$("[js-target='linkedin-share']").on("click.social", SocialNetworkSharing.prototype.linkedInShare);

		// This takes care of closing the gifting popup when cross icon is clicked
		$(".gift-icon .cross-icon").on("click", function() {
			// Find the closest ancestor li with class .gift-icon and find the checkbox inside it
			let checkbox = $(this).closest(".gift-icon").find("input[type='checkbox']");
			// Toggle the checkbox
			checkbox.prop("checked", !checkbox.prop("checked"));
		});

		$(".gift-icon input[type='checkbox']").on("click", function() {
			if(shouldCopyGift) {
				$(".gift-icon .copy-button button").show();
			} else {
				$(".gift-icon .copy-button button").hide();
			}
			$(".gift-icon .copy-button .success-icon").hide();
		});

		$(".gift-icon .copy-button button").on("click", async function () {
			const copyButton = $(".gift-icon .copy-button button");
			const successIcon = $(".gift-icon .copy-button .success-icon");
			const currentCountEl = $(".tooltip-content .remaining-count");

			// Initially hide any buttons to show only spinner
			copyButton.hide();
			successIcon.hide();

			// Grab spinner and render it.
			const spinner = $(".gift-tooltip .tooltip-body .copy-button .icon.icon-spinner");
			spinner.each(function (index, element) {
				$(element).show();
			});

			let response = null;
			try {
				// execute API to generate link
				response = await generateLinkAndUpdateCount();
				currentCountEl.text(response.articleGiftsLeft);
				if(+response.articleGiftsLeft === 0) {
					shouldCopyGift = false;
				}
			} catch (err) {
				console.log("error generating link: ", err);
			}

			try {
				// Use the modern Clipboard API to copy generated link from response
				setTimeout(() => {navigator.clipboard.writeText(response.articleGiftURL)}, 0);
				spinner.each(function (index, element) {
					$(element).hide();
				});
				copyButton.hide();
				successIcon.show();
			} catch (err) {
				copyButton.show();
				spinner.each(function (index, element) {
					$(element).hide();
				});
				console.error("Unable to copy text to clipboard", err);
			}
		});
	},

	reAttachEventHandlers: function() {
		$("[js-target='facebook-share']").off("click.social");
		$("[js-target='linkedin-share']").off("click.social");
		SocialNetworkSharing.prototype.attachEventHandlers();
	},

	initialize: function() {
		$.getScript("https://connect.facebook.net/en_US/sdk.js", function() {
			/* initialize Facebook SDK */
			FB.init({
				appId   : 1761258887507575,
				status  : true,
				xfbml   : false,
				version : "v2.9"
			});
		});
		$.getScript("https://platform.twitter.com/widgets.js");

		SocialNetworkSharing.prototype.attachEventHandlers();

		$(document).on("socialNetworkSharingRender", function(e) {
			SocialNetworkSharing.prototype.reAttachEventHandlers();
		});
	}
}

// this method must be called once the component is imported
SocialNetworkSharing.initialize = SocialNetworkSharing.prototype.initialize;
SocialNetworkSharing.attachEventHandlers = SocialNetworkSharing.prototype.attachEventHandlers;

mixinComponent(SocialNetworkSharing, selector);

/********************************************
Sample usage:
import SocialNetworkSharing from "../components/social-network-sharing/social-network-sharing"
SocialNetworkSharing.activateAll();
SocialNetworkSharing.initialize();
********************************************/
