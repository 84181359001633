<stream-item class="stream-item"
	data-id="{{ entry.id }}"
	data-title="{{ entry.title }}"
	data-url="{{ entry.content.src }}"
	data-topic="{{ entry.category.term }}"
	data-authors="{% entry.author.forEach(function(author){ %}{{ author.name }};{% }); %}"
	data-content-type="{{ entry.contentType }}"
	data-content-image = "{{ entry.content.image }}"
	data-summary = "{{ entry.summary }}"
>

	<div class="row collapsed">
		<div class="column small-4 medium-3">
			<figure><img src="{{ entry.content.image }}" alt="" /></figure>
			<div class="text-right mtm hide-for-small"><a href="{{ entry.content.src }}" class="lighter-gray"><i class="icon icon-zoom icon-lg"></i></a></div>
		</div>
		<div class="column small-8 medium-9">
			<h4><a href="{{ entry.content.src }}">{{ entry.title }}</a></h4>
	
			<div class="article-tools mbm has-no-border">
				<ul class="plain-inline-list has-vertical-dividers nowrap mln">
					<li class="utility text-center"><a href="#" js-target="save-flyout"><i class="icon icon-lg icon-file-text-o"></i><span class="hide-for-small"><br>Save</span></a></li>
					<!-- <li class="utility text-center"><a href="#" js-target="share-flyout"><i class="icon icon-lg icon-share"></i><span class="hide-for-small"><br>SHARE</span></a></li> -->
					<!-- <li class="utility text-center"><a href="javascript:window.print();" js-target="print-article"><i class="icon icon-lg icon-print"></i><span class="hide-for-small"><br>PRINT</span></a></li> -->
					
					{% if (isPaywallEnabled) { %}
						<li class="utility text-center"><a href="{{ entry.content.src }}"><i class="icon icon-lg icon-download-pdf"></i><span class="hide-for-small"><br/>Download</span></a></li>
					{% } else if (user.isSubscriber()) { %}
						<li class="utility text-center"><a href="/visual-library/download/{{ user.getSubscriberId() }}/{{ entry.content.asset }}"><i class="icon icon-lg icon-download-pdf"></i><span class="hide-for-small"><br/>Download</span></a></li>
					{% } %}
				</ul>
			</div>
	
			<div class="hide-small">
				<h6 class="product-hed mbn">About</h6>
				<p class="product-text mbs">
					{{ entry.summary }}
				</p>				
				{% if (typeof entry.author !== "undefined") { %}
				<h6 class="product-hed mbn">Author</h6>
				<ul class="product-text mbs byline-list no-by is-block">{% entry.author.forEach(function(author) { %}<li>{{ author.name }}</li>{% }); %}</ul>
				{% } %}
				<h6 class="product-hed mbn">Subject</h6>
				<p class="product-text mbs">
					{{ entry.category.term }}
				</p>
				<h6 class="product-hed mbn">Format</h6>
				<p class="product-text mbs">
					{{ entry.content.format }}
				</p>
				<h6 class="product-hed mbn">Publication Date</h6>
				<p class="product-text mbs">
					<time>
						{{ moment(entry.published).format("MMMM DD, YYYY") }}
					</time>
				</p>
			</div>
	
		</div>
	</div>
</stream-item>
