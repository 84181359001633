
import $ from "jquery";
import common from "../../common";

var uri = common.API + "components/article/";

/**
 * Return the fully qualified URI for an article
 * @param {object} articleData
 * @return URI to request the article content
 */
function parseURI(articleData) {
	return uri + articleData.year + "/" + articleData.month + "/" + articleData.seo;
}

// get all filters of a given type
exports.get = function (articleData) {
	return $.ajax({
		type: "get",
		contentType: "text/html",
		url: parseURI(articleData)
	}).promise();
};
