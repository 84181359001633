import axios from "axios";

exports.getUserRef = function() {
	return new Promise((resolve, reject) => {
		axios.get("/service/user/userRef")
			.then((response) => {
				console.log("userRef: " + response.data.userRef[0]);
				resolve(response.data.userRef[0]);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

exports.completeSubscriptionActivation = function() {
	return new Promise((resolve, reject) => {
		axios.post("/api/user/subscribe/checkoutComplete",
			{},
			{headers: {"Content-Type": "application/json"}}
		)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

exports.getTransactionDigitalData = function(uid) {
	return new Promise((resolve, reject) => {
		axios.get("/api/digitaldata/piano/transactionComplete?uid=" + uid,
			{headers: {"Content-Type": "application/json"}}
		)
			.then((response) => {
				resolve(response);
			})
			.catch((response) => {
				reject(response);
			});
	});
}
