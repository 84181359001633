import React, {Component, useState} from 'react';

function VisiblePassword(props) {
	let shouldRenderRequirements = props.showRequirments || false;
	let className = props.valid ? "" : "error";
	return (
		<>
		<div className={`password-input ${className} ${props.showRequirments ? 'with-password-req' : ''}`}>
			<div class="password-option view-option">
				<input type="text" 
						id="password"
						name="password"
						class="password-text"
						value={props.password} 
						onBlur={props.onBlur}
						onChange={props.onChange}
						/>
						<a href="#" onClick={ (ev)=> { ev.preventDefault();props.toggle()}}>
							<img class="hbr-modal--imgs" src="/resources/css/images/password-hide.svg" />
						</a>
			
			</div>
		</div>
		{ shouldRenderRequirements && 
			<span class="password-requirements">Passwords must have at least 10 characters, one number, one upper and lower case letter, and one special character.</span> 
		}
		</>
	)
}

function HiddenPassword(props) {
	let shouldRenderRequirements = props.showRequirments || false;
	let className = props.valid ? "" : "error";
	return (
		<>
		<div className={`password-input ${className} ${props.showRequirments ? 'with-password-req' : ''}`}>
			<div class="password-option hide-option">
				<input 	type="password" 
						id="password"
						name="password"
						value={props.password} 
						class="password"
						onBlur={props.onBlur}
						onChange={props.onChange}
						/>
				<a href="#" onClick={ (ev)=> { ev.preventDefault();props.toggle()}}>
					<img class="hbr-modal--imgs"
					src="/resources/css/images/password-view.svg" 
					/>
				</a>

				
			</div>
		</div>
		{ shouldRenderRequirements && 
			<span class="password-requirements">Passwords must have at least 10 characters, one number, one upper and lower case letter, and one special character.</span>
		}
		</>
	)
}

function PasswordComponent(props){
	var fieldName = props.name
	var fieldValue = props.store((state) => state.form[fieldName])
	const [show, setShow] = useState(false);
	const toggle = () => {
		setShow(!show)
	}
	if(show) {
		return <VisiblePassword {...props} password={fieldValue} toggle={ toggle } />
	} else {
		return <HiddenPassword {...props} password={fieldValue} toggle={ toggle } />
	}
}

export default PasswordComponent;
