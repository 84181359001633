{% if (!shareNetworkLoggedIn) { %}

	<div class="{{ shareNetwork }}-container" js-target="share-{{ shareNetwork }}">
		<div class="cushion text-center">
			<i class="icon icon-{{ shareNetworkIcon }}-square icon-2x lighter-gray"></i>
		</div>
	</div>

	{% if (shareNetwork === "linkedin") { %}
		<button class="button--black expand" js-target="linkedin-share">Connect with {{ shareNetworkDisplayName }}</button>
	{% } else { %}
		<button class="button--black expand" js-target="share-connect">Connect with {{ shareNetworkDisplayName }}</button>
	{% } %}

{% }else { %}

	<div class="{{ shareNetwork }}-container" js-target="share-{{ shareNetwork }}">
		<div class="cushion text-center">
			<i class="icon icon-{{ shareNetworkIcon }}-square icon-2x lighter-gray"></i>
		</div>
	</div>

	{% if (shareNetwork === "linkedin") { %}
		<button class="button--black expand mbn" js-target="linkedin-share">Share on {{ shareNetworkDisplayName }}</button>
	{% } else if (shareNetwork === "twitter") { %}
		<a class="button--black expand mbn" href="http://twitter.com/HarvardBiz" target="_blank" rel="noopener noreferrer" js-target="twitter-share" role="button">Share on X Corp.</a>
	{% } else if (shareNetwork === "facebook") { %}
		<button class="button--black expand mbn" js-target="facebook-share">Share on {{ shareNetworkDisplayName }}</button>
	{% } %}

{% } %}
