
import common from "../../common";
import request from "../request";

var BASE_URI = common.API + "search";

function parseURI (filterData) {
	return BASE_URI + filterData.queryString + "&filters=" + filterData.filters + "&sort=" + filterData.sort + "&order=" + filterData.order;
}

// get all filters of a given type
exports.post = function (filterData) {
	return request.ajax("post", parseURI(filterData));
};

