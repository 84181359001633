import $ from "jquery";

(function() {
	$("body").on("content-action:success", setEventData);

	/**
	 * Set event information to digital data for a content action.
	 *
	 * @param {object} ev - event object.
	 * @param {object} eventData - object containing content action information.
	 */
	function setEventData (ev, eventData) {
		if (window.digitalData && eventData && eventData.element) {
			// create event info
			var eventInfo = {};
			eventInfo.eventName = "Content Interaction";
			eventInfo.eventAction = eventData.eventAction;
			if ($(".article-content-flex2019").length > 0) {
				eventInfo.contentName = eventData.element.data("title");
				eventInfo.contentType = eventData.element.data("content-type");
			} else {
				eventInfo.contentName = eventData.element.data().title;
				eventInfo.contentType = eventData.element.data().contentType;
			}
			if (eventData.shareMedium) {
				eventInfo.shareMedium = eventData.shareMedium;
			}
			if (eventData.eventAction === "Content Save" || eventData.eventAction.match(/Content Share/)) {
				eventInfo.actionSource = getActionSource(eventData.element);
			}
			// set event info to digital data
			var event = {};
			event.eventInfo = eventInfo;
			window.digitalData.event = [];
			window.digitalData.event.push(event);
		}
	}

	/**
	 * Finds out the source of content interaction.
	 *
	 * @param {object} element - Element used for content interaction.
	 */
	function getActionSource (element) {
		// check for product detail page
		if (location.pathname.indexOf("/product/") >= 0) {
			return "Product Detail Page";
		}

		// check for store category page
		if (/store\/([a-zA-Z]+)/.test(location.pathname)) {
			return "Category Page";
		}

		// check for home page
		var streamListComponent;
		var endpoint;
		if (location.pathname === "/" || location.pathname === "/resources/html/marketing/intromercial/overlay.html") {
			streamListComponent = $(element).closest("stream-list");
			if (streamListComponent && streamListComponent.length > 0) {
				endpoint = streamListComponent.attr("data-stream-endpoint");
				if (endpoint) {
					if (endpoint.indexOf("components/list/latest") >= 0) {
						return "The Latest";
					} else if (endpoint.indexOf("components/list/editors-picks") >= 0) {
						return "Editor's Picks";
					} else if (endpoint.indexOf("components/search-list/popular-content") >= 0) {
						return "Most Popular";
					}
				}
			}
			return "Home Page";
		}

		// check for topic page
		if (location.pathname.indexOf("/topic/") >= 0) {
			return "Topic Page";
		}

		// check for article page
		if ($(element).closest("article-content").length > 0 ||  $(element).closest("article-content-flex2019").length > 0) {
			return "Article Page";
		}

		// check for store page
		if (location.pathname.indexOf("/store") >= 0) {
			// check for store page stream lists
			streamListComponent = $(element).closest("stream-list");
			if (streamListComponent && streamListComponent.length > 0) {
				endpoint = streamListComponent.attr("data-stream-endpoint");
				if (endpoint) {
					if (endpoint.indexOf("home_page.store_classics") >= 0) {
						return "Top Products - Classics";
					} else if (endpoint.indexOf("home_page.store_must_read") >= 0) {
						return "Top Products - Must Read";
					} else if (endpoint.indexOf("home_page.store_for_you") >= 0) {
						return "Top Products - For You";
					} else if (endpoint.indexOf("home_page.store") >= 0) {
						return "Top Products - Top This Month";
					}
				}
			}
		}
		return "Landing Page";
	}
})();
