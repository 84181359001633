
import $ from "jquery";
import _contains from "lodash-amd/modern/collections/contains";
import _once from "lodash-amd/modern/functions/once";
import _tap from "lodash-amd/modern/chaining/tap";
import geoip2 from "support/geoip2";

var EU_CODES = [
	"EU", "BE", "BG", "CZ", "DK", "DE", "EE", "IE", "EL", "ES", "FR",
	"IT", "CY", "LV", "LT", "LU", "HU", "MT", "NL", "AT", "PL", "PT",
	"RO", "SI", "SK", "FI", "SE", "UK", "NO", "CH", "AL", "BA", "HR"
];

var DOM_CODES = [
	"US", "CA"
];

var US_CODE = "US";

exports.determineIfEU = _once(function() {
	return _tap($.Deferred(), function(promise) {
		geoip2.country(function(res) {
			promise.resolve(_contains(EU_CODES, res.country.iso_code));
		});
	});
});

exports.determineIfDomesticUser = _once(function() {
	return _tap($.Deferred(), function(promise) {
		geoip2.country(function(res) {
			promise.resolve(_contains(DOM_CODES, res.country.iso_code));
		});
	});
});

var	usDomesticPromise;
exports.determineIfUSDomesticUser = function () {
	if (!usDomesticPromise) {
		usDomesticPromise = $.Deferred();
		geoip2.country(function(res) { usDomesticPromise.resolve(res.country.iso_code === US_CODE); });
	}
	return usDomesticPromise;
};

