
// Components
import $ from "jquery";
import _throttle from "lodash-amd/modern/functions/throttle";
import mixinComponent from "../../mixins/component";
import SignInModal from "../../react/AuthModal/Modal";
import state from "../../services/state";

export default function GlobalNav($el) {
	this.initializeAsComponent($el);

	$("[js-target='menu-toggle']").on("click", function(evt) {
		evt.preventDefault();
		this.setActive(!this.$el.hasClass("active"));
	}.bind(this));

	this.$el.on("clickoutside", function(ev) {
		if (!$(ev.target).parents().addBack().is("[js-target='menu-toggle']")) {
			this.setActive(false);
		}
	}.bind(this));

	$(window).on("scroll resize", _throttle(this.recalcContained.bind(this), 50));
	this.recalcContained();
}

GlobalNav.prototype = {
	events: {
		"click a[data-sign-in]": "requireSignIn",
		"focus a": "scrollToNav"
	},

	requireSignIn: function(ev) {
		ev.preventDefault();
		SignInModal.ensureSignedIn().then(function() {window.location = $(ev.target).attr("href"); });
	},

	setActive: function(active) {
		this.$el.toggleClass("active", active);
		$(".top-header").find("[js-target='menu-toggle']").toggleClass("toggled", active);
	},

	setContained: function(contained) {
		var target = $(contained ? "[data-params*='region=global-nav']" : "header");
		if (!this.$el.parent().is(target)) {
			target.append(this.$el);
		}
		this.$el.toggleClass("is-contained", contained);
	},

	scrollToNav: function(ev) {
		window.scrollTo(0, 0);
	},

	recalcContained: function() {
		var contain = state.homeHeroVisible() && !state.isMobile();
		var contained = this.$el.hasClass("is-contained");
		if (contain && !contained && this.$el.hasClass("active")) {
			this.setActive(false);
			setTimeout(this.setContained.bind(this, true), 150);
		}
		else {
			this.setContained(contain);
		}
	}
};

mixinComponent(GlobalNav, "global-nav");
