import $ from "jquery";

(() => {
	/**
	 * Set followed item information.
	 *
	 * @param {object} ev - event object.
	 * @param {object} followedItemInfo - information about the item followed.
	 */
	function setFollowSubscribeInfo (ev, followedItemInfo) {
		if (window.digitalData) {
			if (followedItemInfo.type === "newsletter") {
				// set event info
				var eventInfo = {};
				eventInfo.eventName = "Profile Update";
				eventInfo.eventAction = "Subscribe to Newsletter";
				eventInfo.newsletterName = followedItemInfo.name;
				if (followedItemInfo.isPromo == true || followedItemInfo.isPromo == "true") {
					eventInfo.newsletterSubClickLocation = "Promo";
				} else {
					eventInfo.newsletterSubClickLocation = followedItemInfo.isPromo + "|" +followedItemInfo.orderNumber;
				}
				var event = {};
				event.eventInfo = eventInfo;
				window.digitalData.event = [];
				window.digitalData.event.push(event);
			} else if (followedItemInfo.type === "topic") {
				// set event info
				var eventInfo = {};
				eventInfo.eventName = "Profile Update";
				eventInfo.eventAction = "Follow Topic";
				var event = {};
				event.eventInfo = eventInfo;
				window.digitalData.event = [];
				window.digitalData.event.push(event);

				// set topic followed
				if (window.digitalData.users && window.digitalData.users.length > 0) {
					var user = window.digitalData.users[0];
					if (user.profile) {
						if (!user.profile.feeds) {
							user.profile.feeds = [];
						}

						// check if topic is already avaiable in digital data
						var topicAlreadyAdded = false;
						$.each(user.profile.feeds, function(index, feed) {
							if (feed.topicName === followedItemInfo.name) {
								topicAlreadyAdded = true;
								return false;
							}
						});

						// add topic to digital data if not available already
						if (!topicAlreadyAdded) {
							var newFeed = {};
							newFeed.topicName = followedItemInfo.name;
							user.profile.feeds.push(newFeed);
						}
					}
				}
			} else if (followedItemInfo.type === "marketingCampaign") {
				// set event info
				var eventInfo = {};
				eventInfo.eventName = "Profile Update";
				eventInfo.eventAction = "Subscribe to Marketing Campaign";
				eventInfo.marketingCampaignName = followedItemInfo.name;
				eventInfo.marketingCampaignSubClickLocation = "Not Applicable";
				var event = {};
				event.eventInfo = eventInfo;
				window.digitalData.event = [];
				window.digitalData.event.push(event);
			}

			// set page type and page name
			if (window.digitalData.page && window.digitalData.page.pageInfo) {
				if (location.pathname === "/") {
					window.digitalData.page.pageInfo.pageType = "Home Page";
					window.digitalData.page.pageInfo.pageName = document.title;
				}
				if (location.pathname.indexOf("/topic/") >= 0) {
					window.digitalData.page.pageInfo.pageType = "Topic";
					window.digitalData.page.pageInfo.pageName = followedItemInfo.name;
				}
				if (location.pathname.match(new RegExp(/^\/([0-9]{4})\/([0-9]{2})\/([a-zA-Z0-9\-]+)(\/)?(\?)?(.*)/i))) {
					window.digitalData.page.pageInfo.pageType = "Article";
				}
				if (location.pathname.indexOf("/email-newsletters") >= 0) {
					window.digitalData.page.pageInfo.pageType = "Landing Page";
					window.digitalData.page.pageInfo.pageName = "Newsletter Landing Page";
				}
				if (location.pathname.indexOf("/the-latest") >= 0) {
					window.digitalData.page.pageInfo.pageType = "Landing Page";
					window.digitalData.page.pageInfo.pageName = "Article Landing Page - The Latest";
				}
				if (location.pathname.indexOf("/product/") >= 0 || location.pathname.indexOf("/registration-complete") >= 0) {
					window.digitalData.page.pageInfo.pageType = "Landing Page";
				}
			}
		}
	}

	function setUnFollowUnSubcribeInfo (ev, unfollowedItemInfo) {
		if (window.digitalData) {
			if (unfollowedItemInfo.type === "marketingCampaign") {
				// set event info
				var eventInfo = {};
				eventInfo.eventName = "Profile Update";
				eventInfo.eventAction = "Unsubscribe from Marketing Campaign";
				eventInfo.marketingCampaignName = unfollowedItemInfo.name;
				eventInfo.marketingCampaignSubClickLocation = "Not Applicable";
				var event = {};
				event.eventInfo = eventInfo;
				window.digitalData.event = [];
				window.digitalData.event.push(event);
			} else if (unfollowedItemInfo.type === "newsletter") {
				// set event info
				var eventInfo = {};
				eventInfo.eventName = "Profile Update";
				eventInfo.eventAction = "Unsubscribe from Newsletter";
				eventInfo.newsletterName = unfollowedItemInfo.name;
				if (unfollowedItemInfo.isPromo === true || unfollowedItemInfo.isPromo === "true") {
					eventInfo.newsletterSubClickLocation = "Promo";
				} else {
					eventInfo.newsletterSubClickLocation = unfollowedItemInfo.isPromo + "|" +unfollowedItemInfo.orderNumber;
				}
				var event = {};
				event.eventInfo = eventInfo;
				window.digitalData.event = [];
				window.digitalData.event.push(event);
			}
		}
	}

	// bind event
	$("body").on("follow:success", setFollowSubscribeInfo);
	$("body").on("unfollow:success", setUnFollowUnSubcribeInfo);
})();
