
import subscription from "../services/subscription";

// subscriber model
var subscriber = {
	firstName: null,
	lastName: null,
	email: null,
	source: null,
	subscriberAccountNumber: null,
	subscriptionCode: null,
	subscriptionActive: false,
	subscriptionLinked: false,
	issues: [],
	optIn: false
};

// subscriber model exports
exports.getSubscriberAccountNumber = function() {
	return subscriber.subscriberAccountNumber;
};

exports.getSubscriberFirstName = function() {
	return subscriber.firstName;
};

exports.getSubscriberLastName = function() {
	return subscriber.lastName;
};

exports.getSubscriberEmailAddress = function() {
	return subscriber.email;
};

exports.getSubscriberEntitlements = function() {
	return subscriber.issues;
};

exports.isSubscriberActive = function() {
	return subscriber.subscriptionActive;
};

exports.isSubscriberLinked = function() {
	return subscriber.subscriptionLinked;
};

exports.getSubscriberSubscriptionCode = function() {
	return subscriber.subscriptionCode;
};

/**
 * fetch - Look up the Subcriber Information
 * @param {string} emailAddress - Email Address of the subscriber
 * @param {string} accountNumber - Subscriber Account number
 * @returns {promise.<object,Error>} - Promise with the subscriber data.
 */
exports.fetch = function(emailAddress, accountNumber) {
	return subscription.lookup(emailAddress, accountNumber).then(
		function(subscriptionInfo) {
			// save a copy of the subscriber
			subscriber = subscriptionInfo;
			return subscriber;
		});
};

/**
 * verify - Verifies the Subscriber account.  The service here
 * will either create the account for the subscriber using the
 * subscriber model contained here, or it will log the subscriber
 * in if the account already exists.
 * @param {string} password - Subscriber's password
 * @returns {promise.<object,Error>} - Promise with the subscriber data.
 */
exports.verify = function(password, optIn) {
	subscriber.optIn = optIn;
	return subscription.verify(subscriber, password);
};
