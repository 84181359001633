/**
 * @module services/stream
 * @requires module:jquery
 * @requires module:common
 * @requires module:mixins/events
 */

import $ from "jquery";
import common from "../common";

/**
   * loadMore
   *
   * @param {string} endpoint - The url of stream feed to load.
   * @param {Object} page - Page number and result size to load from.
   * @param {string} query - optional query string param(s)
   * @return {object} A promise that when the stream completes.
   */
exports.loadMore = function(endpoint, page, query) {
	return $.getJSON(common.API + endpoint + "/" + page.number + "/" + page.size + "?format=json" + (query ? "&" + query : ""));
};

/**
   * cloneAd
   * Copies the existing ad from a li and papaste it into newly created li
   *
   * @param {Object} streamList - Stremalist with original ad, as well as copy target
   */
exports.cloneAd = function(streamList) {
	if (streamList.data() && streamList.data().adsEnabled) {
		var originalAd = streamList.find("li[js-target='stream-ad-container']");
		var adHtml = originalAd[0].innerHTML;
		var adTarget = streamList.find("li[js-target='stream-ad-more']");
		adTarget.html(adHtml);
		adTarget.removeClass("is-hidden");
	}
};
