
import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import dom from "react-dom-factories";
import _each from "lodash-amd/modern/collections/forEach";
import _map from "lodash-amd/modern/collections/map";
import _contains from "lodash-amd/modern/collections/contains";
import _omit from "lodash-amd/modern/objects/omit";
import _values from "lodash-amd/modern/objects/values";
import _sortBy from "lodash-amd/modern/collections/sortBy";
import _find from "lodash-amd/modern/collections/find";
import _indexBy from "lodash-amd/modern/collections/indexBy";
var assoc = require("support/utilities").assoc;
var merge = require("support/utilities").merge;
import interaction from "../../services/hbr/interaction";
import validatorService from "../../services/validator-utils";
import $ from "jquery";

// React component:
// This UI component is rendered client-side using data from the server. The
// initial state is conveyed as serialized JSON in a data attribute on the
// container element, and is read by the activateAll function.

var FolderList = createReactClass({
	getInitialState: function() {
		var path = window.location.pathname;
		var slugExpression = /^\/my-library\/library\/([a-zA-Z0-9\-]+)$/g;
		var slugs = slugExpression.exec(path);
		var slugFolder = null;
		if (slugs !== null) {
			var slug = slugs[1];
			slugFolder = _find(this.props.folders, function(obj) {
				if (obj.slug !== "undefined" && obj.slug !== null && obj.slug === slug) {
					return obj;
				}
				return null;
			});
		}
		var activeFolderId = (slugFolder === null) ? "all" : slugFolder.id;
		return {folders: _indexBy(this.props.folders, "id"), open: false, active: activeFolderId, editing: null, newName: "", newDescription: "", error: null};
	},

	componentDidMount: function() {
		if (this.state.active !== "all") {
			var d = function(folder) {
				return merge(folder, {streamEndpoint: "user/list/" + encodeURIComponent(folder.id), streamParam: ""});
			};
			$(ReactDOM.findDOMNode(this)).trigger("change:stream", d(this.state.folders[this.state.active]));
		}
	},


	componentDidUpdate: function(prevProps, prevState) {
		var d = function(folder) {
			return merge(folder, {streamEndpoint: "user/list/" + encodeURIComponent(folder.id),
				streamParam: prevState.editing === "new" ? "newFolderCreated" : ""});
		};

		if (this.state.active !== prevState.active && prevState.editing !== "new") {
			$(ReactDOM.findDOMNode(this)).trigger("change:stream",
				this.state.active === "all" ? {streamEndpoint: "user/saves", id: "all"} : d(this.state.folders[this.state.active]));
		}
	},

	onOpen: function(ev) {
		ev.preventDefault();
		this.setState({open: !this.state.open});
	},

	onActivate: function(folder, ev) {
		ev.preventDefault();
		this.setState({active: folder.id, editing: null, open: false});
	},

	onEdit: function(folder, ev) {
		ev.preventDefault();
		this.setState({active: folder.id, editing: "active", newName: folder.name, newDescription: folder.description, error: null});
	},

	onNew: function(ev) {
		ev.preventDefault();
		this.setState({editing: "new", newName: "", newDescription: "", error: null});
	},

	onCancel: function(ev) {
		ev.preventDefault();
		this.setState({editing: null});
	},

	onUpdate: function(folder, ev) {
		ev.preventDefault();
		interaction.request("lists", merge(folder, {name: this.state.newName.trim(), description: this.state.newDescription})).then(function(res) {
			this.setState({active: res.id, editing: null, folders: assoc(this.state.folders, res.id, res), open: folder.id ? true : false, error: null});
			const d = function(folder) {
				return merge(folder, {streamEndpoint: "user/list/" + encodeURIComponent(res.id), streamParam: ""});
			};
			$(ReactDOM.findDOMNode(this)).trigger("change:stream", d(this.state.folders[this.state.active]));
		}.bind(this), function(response) {
			if (response && response.responseJSON && (response.responseJSON.errors || response.responseJSON.error)) {
				var errorMessage = "";
				var errors = response.responseJSON.errors;
				if (errors && errors.length !== 0) {
					errorMessage = errors[0].error;
				}
				else {
					errorMessage = response.responseJSON.message;
				}

				this.setState({"error": errorMessage});
			}
		}.bind(this));
	},

	onDelete: function(folder, ev) {
		ev.preventDefault();
		this.setState({active: folder.id, editing: "active-delete"});
	},

	onDeleteYes: function(folder, ev) {
		ev.preventDefault();
		this.setState({active: "all", editing: null, folders: _omit(this.state.folders, folder.id)});
		interaction.request("lists", "delete", folder);
	},

	onDeleteNo: function(folder, ev) {
		ev.preventDefault();
		this.setState({editing: null});
	},

	render: function() {
		var validName = validatorService.validate(this.state.newName, "listName");
		var invalidName = this.state.newName && !validName;
		var formContents = function(action) {
			return [
				dom.div({className: "flex-row"},
					dom.div({className: "text-center eyebrow--small pbm flex-1"}, action + " FOLDER"),
					dom.a({href: "#", onClick: this.onCancel},
						dom.i({className: "icon icon-times link--black", title: "Cancel action"}))
				),
				dom.input({type: "text", className: invalidName || this.state.error ? "error" : "", placeholder: "Folder Title", autoFocus: true, value: this.state.newName, onChange: function(ev) {
					this.setState({newName: ev.target.value, error: null});
				}.bind(this)}),
				invalidName || this.state.error ? dom.label({className: "error list-error text--align-center"}, !validName ? validatorService.validatorPatterns.listName.message : this.state.error) : "",
				dom.textarea({placeholder: "Folder Description", value: this.state.newDescription, onChange: function(ev) {
					this.setState({newDescription: ev.target.value});
				}.bind(this)})
			];
		}.bind(this);

		var creating = this.state.editing === "new";
		return dom.div({className: "folder-list" + (this.state.open ? " open" : "")},
			dom.a({href: "#", className: "folder-list--opener flex-row justify-between pvm prm pll link--white", onClick: this.onOpen},
				this.state.active === "all" ? "All Folders" : this.state.folders[this.state.active].name,
				dom.span({className: "icon icon-caret-right font-size-xlarge"})
			),
			dom.div({className: "folder-list--folders"},
				_map([{id: "all", name: "All Folders"}].concat(_sortBy(_values(this.state.folders), "name")), function(folder) {
					var active = this.state.active === folder.id;
					var editing = active && _contains(["active", "active-delete"], this.state.editing);
					return dom.div({className: "folder-list--folder prm" + (active ? " active" : "") + (editing ? " editing" : "")},
						editing ? dom.div({className: "ptm pbl"},
							this.state.editing === "active" ? [
								formContents("EDIT"),
								dom.button({className: "button--black expand mbs", disabled: !validName, onClick: this.onUpdate.bind(this, folder)}, "Save")
							] : [
								dom.div({className: "mbm"}, "Are you sure you want to discard this folder?"),
								dom.div({className: "row"},
									dom.div({className: "small-6 columns"},
										dom.button({className: "button--black expand", onClick: this.onDeleteYes.bind(this, folder)}, "Yes")
									),
									dom.div({className: "small-6 columns"},
										dom.button({className: "button--alt-black expand", onClick: this.onDeleteNo.bind(this, folder)}, "No")
									)
								)
							]
						) : dom.div({className: "flex-row justify-between"},
							dom.a({href: "#", className: "folder-list--folder-link flex-1 pvm text-overflow nowrap", onClick: this.onActivate.bind(this, folder)},
								((folder.slug === "hbrgift") ? dom.i({className: "icon icon-gift prs"}) : null), folder.name),
							(folder.id !== "all" && !folder.readOnly) ? [
								dom.a({href: "#", className: "folder-list--edit-link font-size-large pvm", onClick: this.onEdit.bind(this, folder)},
									dom.i({className: "icon icon-pencil", title: "Edit folder"})),
								dom.a({href: "#", className: "folder-list--edit-link font-size-xsmall pvm pls", onClick: this.onDelete.bind(this, folder)},
									dom.i({className: "icon icon-circle-x", title: "Delete folder"}))
							] : null
						)
					);
				}.bind(this)),
				dom.div({className: "folder-list--folder pvm prm" + (creating ? " editing" : "")},
					creating ? [
						formContents("NEW"),
						dom.button({className: "button--black expand", disabled: !validName, onClick: this.onUpdate.bind(this, {})}, "Save")
					] : dom.a({href: "#", className: "button--alt-black expand mbn", onClick: this.onNew}, "New Folder")
				)
			)
		);
	}
});

var all;

exports.activateAll = function() {
	all = _map(document.getElementsByTagName("folder-list"), function(el) {
		return ReactDOM.render(React.createElement(FolderList, {folders: JSON.parse(el.getAttribute("data-folders"))}), el);
	});
};

exports.updateFolder = function(folder) {
	_each(all, function(component) {
		component.setState({folders: assoc(component.state.folders, folder.id, folder)});
	});
};

exports.updateOnToggleTab = function () {
	if (all) {
		for (let i = 0; i < all.length; i++) {
			const {editing, active, newName, newDescription, folders, folder} = all[i].state;
			let newEditingState = null;
			let hasEditedName = false;
			let hasEditedDescription = false;

			if (editing === "new") {
				hasEditedName = newName !== "";
				hasEditedDescription = newDescription !== "";
			} else if (editing === "active") {
				hasEditedName = newName !== folders[active].name;
				hasEditedDescription = newDescription !== folders[active].description;
			}

			if (hasEditedName || hasEditedDescription) {
				newEditingState = editing;
			}

			all[i].setState({folder, editing: newEditingState});
		}
	}
};
