import React from 'react';

function FieldGroup(props) {
	let className = `field-group ${props.class}`
	return (
		<div className={className}>
			{ props.hasOwnProperty("withLabel") && <label for={props.for}>{props.withLabel}</label> }
			{ props.children }
		</div>
	)
}

export default FieldGroup;
