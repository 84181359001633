
import $ from "jquery";

exports.ajax = function (method, uri, data) {
	return $.ajax({
		type: method,
		dataType: "json",
		contentType: "application/json",
		data: JSON.stringify(data),
		url: uri
	}).promise();
};

