/**
 * A series of functions that can be used throughout the localstorage.
 */

// Dependencies
import "../common";

/**
* function that will cache the values given
* on the client side
*
* @param {string} key - key of the cached values that
* will be used as a key in the storing the value
* @param {string} value - the object that will be stored on the client
* side
*
*/
exports.setCache = function(key, value) {
	if (typeof value === "object") {
		value = JSON.stringify(value);
	}

	window.localStorage.setItem(key, value);
};

/**
* function that will retrieve the cached values from the client side
*
* @param {string} key - the key used to store the values on the client side
*
* @return {string|object|array}
*/
exports.getCache = function(key) {
	return JSON.parse(window.localStorage.getItem(key));
};

/**
* function that will remove the given cached object
*
* @param {string} key
*
*/
exports.removeCache = function(key) {
	window.localStorage.removeItem(key);
};

/**
* function that will clear the client side cache
*
*/
exports.clearCache = function() {
	window.localStorage.clear();
};

/**
* function that will retrieve the key of the cached client at the given
* location
*
* @param {number} i - index of the key of the client cache
*
* @return {string} key
*/
exports.getCacheKey = function(i) {
	return window.localStorage.key(i);
};
