<stream-item class="stream-item ic1-feed-item"
	data-id="{{ entry.id }}"
	data-title="{{ entry.title }}"
	data-url="{{ entry.content.src }}"
	data-topic="{{ entry.category.term }}"
	data-authors="{% entry.author.forEach(function(author){ %}{{ author.name }};{% }); %}"
	data-content-type="{{ entry.contentType }}"
	data-content-image = "{{ entry.content.image }}"
	data-summary = "{{ entry.summary }}"
>	
	<div class="feed-stream-image" aria-hidden="true">
		<figure>
			<a href="{{ entry.content.src }}" tabindex="-1">
				<img src="{{ entry.content.image }}"></a>
		</figure>
	</div>
	<div class="ic1-stream-info">
		<div class="content-type">{{ entry.contentType.replace("Digital ", "") }}</div>
		<span class="sponsored-content-type">{{ entry.summary }}</span>

		<h3 class="hed"><a href="{{ entry.content.src }}">
			{{ entry.title }}</a>
		</h3>
	
		<div class="ic1-stream-item-info">
			{% if (typeof entry.category.term !== "undefined") { 
				var termlink = entry.category.term.toLowerCase().replace("&","and").replace(/ /g,"-");
			%}
			<a href="/topic/subject/{{ termlink }}" class="topic hide-small">{{ entry.category.term }}</a>
			{% } %}
			<span class="divider hide-small"></span>
			<span class="dek">{{ entry.summary }}</span>
		</div>

		<ul class="byline-ic byline-list hide-small">
			{% 
				var publishedDate = moment(entry.published).isValid()
				 ? moment(entry.published).format("MMMM DD, YYYY") : entry.published;
				
				entry.author.forEach(function(author){ 
			%}
					<li>{{ author.name }}</li>
			{% 
				}); 
			%}
		</ul>
		
		<span class="ic1-item-date hide-small"><time>{{ publishedDate }}</time></span>

	</div>
</stream-item>
