import React from 'react';


export default function Submit(props) {
	return (
		<input type="submit" value={props.value} 
			className={ props.enabled ? props.className || "" : "disabled" } 
			disabled={ !props.enabled }  
		/>
	)
}
