/**
 * A series of functions that can be used throughout the shopping cart.
 */

// Dependencies
import $ from "jquery";
import "../common";

/**
* function that will update the quantity value when the increase
* or decrease value has been clicked
*
* @param {object} elem - element that triggered the event
* @param {string} targetInput
* @param {object} ev
*
* @return {object} - the corresponding quantity input box
*/
exports.updateQuantityValue = function(elem, targetInput, ev) {
	var updateDir = $(elem).data().changeType;
	var quantityEl = $(elem).closest("div").siblings(targetInput).find("input");

	if (ev.type === "click" && (updateDir === "up" || updateDir === "down")) {
		var qty = parseInt($(quantityEl).val(), 10);
		if (updateDir === "up") {
			qty++;
		} else if (updateDir === "down" && qty > 0) {
			qty--;
		}
		$(quantityEl).val(qty);
	}

	return quantityEl;
};

/**
* function that will update the totals of cart based on the changed
* cart values
*
* @param {object} cartTotalEl
* @param {object} cartObj
*
* @return
*/
exports.updateTotals = function(cartTotalEl, cartObj) {
	// update the cart totals
	$(cartTotalEl).find("[js-target='cart-subtotal']").html(parseFloat(cartObj.subTotal).toFixed(2));
	$(cartTotalEl).find("[js-target='cart-tax']").html(parseFloat(cartObj.tax).toFixed(2));
	$(cartTotalEl).find("[js-target='cart-shipping-cost']").html(parseFloat(cartObj.shippingCost).toFixed(2));
	$(cartTotalEl).find("[js-target='cart-grand-total']").html(parseFloat(cartObj.grandTotal).toFixed(2));
};

/**
 * helper that updates all the line item totals
 * @param {object} cartEl selector
 * @param {object} cartObj Cart
 */
exports.updateCartLineItems = function(cartEl, cartObj) {
	// loop through the items
	if (typeof cartObj !== "undefined") {
		$.each(cartObj.items, function() {
			var item = this;

			var cartItem = cartEl.find("cart-item[data-item-id=" + item.itemid + "]");
			if (cartItem !== null) {
				var cartItemPrice = (parseInt(item.quantity) * parseFloat(item.unitPrice)).toFixed(2);
				cartItem.data("quantity", item.quantity);
				cartItem.find("[js-target='item-list-price']").html(cartItemPrice);
			}
		});
	}
};
/**
* function that will set the item type based on the
* productId
*
* @param {string} productId
*
* @return {string} type
*/
exports.getProductType = function(productId) {
	return {
		"KND": "Ebook",
		"BUN": "Bundle",
		"PDF": "Digital",
		"HTM": "Digital",
		"ZIP": "Digital",
		"PPT": "Digital",
		"XLS": "Digital",
		"EPB": "Digital"
	}[productId.split("-")[1]] || "Ship";
};

/**
 * function that will toggle display of elements that need to be shown or
 * hidden depending on whether or not the order is shippable
 *
 * @param {object} cartShippingEl
 * @param {array} cartItems
 */
exports.toggleDisplayWhenShippable = function toggleDisplayWhenOrderIsShippable(cartShippingEl, cartItems) {
	let shippableOrder = false;

	cartItems.forEach((item) => {
		const lineType = item.lineType || item.type;

		if (!shippableOrder && lineType.toLowerCase() === "ship") {
			shippableOrder = true;
		}
	});

	const showWhenShippable = cartShippingEl.find("[js-target='show-when-shippable']");
	const hideWhenShippable = cartShippingEl.find("[js-target='hide-when-shippable']");

	if (shippableOrder) {
		hideWhenShippable.addClass("is-hidden");
		showWhenShippable.removeClass("is-hidden");
	} else {
		showWhenShippable.addClass("is-hidden");
		hideWhenShippable.removeClass("is-hidden");
	}
};
