<h6 class="flyout-header">Change password:</h6>

<form js-target="save-new-list-form" class="field-container">
	<div class="notification-box">
		<div class="notification-box-content" js-target="notification-box">
			<div class="row collapse">
				<div class="small-2 column text-center">
					<span class="icon-stack mts">
						<i class="icon icon-circle-o icon-stack-2x warning-color"></i>
						<i class="icon icon-exclamation icon-stack-1x warning-color"></i>
					</span>
				</div>
				<div class="small-9 column mtm">
					<div class="message" js-target="notification-box-message">
					Current Password is invalid
					</div>
				</div>
			</div>
		</div>
	</div>

	<label for="current-password">Current password</label>
	<input name="current-password" id="current-password" type="password" required>

	<label for="new-password">New password</label>
	<input name="new-password" id="new-password" type="password" required>

	<label for="confirm-password">Confirm new password</label>
	<input name="confirm-password" id="confirm-password" type="password" required>

	<button class="expand button--black" js-target="save-user">Save Changes</button>
</form>
