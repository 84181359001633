import $ from "jquery";

(function() {
	$("body").on("tab-bar:change", setTopicInfo);

	/**
	 * Set topic page information to digital data.
	 *
	 * @param {object} ev - event object containing current tab information.
	 */
	function setTopicInfo (ev) {
		if (window.digitalData && window.digitalData.page && window.digitalData.page.attributes) {
			var streamName = $(ev.target).data("stream-name");

			// set article sort
			window.digitalData.page.attributes.articleSort = streamName;

			// set article count
			var articleCount = $("stream-content[data-stream-name='" + streamName + "']").find("stream-item").length;
			window.digitalData.page.attributes.articleCountPerCurrentPage = articleCount;
		}
	}
})();