/**
 * bridge to relate article.js and header.js
 * isArticle,displayingSearch,titleScrolledOff,DomElement @params
 **/
import $ from "jquery";

const headerTitle = {
	isArticle: false,
	displayingSearch: false,
	titleScrolledOff: false,
	DomElement: "null",
	showOrHide: () => {
		if (headerTitle.displayingSearch === false) {
			if (headerTitle.titleScrolledOff === true) {
				$(headerTitle.DomElement).addClass("on");
			} else {
				$(headerTitle.DomElement).removeClass("on");
			}
		} else {
			$(headerTitle.DomElement).removeClass("on");
		}
	}
};

export default headerTitle;
