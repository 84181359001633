import common from "../../common";
import request from "../request";

const uri = `${common.API}user/lists/bulk-update`;

exports.getURI = function () {
	return uri;
};

/**
 * post
 * Adds saved content to multiple reading lists
 * @param {Object} requestData
 * @returns {*}
 */
exports.post = (requestData) => {
	return request.ajax("post", uri, requestData);
};

/**
 * delete
 * Removes saved content from multiple reading list
 * @param {Object} requestData
 * @returns {*}
 */
exports.delete = function (requestData) {
	return request.ajax("delete", uri, requestData);
};
