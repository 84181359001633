/**
 * A series of functions that can be used throughout for validating
 * individual fields without having to rely heavily on jQuery
 * Validation plugin
 */

import $ from "jquery";

var validatorPatterns = exports.validatorPatterns = {
	email: {
		regex: new RegExp(/[^\s@]+@[^\s@]+\.[^\s@]+/),
		message: "Please enter a valid email address."
	},
	listName: {
		regex: new RegExp(/^(?!\s*$)[a-zA-Z0-9\s]{1,30}$/),
		message: "Folder title must be 30 characters or less and consisting of English letters, numbers or spaces."
	},
	password: {
		regex: new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#$%^&+=])(?!.* ).{10,}/),
		message: "Passwords must meet the following criteria: must have at least one number, one lower case letter, one upper case letter, and one special character (@ ! # $ % ^ & + =), no spaces, and be at least 10 characters long."
	},
	validNumber: {
		regex: new RegExp(/^[1-9][0-9]*$/),
		message: "Please enter a valid number."
	},
	firstName: {
		regex: new RegExp(/\b[^(){}/><\][\\\x22,;|]+[^(){}/><\][\\\x22,;|]+\b/),
		message: "Please enter a valid first name."
	},
	lastName: {
		regex: new RegExp(/\b[^(){}/><\][\\\x22,;|]+[^(){}/><\][\\\x22,;|]+\b/),
		message: "Please enter a valid last name."
	},
	required: {
		message: "This field is required."
	},
	maxLength: {
		message: "This field must be less than 30 characters."
	},
	unique: {
		message: "A folder with this title already exists."
	}
};

/**
* function that will validate the string through the
* designated regex
*
* @param {string} validatorString
* @param {string} type
*
* @return {boolean}
*/
var validate = exports.validate = function(validatorString, type) {
	return validatorPatterns[type].regex.test(validatorString);
};

exports.getValidationMessage = function(type) {
	return validatorPatterns[type].message;	
};

/**
* function that will run the given element through its
* proper validation
*
* @param {object} element
* @param {string} type
*
* @return {boolean}
*/
exports.validateElement = function(element, type) {
	var validateString = $(element).val();
	
	if (validateRequired(element)) {
		if (!validate(validateString, type)) {
			displayError(element, type);
			return false;
		}
	} else {
		return false;
	}
	return true;
};

/**
* function that will validate an input field that
* has multiple inputs that will be comma separated
*
* @param {object} element
* @param {string} type
*
* @return {boolean}
*/
exports.validateMulitpleElements = function(element, type) {
	var validateString = $(element).val();

	if (validateRequired(element)) {
		var validateArr = validateString.split(",");
		var dataLen = validateArr.length;

		while (dataLen--) {
			if (!validate(validateArr[dataLen], type)) {
				displayError(element, type);
				return false;
			}
		}
	} else {
		return false;
	}

	return true;
};

/**
* function that will check if a field contains valid numerical input
*
* @param {object} element
*
* @return {boolean}
*/
exports.validateNumber = function(element) {
	const number = $(element).val();

	return validatorPatterns["validNumber"].regex.test(parseInt(number));
};

/**
* function that will check if a required field is null
*
* @param {object} element
*
* @return {boolean}
*/
var validateRequired = function(element) {
	var isRequired = $(element).attr("required");

	var validateString = $(element).val();

	if (isRequired && validateString.trim().length <= 0) {
		displayError(element, "required");
		return false;
	}

	return true;
};

/**
* function that will display the error message on an
* invalid field based on jQuery Validation plugin criteria
* http://jqueryvalidation.org/reference/#markup-recommendations
*
* @param {object} element
* @param {string} type
* @param {string} customMessage
*
*/
var displayError = exports.displayError = function(element, type, customMessage) {
	let $element, $newLabel, errorLabel;
	let errorMessage;
	const id = element.id || element.name;

	if(customMessage) {
		errorMessage = customMessage;
	} else {
		errorMessage = validatorPatterns[type].message;
	}

	$element = $(element);
	errorLabel = document.getElementById(`error-for-${id}`);

	// If we already have an error, remove it and replace it with the most recent error.
	if(errorLabel !== null) {
		errorLabel.remove();
	}

	// Create an error label with a message based upon the validation type.
	$newLabel = $(`<label class="list-error error" id="error-for-${id}" error-type="${type}">${errorMessage}</label>`);

	$newLabel.attr("aria-describedby", `${id}-error`);

	// Add relevant classes and insert error label after the input field.
	$element.addClass("error");
	$element.after($newLabel);

	return true;
};

exports.validityErrorCheck = function validityCheck(field) {
	// Validate the field
	const validity = field.validity;

	// Don't validate submits, buttons, file and reset inputs, and disabled fields
	if (field.disabled || field.type === "file" || field.type === "reset" || field.type === "submit" || field.type === "button") return;

	if(validity.valid) return;

	if(validity.tooShort) {
		const minLength = field.getAttribute("minLength");
		return displayError(field, "minLength", `This field must be at least ${minLength} characters`);
	}

	if(validity.tooLong) {
		const maxLength = field.getAttribute("maxLength");
		return displayError(field, "maxLength", `This field must be less than ${maxLength} characters`);
	}

	if(validity.patternMismatch) {
		if(field.type === "password") {
			return displayError(field, "password");
		}

		if(field.type === "text") {
			return displayError(field, "patternMismatch", "Please enter a valid value into this field.")
		}
	}

	if(validity.typeMismatch) {
		if(field.type === "email") {
			return displayError(field, "email");
		}
	}

	if(validity.rangeOverflow) {
		const maxValue = field.getAttribute("max");
		return displayError(event.target, "rangeOverflow", `Please enter a value that is less than ${maxValue}`);
	}

	if(validity.rangeUnderflow) {
		const minValue = field.getAttribute("min");
		return displayError(event.target, "rangeOverflow", `Please enter a value that is more than ${minValue}`);
	}

	if(validity.stepMismatch) {
		return displayError(event.target, "stepMismatch", "Please select a valid value");
	}

	if(validity.valueMissing) {
		return displayError(field, "required");
	}

	// If we don't catch any of the above errors, return a generic error message.
	return "Please enter valid value into this field."
};

/**
* function that will remove all the previously displayed errors
*
* @param {object} element
*
*/
exports.removeError = function(element) {
	$(element).closest(".field-container").find("label.error").remove();
	$(element).siblings("label.error").remove();
	$(element).removeClass("error");
	$(element).closest(".field-container").find("input.error, select.error").removeClass("error");
};
