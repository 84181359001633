<div class="flex-row-medium">
	<figure class="magazine-cover medium-3 large-2 mts mbm mrl">
		{% if (entry.image) { %}
			<a href="/archive-toc/{{ entry.id }}"><img src="{{ entry.image }}" alt="{{ entry.title }}"></a>
		{% } %}
	</figure>
	<div>
		<div class="card mtn">
			<a href="/archive-toc/{{ entry.id }}">
				<h2 class="mvn"> {{ entry.title }} </h2>
			</a>
			<div class="dek text-gray-light mts"> {{ entry.summary }} </div>
		</div>
		<div class="eyebrow hbr-gray mbm mtl">
			Featuring
		</div>
		<ul>
			{% entry.items.forEach(function(feature) { %}<li class="dark-gray">
				<a href="{{ feature.link }}">{{ feature.title }}</a></li>{% }); %}
		</ul>
	</div>
</div>
