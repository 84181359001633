import $ from "jquery";

(() => {
	/**
	 * Set event information to digital data for form interaction.
	 *
	 * @param {object} ev - event object.
	 * @param {String} formName - Form name.
	 * @param {String} eventAction - Event action name.
	 */
	var handleFormInteraction = (ev, formName, eventAction) => {
		if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
			// set event info
			var eventInfo = {};
			eventInfo.eventName = "Form Interaction";
			eventInfo.eventAction = eventAction;
			var event = {};
			event.eventInfo = eventInfo;
			window.digitalData.event = [];
			window.digitalData.event.push(event);
			// set form name
			if (!window.digitalData.page.pageInfo.attributes) {
				window.digitalData.page.pageInfo.attributes = {};
			}
			window.digitalData.page.pageInfo.attributes.formName = formName;
		}
	}

	// bind event
	$("body").on("form-interaction", handleFormInteraction);
})();