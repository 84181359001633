
import common from "../../common";
import request from "../request";

/**
 * Augment the URI based on passed method.
 *
 * @returns {string} A string URI.
 */
exports.getURI = function() {
	return common.API + "user/marketingcampaign/subscribe";
};

/**
 * delete
 * Unsubscribes user from a marketing campaign subscription
 * @param {Object} data
 * @returns {*}
 */
exports.delete = function (data) {
	return request.ajax("DELETE", common.API + "user/marketingcampaign/subscribe", data);
};
