
import $ from "jquery";
import contentTemplate from "./template";
import interactionService from "../../../services/hbr/interaction";
import subscriptionService from "../../../services/subscription-utils";
import validatorService from "../../../services/validator-utils";
import FolderList from "../../folder-list/folder-list";
import _sortBy from "lodash-amd/modern/collections/sortBy";
import {updateFocusableContent} from "../flyout";

var options = {
	saveButtonTarget: "[js-target='save-submit']",
	saveListButtonTarget: "[js-target='save-new-list']",
	removeNewListButtonTarget: "[js-target='remove-new-list-checkbox']",
	readingListCheckboxes: "[js-target='save-list-checkbox']",
	newListFormTarget: "[js-target='save-new-list-form']",
	listNameTarget: "[js-target='list-form-name']",
	listDescTarget: "[js-target='list-form-description']",
	listIdTarget: "[js-target='list-form-id']",
	createListButtonTarget: "[js-target='create-new-list']",
	saveNewListFormError: "[js-target='save-new-list-form-error']",
	folderListName: "[js-target='folder-list-name']"
};

var saveContent = null;
var currentStreamItem = null;

var destroy = function () {
	$(exports).trigger("destroy", {message: "Saved!"});
	// Update digital data
	var eventData = {};
	eventData.eventAction = "Content Save";
	eventData.element = currentStreamItem;
	$("body").trigger("content-action:success", eventData);
};

var destroyOnError = function (failureMessage) {
	$(exports).trigger("destroy", {message: failureMessage || "Saving failed!"});
};

/**
 * render
 * @returns {Object} Promise
 */
exports.render = function () {
	var saveRenderDeferred = $.Deferred();
	var renderedTemplate = null;

	// Get all reading lists.
	interactionService.request("lists", "get").then(function(response) {
		if (response) {
			renderedTemplate = contentTemplate({
				readingLists: _sortBy(response, "name")
			});

		}
		// Find which reading lists this list-item belongs to.
	}).then(function() {
		if (saveContent) {
			return interactionService.request("listItem", "get", saveContent.id)
				.then(function (resp) {
					saveRenderDeferred.resolve(renderedTemplate);
					// Ensure all valid items are checked.
					resp.forEach(function (list) {
						$("#save-" + list.id).attr("checked", true);
					});
				});
		} else {
			saveRenderDeferred.resolve(renderedTemplate);
		}
	});

	return saveRenderDeferred.promise();
};

var showNewListForm = function (e) {
	e.preventDefault();
	if($(options.newListFormTarget).is(":hidden")) {
		$(options.saveListButtonTarget).find("i.icon").hide();
		$(options.newListFormTarget).show();
		checkTitle();
	} else {
		removeNewListForm(e);
	}
};

/**
 * removeFormList
 * Function to remove the new folder list form and clean the input fields.
 * So we can ignore the frontend validation checks for these fields.
 */
var removeNewListForm = function (e) {
	e.preventDefault();

	let $submit, $folderTitleInput, $folderDescInput;

	$folderTitleInput = $(document.getElementById("list-form-name"));
	$folderDescInput = $(document.getElementById("list-form-description"));
	$submit = $(document.querySelectorAll("[js-target='save-submit']")[0]);

	$(options.saveListButtonTarget).find("i.icon").show();

	// Hide form
	$(options.newListFormTarget).hide();

	// Clean input fields and any errors to ensure we do not create a new folder when the form is closed.
	$folderTitleInput.val("");
	$folderDescInput.val("");
	validatorService.removeError($folderTitleInput);

	// Re-enable submit button, since we aren't waiting for valid form input.
	// We can allow the user to save to another pre-existing folder.
	// This will bypass the interactionService request due to no new folder being created.
	$submit.prop("disabled", false);
}

/**
 * createNewList
 * Click Handler for the Create New List Flyout.
 */
var createNewList = function(e) {
	e.preventDefault();

	var createListPromise = exports.createList();
	if (typeof createListPromise === "boolean" && !createListPromise) {
		return false;
	} else {
		createListPromise.fail(destroy);
		return $.when.apply(createListPromise).always(destroy);
	}
};

/**
 * save
 * Click handler for save button. First executes a general "save" using the
 * interaction service. Then posts a request to any existing selected reading
 * lists.
 *
 * Finally, creates a new reading list and saves the item to that new list if
 * the user has requested it.
 */
function save (e) {
	e.preventDefault();

	if (saveContent) {
		return addToSaves().then(function (resp) {
			var saveId = resp.id;
			return exports.addToLists(saveId);
		}, destroy);
	}
}

function addToSaves () {
	return interactionService.request("saved", saveContent);
}

/**
 * isFolderUnique
 * Takes an array of DOM nodes, along with a single input node and then
 * extracts the text value from the node and then compares if the value
 * is unique
 * @param {array} nodeList
 * @param {object} newNode
 * @return {boolean}
 */
function isFolderUnique(nodeList, newNode) {
	const elementNames = nodeList.map((index, item) => $(item).text().toLowerCase().replace(/\s/g, ""));
	const newElementName = $(newNode).val().toLowerCase().replace(/\s/g, "");

	if($.inArray(newElementName, elementNames) > -1) {
		return false;
	}

	return true;
}

exports.addToLists = function (saveId) {
	const listOfFolders = $(options.folderListName);
	const newListName = $(options.listNameTarget);
	const saveCheckboxes = $(options.readingListCheckboxes);
	const removeFromLists = [];
	const addToLists = [];

	saveCheckboxes.each((index, input) => {
		const listId = Number(input.dataset.listId);

		if (input.checked) {
			addToLists.push(listId);
		} else {
			removeFromLists.push(listId);
		}
	});

	const savePromises = [];
	let newFolderPromise = Promise.resolve();

	if (newListName.val().length !== 0) {
		if (isFolderUnique(listOfFolders, newListName)) {
			newFolderPromise = new Promise((resolve) => {
				savePromises.push(
					exports.createList()
						.then(list => addToLists.push(list.id))
						.done(resolve)
				);
			});
		} else {
			validatorService.displayError(newListName, "unique");
			return false;
		}
	}

	return newFolderPromise
		.then(() => {
			if (addToLists.length > 0) {
				savePromises.push(
					interactionService.request("bulkListItem", {
						id: saveId,
						listIds: addToLists,
					})
				);
			}

			if (removeFromLists.length > 0) {
				savePromises.push(
					interactionService.request("bulkListItem", "delete", {
						id: saveId,
						listIds: removeFromLists,
					})
				);
			}

			return $.when.apply($, savePromises)
				.fail(function(jqXHR) {
					$(options.saveListButtonTarget).find("i.icon").show();
					$(options.newListFormTarget).hide();

					var errorLabel = document.querySelector("[js-target='save-new-list-form-error']");
					if (errorLabel) {
						errorLabel.innerHTML = jqXHR.status === 409 ? `Folder ${newListName} already exists` : "There was an error while processing your request, please try again later.";

						errorLabel.className = errorLabel.className.replace("is-hidden");
						setTimeout(function () {
							errorLabel.className += "is-hidden";
							destroyOnError("Save operation failed!");
						}, 5000);
					}
				})
				.done(destroy);
		});
};

exports.createList = function () {
	var listName = $(options.listNameTarget).val();
	var listDesc = $(options.listDescTarget).val();
	var listId = $(options.listIdTarget).val();

	validatorService.removeError($(options.listNameTarget));
	if (!validatorService.validateElement($(options.listNameTarget), "listName")) {
		return false;
	}
	return interactionService.request("lists", {
		name: listName,
		description: listDesc,
		id: listId
	}).then(function(response) {
		// trigger a list refresh event
		FolderList.updateFolder(response);
		updateFocusableContent();
		
		return response;
	});
};

/**
 * getOverrideOptions
 * Returns a list of options specific to the save flyout
 * @returns {{}}
 */
exports.getOverrideOptions = function () {
	return options;
};

/**
 * getData
 * Gets necessary data from the DOM for rendering share template and
 * hitting share/history end points
 * @param {Object} $element
 * @returns {Object}
 */
exports.getData = function ($element) {
	var $streamItem = $element.parents("stream-item");
	if ($(".article-content-flex2019").length > 0) {
		$streamItem = $("page-utils");
		saveContent = {
			id: $streamItem.data("id"),
			title: $streamItem.data("title"),
			url: subscriptionService.processUrl($streamItem.data("url")),
			topic: $streamItem.data("topic"),
			authors: $streamItem.data("authors"),
			contentType: $streamItem.data("content-type"),
			image: $streamItem.data("content-image"),
			summary: $streamItem.data("summary"),
			listPrice: $streamItem.data("list-price"),
			activityType: "saved"
		};
	} else {
		saveContent = {
			id: $streamItem.attr("data-id"),
			title: $streamItem.data("title"),
			url: subscriptionService.processUrl($streamItem.attr("data-url")),
			topic: $streamItem.data("topic"),
			authors: $streamItem.data("authors"),
			contentType: $streamItem.data("content-type"),
			image: $streamItem.data("content-image"),
			summary: $streamItem.data("summary"),
			listPrice: $streamItem.data("list-price"),
			activityType: "saved"
		};
	}
	currentStreamItem = $streamItem;
};

exports.bindInteractions = function () {
	$(options.saveButtonTarget).on("click", save);
	$(options.saveListButtonTarget).on("click", showNewListForm);
	$(options.removeNewListButtonTarget).on("click", removeNewListForm);
	$(options.createListButtonTarget).on("click", createNewList);
};


function checkTitle() {
	let $submit, $lnTarget;

	$submit = $(document.querySelectorAll("[js-target='save-submit']")[0]);
	$submit.prop("disabled", true);
	document.getElementById("list-form-name").addEventListener("input", () => {
		$lnTarget = $(document.getElementById("list-form-name"));

		if(validatorService.validateElement($lnTarget, "listName")) {
			validatorService.removeError($lnTarget);
			$submit.prop("disabled", false);
		}else{
			$submit.prop("disabled", true);
		}
	});
};

