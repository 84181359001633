/**
 * A series of functions that can be used throughoutfor checking
 * the url length and adjusting it according to need.
 */

var urlRegex = new RegExp(/.*hbr\.org\//i);

/**
* function that will cehck to see if the url path is
* absolute
*
* @param {string} url
*
* @return {boolean}
*/
var isUrlAbsolute = function(url) {
	return url && url.match(urlRegex);
};

/**
* function that will add the full path to the relative URL
*
* @param {string} url
*
* @return {string}
*/
exports.setFullPath = function(url) {
	return !isUrlAbsolute(url) ? window.location.origin + url : url;
};

/**
* function that will set the full path to relative path
*
* @param {string} url
*
* @return {string}
*/
exports.setRelativePath = function(url) {
	return isUrlAbsolute(url) ? url.replace(url.match(urlRegex), "/") : url;
};
