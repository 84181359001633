
import common from "../../common";
import request from "../request";

exports.getURI = function () {
	return common.API + "user/lists";
};

/**
 * delete
 * Removes saved content from a reading list
 * @param {Object} data
 * @returns {*}
 */
exports.delete = function (data) {
	return request.ajax("DELETE", common.API + "user/lists", data);
};
