
import $ from "jquery";

// Sharing a lot of features with save.
import save from "../save/save";
import interactionService from "../../../services/hbr/interaction";
import _map from "lodash-amd/modern/collections/map";
var $component = $(exports);

var itemsToAdd = [];

var options = {
	saveButtonTarget: "[js-target='save-submit']",
	readingListCheckboxes: "[js-target='save-list-checkbox']",
	listNameTarget: "[js-target='list-form-name']"
};

/**
 * Pass through to save flyout
 * @returns {*|Object}
 */
exports.render = function () {
	return save.render();
};

/**
 * getData
 * Pass through to save flyout
 * @param {Object} $element
 * @returns {Object}
 */
exports.getData = function ($element) {
	itemsToAdd = _map($("stream-list").find("[js-target='bulk-action-checkbox']:checked"), function(el) {return $(el).attr("id"); });
	return itemsToAdd;
};

var bulkAdd = function (e) {
	e.preventDefault();
	e.stopImmediatePropagation();
	var saveCheckboxes = $(options.readingListCheckboxes);
	var addPromises = [];

	$.each(itemsToAdd, function () {
		var item = this;

		$.each(saveCheckboxes, function () {
			let reqType = $(this).is(":checked") ? "post" : "delete";
			addPromises.push(interactionService.request("listItem", reqType, {
				name: $(this).data("list-id"),
				data: {
					id: item
				}
			}));
		});
	});

	var newListName = $(options.listNameTarget).val();
	if (newListName.length !== 0) {
		save.createList().then(function (folderCreated) {
			$.each(itemsToAdd, function () {
				addPromises.push(interactionService.request("listItem", {
					name: folderCreated.id,
					data: {
						id: this
					}
				}));
			});
		});
	}

	$.when.apply($, addPromises).then(function () {
		$component.trigger("destroy", {message: itemsToAdd.length + (itemsToAdd.length === 1 ? " item" : " items") + " added to folders"});
	});
};

exports.bindInteractions = function () {
	save.bindInteractions();

	$(options.saveButtonTarget).on("click", bulkAdd);
};
