
// Depedencies
import $ from "jquery";
// Services
import cartService from "../../services/cart";
import utils from "../../services/shopping-cart-utils";
import state from "services/state";

// The component mixin.
import modalBox from "../modal-box/modal-box";
import mixinComponent from "../../mixins/component";
import AddToCartConfirmation from "./add-to-cart-confirmation/add-to-cart-confirmation";

export default function AddToCart($el) {
	this.initializeAsComponent($el);
}

AddToCart.prototype = {
	globalEvents: {
		"click [js-target='add']": "add",
		"click [js-target='add-bt-multiple']": "addBTMultiple",
		"click [js-target='speed-pay']": "speedPay"
	},

	events: {
		"click [js-target='item-quantity-value']": "updateQuantityValue",
		"keyup [js-target='item-quantity']": "updateQuantity",
		"click [js-target='permission-quantity-value']": "updateQuantityValue",
		"click [js-target='checkbox-better-together']": "updateBTGroupPrice"
	},

	/**
	* function that will add an item to the cart
	*
	* @param {object} ev - event object
	*
	* @return {object} cart
	*/
	add: function(ev) {
		ev.preventDefault();
		ev.stopImmediatePropagation();

		/** we want to hit the ProductUrlCleanerFilter that corrects SEO titles
		 * add /an before the valid sku and it should redirect to the correct page */
		var itemToAddToCart = $(ev.currentTarget).closest("add-to-cart")[0];
		var productUrl = itemToAddToCart.dataset.productUrl;
		if (!(productUrl.startsWith("http://") || productUrl.startsWith("https://")) && !productUrl.includes("/an/") && productUrl.includes("/product/")) {
			var urlSegments = productUrl.split("/");
			urlSegments.splice(3, 0, "an");
			window.location.href = urlSegments.join("/");
			
		} else {
			window.location.href = productUrl;
		}
		
	},

	addBTMultiple: function(ev) {
		ev.preventDefault();
		ev.stopImmediatePropagation();
		// get product items belonging to similar group
		var addToCartItems = $("add-to-cart[data-group-name='better-together']");
		var selectedAddToCartItems = [];

		if (addToCartItems) {
			addToCartItems.each(function(i, addToCartItem) {
				if ($(addToCartItem).find("[js-target='checkbox-better-together']").is(":checked")) {
					selectedAddToCartItems.push(addToCartItem);
				}
			});
		}

		if (selectedAddToCartItems.length > 0) {
			modalBox.activate($("body")).render();
			addToCart(selectedAddToCartItems).then(function(response) {
				modalBox.close();
				AddToCartConfirmation.activate($("body")).render({items: response.addToCartItems, itemAdded: true});
				$("body").trigger("update:cart", response.cart);
				$("body").trigger("add-to-cart:success", [response.cart, response.addToCartItems]);
			}, function(error) {
				modalBox.close();
				AddToCartConfirmation.activate($("body")).render({items: error.addToCartItems, itemAdded: false});
			});
		}
	},

	updateBTGroupPrice: function(ev) {
		$("[js-target='price-better-together']").html(this.getBTTotalPrice());
	},

	getBTTotalPrice: function() {
		var totalPrice = 0;
		var btCheckElems = $("[js-target='checkbox-better-together']");
		if (btCheckElems) {
			btCheckElems.each(function(i, el) {
				if ($(el).is(":checked")) {
					var itemPrice = parseFloat($(el).closest("add-to-cart").data("product-price"));
					totalPrice += itemPrice;
				}
			});
		}
		return totalPrice.toFixed(2);
	},

	/**
	* function that will run the addition to the cart through the spped pay
	* process
	*
	* @param {object} ev
	*
	*/
	speedPay: function(ev) {
		ev.preventDefault();
		ev.stopImmediatePropagation();
		addToCart($(ev.currentTarget).closest("add-to-cart")).then(function(response) {
			$(ev.currentTarget).closest("form").trigger("submit");
		});
	},

	/**
	* function that will update the quantity value when the increase
	* or decrease value is clicked
	*
	* @param {object} ev
	*
	*/
	updateQuantityValue: function(ev) {
		ev.preventDefault();
		var selectedData = $(this.$el).data();
		var thisEl = $(ev.currentTarget);
		var thisData = $(thisEl).data();
		var isPermission = thisData.className ? thisData.className.indexOf("permission") >= 0 : false;
		var updateDir = thisData.changeType;
		var itemQuantityVal = parseInt($(this.$el).find("[js-target='item-quantity']").val(), 10);
		if (!isPermission && utils.getProductType(selectedData.productId) === "Digital" && updateDir === "up" && itemQuantityVal > 0) {
			return false;
		}

		var inputClassName = "." + $(thisEl).data().className;
		utils.updateQuantityValue(thisEl, inputClassName, ev);
	},

	/**
	* function that will evaluate whether the quantity
	* can be increased to higher than 1 when a product of
	* of type Digital has been selected
	*
	* @param {object} ev
	*
	*/
	updateQuantity: function(ev) {
		//ev.preventDefault();
		//var selectedData = $(this.$el).data();
		//var thisEl = $(ev.currentTarget);
		//var itemQuantityVal = parseInt($(thisEl).val(), 10);
	}
};

/**
* function that will retrieve all the necessary product information to submit
* for adding to cart
*
* @param {object} productEl
* @param {object} itemObj
*
* @return {object} item
*/

/*var getProductDetails = function(addToCartEl) {
	var itemData = $(addToCartEl);
	itemObj.productId = $(addToCartEl).attr("data-product-id")
	itemObj.productPrice = $(addToCartEl).attr("data-product-price")";
	itemObj.productUrl = $(addToCartEl).attr("data-product-url")";
	itemObj.productContentType = $(addToCartEl).attr("data-product-content-type")";
	itemObj.type = $(addToCartEl).attr("data-type");
	if (!itemData.type) {
		itemObj.type = utils.getProductType(itemObj.productId);
	}
	itemObj.format = $(addToCartEl).attr("data-format")";
	return itemObj;
};*/


/**
* function that will process all the necessary steps and
* prepare the item to be added to the cart
*
* @param {object} cartEl - add-to-cart element
*
* @return {promise.object} promise returned for adding to cart
*/
var addToCart = function(cartElements) {
	var dfd = new $.Deferred();
	var items = [];
	var cartEls = [];
	if ($.isArray(cartElements)) {
		cartEls = cartElements;
	} else {
		cartEls.push(cartElements);
	}

	cartEls.forEach(function(cartEl) {
		var item = $(cartEl).data();
		var referral = item.productUrl && item.productUrl.match(/\?.*referral=(\d+)/);
		item.quantity = 1;
		if (referral && referral.length > 1) {
			item.referral = referral[1];
		}
		if (!item.type && item.productId) {
			item.type = utils.getProductType(item.productId);
		}
		// only add the items if the quantity is greater than 0
		if (item.quantity > 0) {
			items.push(item);
		}
	});

	if (items.length > 0) {
		cartService.addToCart(items).then(function(response) {
			response.addToCartItems = items;
			dfd.resolve(response);
		}, function(response) {
			response.addToCartItems = items;
			dfd.reject(response);
		});
	}
	return dfd;
};

mixinComponent(AddToCart, "add-to-cart");
state.addInitializer(AddToCart);
