/**
 * @module components/stream-section/stream-section
 * @requires module:jquery
 * @requires module:mixins/component
 * @requires module:services/stream
 */

// Component mixin.
import mixinComponent from "../../mixins/component";

// Internal related components.
import TabBar from "../tab-bar/tab-bar";
import StreamContent from "./stream-content/stream-content";

import $ from "jquery";

/**
 * Represents a StreamSection component.
 *
 * @constructor
 * @public
 */
export default function StreamSection($el) {
	this.initializeAsComponent($el);

	TabBar.activateAll($el);
	this.$streamContent = StreamContent.activateAll($el);
}

StreamSection.prototype = {
	events: {
		"tab-bar:change [js-stream-section='chooser']": "toggleStream"
	},

	toggleStream: function(ev) {
		var target = this.$("stream-content[data-stream-name='" + $(ev.target).data("stream-name") + "']");
		this.$streamContent.not(target).removeClass("active");
		target.addClass("active").find("stream-list").trigger("request:placement");

		// Update current section of My Library when using the tab navigation
		window.digitalData.page.pageInfo.pageURL = ev.target.baseURI;
	}
};

// Decorate this constructor as a component.
mixinComponent(StreamSection, "stream-section");
