import axios from "axios";

exports.generateLinkAndUpdateCount = function () {
	const endpoint = "/api/article-gift/give-gift";
	return new Promise((resolve, reject) => {
		axios.post(endpoint,
			{"url": window.location.href},
			{headers: {"Content-Type": "application/json"}})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
