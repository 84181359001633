
import $ from "jquery";
import storageService from "../localstorage-util";
import stateService from "../state";
import _contains from "lodash-amd/modern/collections/contains";

exports.evaluate = function() {
	var promise = $.Deferred();

	/* get the current page */
	var page = window.location.pathname;
	var messages = storageService.getCache("help-messages") || {seen: []};

	/* determine which rules apply - disabled all sticky boxes December 2018 */
	if (page === "/search" && stateService.current.indexOf("subscriber") === 0) {
		if (!_contains(messages.seen, "subscriber-search")) {
			resolve("subscriber-search", promise);
		}
	}
	return promise;
};

/**
 * function that resolves the promise with the help message
 * provided
 *
 * @param {string} message - help message
 * @param {object} promise - promise to resolve with the help message
 */
var resolve = function(message, promise) {
	/* retrive the list of help messages already seen from local storage */
	var messages = storageService.getCache("help-messages") || {seen: []};
	if (message !== null) {
		messages.seen.push(message);
		storageService.setCache("help-messages", messages);
		promise.resolve(message);
	} else {
		promise.resolve();
	}
};
