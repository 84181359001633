{%if (title && title.length>0) {
	if (bigIdea) { %}
		<a href="/big-ideas" target="_blank" class="headline--article-big-idea">The Big Idea Series<span class="series-title">{{ seriesTitle }}</span></a>
	{% } else if (series) { %}
		<span class="headline--article-series">
			{{series}} /
		</span>
		<span class="headline--article-series-title">
			{{seriesTitle}}
		</span>
	{% } else { %}
		{% if (topic) { %}
			<span class="headline--article-topic">
				<a href="{{topicURL}}" class="hover-opacity-60">
					{{topic}}
				</a>
			</span>
			<span class="headline--article-separator">&nbsp;&nbsp;&#124;&nbsp;&nbsp;</span>
		{% } %}
		<span class="headline--article-title">
			{{title}}
		</span>
	{% }
} %}
