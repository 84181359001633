import $ from "jquery";
import auth from "../../../services/auth";
import contentTemplate from "./template";
import {removeError, validateElement} from "../../../services/validator-utils";

/**
 * render
 *
 * @return
 */
exports.render = function () {
	var saveRenderDeferred = $.Deferred();
	saveRenderDeferred.resolve(contentTemplate());
	return saveRenderDeferred.promise();
};

/**
 * Binds the events to the flyout component type.
 *
 * @param {Object} $el - A jQuery element of the flyout container.
 */
exports.bindInteractions = function($el) {
	const $notificationBoxMessage = $el.find("[js-target='notification-box-message']");
	const $notificationBox = $el.find("[js-target='notification-box']");

	const displayError = (errorMessage) => {
		$notificationBoxMessage.html(errorMessage);
		$notificationBox.show();
	};

	// Wait for the form to be submitted before acting on it.
	$el.on("submit", "form", function(evt) {
		$notificationBox.hide();
		evt.preventDefault();
		evt.stopPropagation();

		// Find the password values.
		const currentPassword = document.getElementById("current-password");
		const newPassword = document.getElementById("new-password");
		const confirmPassword = document.getElementById("confirm-password");

		if (!currentPassword.value) {
			displayError("Current Password is required");
			return;
		}

		if (newPassword.value !== confirmPassword.value) {
			displayError("New Password and Confirm Password do not match");
			return;
		}

		if (!newPassword.value) {
			displayError("New Password is required");
			return;
		}

		if (!validateFormField(newPassword, "password")) {
			return;
		}

		// Issue the request to the server, close on success or failure.
		auth.changeUserPassword(currentPassword.value, newPassword.value).then(function() {
			$(exports).trigger("destroy", {
				message: "Password successfully changed."
			});
		}).fail(function(response) {
			// show the error message
			var errorMessage = "Current Password is invalid";
			displayError(errorMessage);
		});
	});
};

const validateFormField = (element, patternType) => {
	if (!validateElement(element, patternType)) {
		return false;
	}
	removeError(element);
	return true;
};