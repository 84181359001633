import mixinComponent from "../../mixins/component";
import user from "../../models/user";
import SignInModal from "../../react/AuthModal/Modal";

export default function FooterMenu($el) {
	this.initializeAsComponent($el);
}

FooterMenu.prototype = {
	renderSignInModal: function(ev) {
		ev.preventDefault();
		SignInModal.render();
	},
	
	validateUserSignedRedirect: function(ev) {
		if(user.isGuest()) {
			ev.preventDefault();
			ev.stopPropagation();
			this.renderSignInModal(ev);
		}
	},
	
	events: {
		"click [js-target='footer-item']": "showFooterList",
		"click [js-target='validate-user-signed']": "validateUserSignedRedirect"
	},

	showFooterList: function(ev) {
		this.$("[js-target='footer-item']").not(ev.currentTarget).removeClass("show-list");
		this.$(ev.currentTarget).toggleClass("show-list");
	}
};

mixinComponent(FooterMenu, "footer-menu");
