
import $ from "jquery";
import contentTemplate from "./template";
import auth from "../../../services/auth";
import CaptchaService, {CaptchaActions, CaptchaKeys} from "../../../services/captcha-service"
import {validateElement, removeError} from "../../../services/validator-utils";
import common from "../../../common";

var options = {
	requiresSignIn: false,
	confirmationTimeout: 7000
};

/**
 * render
 * @returns {*}
 */
exports.render = function (data) {
	var forgotPasswordRenderDeferred = $.Deferred();
	forgotPasswordRenderDeferred.resolve(contentTemplate(data));
	return forgotPasswordRenderDeferred.promise();
};

/**
 * getData
 * Gets necessary data from the DOM for rendering share template and
 * hitting share/history end points
 * @param {Object} $element
 * @returns {Object}
 */
exports.getData = function ($element) {
	/* no data necessary */
};

/**
 * getOverrideOptions
 * Sign in not required
 * @returns {Object}
 */
exports.getOverrideOptions = function () {
	return options;
};

exports.bindInteractions = function ($el) {
	/* get the email from the form */
	const email = document.getElementById("email-address-forgot-password-flyout");

	let captchaWidget;
	let captchaToken;
	CaptchaService.isCaptchaEnabled()
		.then((isEnabled) => {
			if (isEnabled) {
				grecaptcha.enterprise.ready(() => {
					captchaWidget = grecaptcha.enterprise.render("captchaTargetFlyoutForgotPass", {
						sitekey: CaptchaKeys.checkbox,
						callback: (token) => {
							captchaToken = token;
						},
						"expired-callback": () => {
							captchaToken = null;
						},
					});
				});
			}
		});

	const validateCaptcha = () => {
		const errElem = document.getElementById("captchaFlyoutError");

		if (captchaToken) {
			errElem.style.visibility = "hidden";
			return true;
		}

		errElem.style.visibility = "visible";
		return false;
	};

	$("[js-target='send-message']").on("click", async function (e) {
		e.preventDefault();
		let isValid = true;
		if (!validateFormField(email, "email")) {
			isValid = false;
		}

		const isCaptchaEnabled = await CaptchaService.isCaptchaEnabled();
		if (isCaptchaEnabled && !validateCaptcha()) {
			isValid = false;
		}

		if (isValid) {
			CaptchaService.isCaptchaEnabled()
				.then((isEnabled) => {
					if (isEnabled) {
						grecaptcha.enterprise.ready(() => {
							grecaptcha.enterprise.execute(CaptchaKeys.score, {action: CaptchaActions.SIGN_IN_PAYWALL});
						});
						verifyForgotPassword(email.value, captchaToken);
						grecaptcha.enterprise.reset(captchaWidget);
						captchaToken = null;
					} else {
						verifyForgotPassword(email.value);
					}
				});
		}
	});
};

const verifyForgotPassword = (email, captchaToken) => {
 	auth.verifyForgotPassword(email, captchaToken).then(function () {
		$(exports).trigger("destroy", {
			message: "If your email is registered with us, you will receive an email with a link to reset your password."
		});
	}.bind(this), function (data) {
		var errorMessage;
		if (data.status == 423) {
		    errorMessage = common.FORGOT_ERROR_ACCOUNT_LOCK;
		} else {
			errorMessage = common.ERROR_MESSAGE;
		}
		$(exports).trigger("destroy", {
			message: errorMessage});
	}.bind(this));
};

const validateFormField = (element, patternType) => {
	if (!validateElement(element, patternType)) {
		return false;
	}
	removeError(element);
	return true;
};
