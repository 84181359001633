import React, {Component} from 'react';
import PasswordComponent from "./PasswordComponent";
import { RequiredField, NoSpacesSignIn, EmailRegularExpresion, PasswordRegularExpresion} from './FieldValidators';
import withFieldValidation from "../Form/withFieldValidation";

function TextField(props) {
	let className = props.valid ? "" : "error";
	return (
		<input 
			id={props.name} 
			type="text"
			className={className}
			{...props}
			/>
	)
}

const RequiredTextField = withFieldValidation(
	TextField,
	RequiredField
)

const SignInRequiredEmailField = withFieldValidation(
	TextField,
	RequiredField,
	NoSpacesSignIn
)

const RequiredEmailField = withFieldValidation(
	TextField,
	RequiredField,
	EmailRegularExpresion
)

const RequiredPasswordField = withFieldValidation(
	PasswordComponent,
	RequiredField
)

const ValidatedPasswordField = withFieldValidation(
	PasswordComponent,
	RequiredField,
	PasswordRegularExpresion
)

exports.RequiredTextField = RequiredTextField;
exports.RequiredEmailField = RequiredEmailField;
exports.RequiredPasswordField = RequiredPasswordField;
exports.ValidatedPasswordField = ValidatedPasswordField;
exports.SignInRequiredEmailField = SignInRequiredEmailField;
