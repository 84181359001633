/**
 * @module components/stream-section/stream-content/stream-content
 * @requires module:mixins/component
 * @requires module:components/stream-section/stream-list/stream-list
 */

// Component mixin.
import $ from "jquery";
import _escape from "lodash/escape";
import mixinComponent from "../../../mixins/component";

// Stream list component.
import StreamList from "../stream-list/stream-list";

/**
 * Represents a StreamContent component.
 *
 * @constructor
 * @public
 */
export default function StreamContent($el) {
	this.initializeAsComponent($el);
	var $streamList = this.$streamList = StreamList.activateAll($el);

	function updateFolderInfo({description, id, name}) {
		$el.find("[js-target='folder-description']").html(
			id === "all" ? "" : `<strong>${name}</strong> ${_escape(description)}`
		);
	}

	$el.on("change:stream", function(ev, data) {
		var streamParam = "";
		if (data.streamParam) {
			streamParam = data.streamParam;
		}
		$streamList.data("component").change(data.streamEndpoint, streamParam);
		updateFolderInfo(data);
	});
	$el.on("tab-bar:change", function(ev, data) {
		$streamList.data("component").change($(ev.target).data("stream-endpoint"));
	});
	$el.on("topic-menu:change", function(ev, data) {
		$streamList.data("topic-name", data.name ? data.name : "Everything");
		let name = data.name === "R&D" ? "RD" : data.name;	
		$streamList.data("component").change("components/myhbr/following", data.id === "all" ? null : "filter=" + name);
	})
}

// Decorate this constructor as a component.
mixinComponent(StreamContent, "stream-content");
