import create from "zustand"

var initialForm = {
	email: "",
	captcha: ""
}

var initialClear = {
	emailAddress: []
}

var initialState = {
	form: {
		...initialForm
	},
	errors: {
		...initialForm,
	},
	errClearTrigger: {
		...initialClear,
	},
	pristine: {
		email: true,
		captcha: true
	},
	valid: false,
	isLoading: false
}

function willFormBeValid(state, fieldName, fieldError, validateExternals) {
	// All other fields are not pristine
	const isNotPristine = Object.entries(state.pristine).every(
		([key, value]) => {
			if (value === false || key === fieldName) return true;
			return false;
		}
	);
	// All other fields have no errors
	const hasNoErrors = Object.entries(state.errors).every(([key, value]) => {
		if (value === "" || key === fieldName) return true;
		return false;
	});

	// This fields are not pristing and have no error
	return isNotPristine && hasNoErrors && fieldError === "";
}

const passwordResetStore = create((set, get) => ({
	...initialState,
	reset: () => set(() => initialState),
	updateValue: (fieldName, value, error) => {
		set((state) => {
			let {
				errors,
				errClearTrigger
			} = state;
			Object.entries(errClearTrigger).forEach(([target, cleanOnArr]) => {
				if (cleanOnArr.includes(fieldName)) {
					errors[target] = initialForm[target];
					errClearTrigger[target] = [];
				}
			});
			// If field has an error then add the clean trigger
			if (error !== "") {
				errClearTrigger = {
					...errClearTrigger,
					[fieldName]: [fieldName],
				};
				errors = {
					...errors,
					[fieldName]: error,
				};
			}
			const isValid = willFormBeValid(state, fieldName, error);

			return {
				form: {
					...state.form,
					[fieldName]: value,
				},
				errors: {
					...errors,
				},
				errClearTrigger: {
					...errClearTrigger,
				},
				pristine: {
					...state.pristine,
					[fieldName]: false,
				},
				valid: isValid,
			};
		});
	},
	updateError(fieldName, error, clearOn) {
		set((state) => ({
			errors: {
				...state.errors,
				[fieldName]: error,
			},
			errClearTrigger: {
				...state.errClearTrigger,
				[fieldName]: clearOn,
			},
			valid: willFormBeValid(state, fieldName, error),
		}));
	},
	setLoading: (submitting) => {
		set(state => ({
			isLoading: submitting
		}))
	}
}))

export default passwordResetStore
