
import $ from "jquery";

// Sharing a lot of features with share.
import share from "../share/share";
import interactionService from "../../../services/hbr/interaction";

exports.getData = function ($element) {
	// get the list data
	var list = {
		id: $element.data("listId"),
		name: $element.data("listName"),
		title: $element.data("listName"),
		summary: $element.data("listDescription")
	};
	return list;
};

/**
 * Pass through to share flyout
 * @returns {*|Object}
 */
exports.render = function (data) {
	// request the url for this reading list
	return interactionService.request("listUrl", "get", data.name).then(function(response) {
		data.url = "https://hbr.org" + response.listurl;
		data.activityType = "shared";
		data.contentType = "list";
		return share.render(data);
	});
};

exports.bindInteractions = function () {
	// Bind the share interactions
	share.bindInteractions();

	// We pass off lots of functionality to the share flyout
	// But the share flyout destroy event won't destroy this flyout
	$(share).on("destroy", function(e, data) {
		$(exports).trigger("destroy", data);
	});
};
