
import common from "../../common";
import request from "../request";

exports.getURI = function() {
	return "/api/shopping-cart";
};

exports.get = function() {
	return request.ajax("GET", exports.getURI(), "");
};

exports.post = function(data) {
	return request.ajax("POST", exports.getURI(), data);
};

exports.delete = function(data) {
	return request.ajax("DELETE", exports.getURI(), data);
};

exports.checkFreshness = function() {
	return request.ajax("HEAD", common.API + "commerce/shopping-cart");
};

exports.shippingEstimate = function(data) {
	return request.ajax("POST", "/api/shopping-cart/estimate", data);
};

exports.shippingPayment = function(data) {
	return request.ajax("POST", common.API + "commerce/shipping-payment", data);
};

exports.statesProvinces = function(countryCode) {
	return request.ajax("GET", common.API + "commerce/state-province/" + countryCode);
};

exports.productAvailability = function(productId) {
	return request.ajax("GET", common.API + "product/" + productId + "/availabilities");
};

exports.submitOrder = function() {
	return request.ajax("POST", common.API + "commerce/order/submit");
};

exports.updateShippingMethod = function(shippingMethod) {
	return request.ajax("POST", common.API + "commerce/order/shipping-method", shippingMethod);
};

exports.applyPromoCode = function(promoCode) {
	return request.ajax("POST", common.API + "commerce/order/promo-code", promoCode);
};

exports.updateBillingShipping = function (addresses) {
	return request.ajax("POST", common.API + "commerce/address", addresses);
};
