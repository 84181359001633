{% if (entry.content.seriesLabel) { %}
	<div class="nudge-up">{{ entry.content.seriesLabel }}</div>
{% } else if (entry.productStatus === "F") { %}
	<div class="nudge-up mbs"><div class="label info">Coming soon</div></div>
{% } %}

{% if (entry.content.selectable === true) { %}
	<div class="hbr-checkbox left mrl mln hide-for-small">
		<input type="checkbox" value="{{ entry.id }}" id="{{ entry.id }}" js-target="bulk-action-checkbox" name="{{ entry.id }}"
		{% if (entry.content.list) { %} data-list="{{ entry.content.list.id }}"{% } %}>
		<label for="{{ entry.id }}"></label>
	</div>
{% } %}

{% if (entry.content.activity) { %}
	<div class="stream-item-activity-label {{ entry.content.activity }}">
		<span class="stream-item-activity-label-text">{{ entry.content.activity === "ordered" ? "order" : entry.content.activity }}</span>
	</div>
{% } %}


<stream-item class="stream-item overflow-hidden {{ context }}"
	data-id="{{ entry.content.id }}"
	data-title="{{ entry.title.replace(/"/g, "&quot;") }}"
	data-url="{{ entry.content.src }}"
	data-topic="{{ entry.category.term }}"
	data-authors="{% entry.author.forEach(function(author){ %}{{ author.name.replace(/"/g, "&quot;") }};{% }); %}"
	data-content-type="{{ entry.contentType }}"
	data-content-image = "{{ entry.content.image }}"
	data-summary = "{{ entry.summary ? entry.summary.replace(/"/g, "&quot;") : '' }}"
	data-list-price ="{{entry.listPrice}}"
>
	{% if (entry.content.activity === "ordered") { %}
	
		{% if (entry.title.indexOf('#S') !== -1) { %}
	<h3 class="hed"><a href="#" js-target="subscription-details">Subscribed to Harvard Business Review</a></h3>
	
	<div class="dek">
		Account Number: <strong>#{{ entry.title.substring(entry.title.indexOf('#S') + 2) }}</strong>
	</div>
	<ul class="stream-utility plain-inline-list">	
		<li class="utility pubdate">
			<time>
				{% if (entry.published !== undefined && entry.published !== null && entry.published !== "") { %}
					{{ moment(entry.published).format("MMMM DD, YYYY") }}
				{% } %}	
			</time>
		</li>
	</ul>
		{% } else { %}
			<div class="order-details">
				{% if (entry.content.hasDownloads) { %}
					<div><a href="{{ entry.content.src }}" class="button--gray mbm">Download Files</a></div>
				{% } %}
				<div class="print-receipt-container"><a href="{{ entry.content.src }}?printMe=1" class="text-gray"><i class="font-size-xsmall icon icon-print"></i> Print Receipt</a></div>
			</div>
			# {{ entry.content.id }} | Total: {{ entry.content.grandTotalIncTax }} | {% if (entry.orderStatus !== "CANCELLED") { %} Purchased {% } else { %} Cancelled {% } %}  {% if (entry.published !== undefined && entry.published !== null && entry.published !== "") { %}  {{ moment(entry.published).format("MMMM DD, YYYY") }} {% } %} | <a href="{{ entry.content.src }}">View {{ entry.content.itemCount }} item(s)</a>
			{% entry.lineItems.slice(0, 5).forEach(function(item) { %}
				<div class="stream-entry">
					<h3 class="hed">{{ item.title }}</h3>
					<span class="content-type">{{ item.contentType }}</span>
					<ul class="byline byline-list">{% if (item.authors) item.authors.forEach(function(author) { %}<li>{{ author }}</li>{% }); %}</ul>
					{% if (item.contentType === "CASE STUDY") { %}
						<div class="product-text font-size-small pbm mbn">Access to case studies expires six months after purchase date.</div>
					{% } %}					
				</div>
			{% }); %}
		{% } %}

	{% } else { %}
	{% if (entry.content.image) { %}
	<div class="stream-image">
		<figure>
			<a href="{{ entry.content.src }}"><img src="{{ entry.content.image }}" alt="" /></a>
		</figure>
	</div>
	{% } %}

	<h3 class="hed"><a href="{{ entry.content.src }}">{{ entry.title }}</a></h3>

	<!-- item info -->
	<div class="stream-item-info">
		{% if (typeof entry.category.term !== "undefined") { 
			var termlink = entry.category.term.toLowerCase().replace("&","and").replace(/ /g,"-");
		%}
		<a href="/topic/subject/{{ termlink }}" class="topic">{{ entry.category.term }}</a>
		{% } %}
		<span class="content-type">{{ entry.contentType }}</span>
		<!-- author could be none, one or many -->
		{% if (typeof entry.author !== "undefined") { %}
		<ul class="byline byline-list">{% entry.author.forEach(function(author) { %}<li>{{ author.name }}</li>{% }); %}</ul>
		{% } %}
	</div>

		<!-- product price/add to cart  -->        
		 {% if (entry.listPrice && 0 < entry.listPrice && entry.contentType.toUpperCase() !== "MAGAZINE ARTICLE" && entry.contentType.toUpperCase() !== "DIGITAL ARTICLE") { %}
		<div class="stream-item-price">
            <div class="price">{{ entry.listPrice }}</div>
            <add-to-cart class="add-to-cart is-inline-block"
                data-product-title="{{entry.title}}"
                data-product-major-topic="Strategy" 
                data-product-author="{{entry.authors}}"
                data-product-price="{{entry.listPrice}}"
                data-product-availability-status="{% if (entry.productStatus === "F") { %}COMING_SOON{% } %}"
                data-publication-date="{{entry.published}}"
                data-product-image="{{entry.image}}"
                data-product-id="{{entry.id}}" 
                data-product-url="{{entry.productLink}}" 
                data-product-content-type="{{entry.contentType}}"
                data-product-format=""
                {% if (referral !== undefined && referral !== "") { %}
					data-referral="{{referral}}"
				{% } else { %}
					data-referral="02749"
				{% } %}
			>
				<input type="hidden" js-target="item-quantity" value="1">
				<a href="{{ entry.productLink }}" class="button button--tight">View Details</a>
            </add-to-cart>
        </div>
		{% } %}
	
	<!-- Hiding description in case of mobile devices  -->
	<div class="dek{% if (entry.endpoint === "search/more-results") { %} hide-for-small{% } %}">
	{% if (entry.content.activity === "shared") { %}
		Shared with: {% if (entry.content.provider === "email") { %}
		{% entry.content.sharedWith.forEach(function(shared) { %}<strong>{{ shared }}</strong> {% }); %}
		{% } else { %}<strong>{{ entry.content.provider }}</strong>
		{% } %}
	{% } else { %}
		{{ entry.summary }}
	{% } %}
	</div>
	
	<ul class="stream-utility plain-inline-list">
		{% if (!entry.content.readOnly) { %}
		{% if (entry.content.selectable === true) { %}
			<li class="utility"><a href="#" js-target="organize-flyout"><i class="icon icon-organize"></i> Move</a></li>
		{% } else { %}
			<li class="utility"><a href="#" js-target="save-flyout"><i class="icon icon-file-text-o"></i> Save</a></li>
		{% } %}
		<li class="utility"><a href="#" js-target="share-flyout"><i class="icon icon-share-square-o"></i> Share</a></li>
		{% if (entry.productLink !== undefined && entry.productLink !== "" && (entry.contentType.toUpperCase() === "MAGAZINE ARTICLE" || entry.contentType.toUpperCase() === "DIGITAL ARTICLE")) { %}
			<li class="utility"><a href="{{ entry.productLink }}"><i class="icon icon-buy-copies"></i> Buy Copies</a></li>
		{% } %}
		{% if (entry.content.selectable === true) { %}
			<li class="utility"><a href="#" js-target="discard-flyout"><i class="icon icon-times"></i> Discard</a></li>
		{% } %}
		{% if (entry.published !== undefined && entry.published !== null && entry.published !== "") { %}
		<li class="utility pubdate">{{ entry.published }}</li>
		{% } %}
		{% } %}
	</ul>
	{% } %}
	{% if (entry.content.downloads) { %}
	<ul class="piped-inline-list mvm">
		{% entry.content.downloads.forEach(function(download) { %}
			<li><a href="{{download.link}}" target="_blank" rel="noopener noreferrer"><i class="icon icon-{{download.format}} baseline-shift-down icon-lg"></i><span class="text-gray font-size-small">
			DOWNLOAD {% if (download.format === "ZIP") { %}
				ZIP FILE TO DESKTOP
			{% } else { %}
				{{download.format}}
			{% } %}
			</span></a></li>
		{% }); %}
	</ul>
	{% } %}
	
</stream-item>
