<h6 class="flyout-header" id="forgot-password-flyout">Enter Your Email Address:</h6>

<form js-target="save-new-list-form">
	<div class="notification-box">
		<div id="forgot-password-flyout-notification-box" class="notification-box-content" js-target="notification-box">
			<div class="row collapse">
				<div class="small-2 column text-center">
					<span class="icon-stack mts">
						<i class="icon icon-circle-o icon-stack-2x warning-color"></i>
						<i class="icon icon-exclamation icon-stack-1x warning-color"></i>
					</span>
				</div>
				<div class="small-9 column mtm">
					<div class="message" js-target="notification-box-message">
						If your email is registered with us, you will receive an email with a link to reset your password.
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="field-container">
		<label for="email-address">Email</label>
		<input id="email-address-forgot-password-flyout" name="email-address" type="email" js-target="forgot-password-email">
	</div>
	<div>
		<div id="captchaTargetFlyoutForgotPass"></div>
		<label style="visibility: hidden;" class="list-error error" id="captchaFlyoutError" error-type="${type}">reCAPTCHA is required.</label>
	</div>
	<button class="expand button--black" js-target="send-message">Send</button>
</form>
