
import $ from "jquery";

var hbrSmoothScroll = function() {
	if (location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") && location.hostname === this.hostname) {
		//Try-catch block to handle exception from jquery if there are any invalid characters in hash.
		try {
			var target = $(this.hash);
			target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
			if (target.length) {
				$("html,body").animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
		catch (e) {
			//This will be reached if hash contains invalid characters.
		}
	}
};

$("a[href*='#']:not([href='#'])").on("click", hbrSmoothScroll);
