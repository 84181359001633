import $ from "jquery";

(function() {
	/**
	 * Set page type and page name to digital data.
	 *
	 * @param {object} ev - Event object.
	 * @param {string} pageType.
	 * @param {string} pageName.
	 */
	var setPageInfo = (ev, pageType, pageName) => {
		if (window.digitalData && window.digitalData.page && window.digitalData.page.pageInfo) {
			window.digitalData.page.pageInfo.pageName = pageName;
			window.digitalData.page.pageInfo.pageType = pageType;
		}
	}
	$("body").on("set-page-info", setPageInfo);
})();