
import $ from "jquery";
import contentTemplate from "./template";
import interactionService from "../../../services/hbr/interaction";

var $component = $(exports);

var discardContent = null;

// reference to the element that this flyout is attached to.
var $flyoutElement = null;

/**
 * render
 * @returns {*}
 */
exports.render = function (flyoutData) {
	var discardRenderDeferred = $.Deferred();
	discardRenderDeferred.resolve(contentTemplate({
		discardWhat: $.isArray(flyoutData) ? "all selected items" : "this"
	}));
	return discardRenderDeferred.promise();
};

/**
 * getData
 * Gets necessary data from the DOM for rendering share template and
 * hitting share/history end points
 * @param {Object} $element
 * @returns {Object}
 */
exports.getData = function ($element) {
	// discard takes a saveID which is on the checkbox, not the stream item
	var $streamItemCheckbox = $element.parents(".stream-entry").find(".hbr-checkbox > input");
	discardContent = {
		id: $streamItemCheckbox.attr("id")
	};

	if ($streamItemCheckbox.data("list")) {
		discardContent.list = $streamItemCheckbox.data("list");
	}

	// store a reference to the element
	$flyoutElement = $element;
};

var removeFromList = function (e) {
	e.preventDefault();
	var request = {};
	if (discardContent.list) {
		// remove the item from the list
		request.name = discardContent.list;
		request.data = {
			id: discardContent.id
		};

		interactionService.request("listItem", "delete", request).then(function() {
			$component.trigger("destroy", {message: "Removed from My Library"});

			var $streamItem = $flyoutElement.closest(".stream-entry");
			// remove the row
			interactionService.hideStreamLabel($streamItem);
			$streamItem.remove();
		});
	} else {
		request.id = discardContent.id;
		interactionService.request("saved", "delete", request).then(function() {
			$component.trigger("destroy", {message: "Removed from My Library"});

			var $streamItem = $flyoutElement.closest(".stream-entry");
			// remove the row
			interactionService.hideStreamLabel($streamItem);
			$streamItem.remove();
		});
	}
};

var cancelAndClose = function (e) {
	e.preventDefault();
	$component.trigger("destroy");
};

exports.bindInteractions = function () {
	$("[js-target='yes']").on("click", removeFromList);
	$("[js-target='cancel']").on("click", cancelAndClose);
};
