import React, {Component} from 'react';
import ReactHtmlParser from 'react-html-parser';
import common from "../../common";
import _indexBy from "lodash-amd/modern/collections/indexBy";
import auth from "services/auth";
import CaptchaService, {CaptchaActions, CaptchaKeys} from "../../services/captcha-service";
const captchaTarget = "captchaTarget";

class ForgotPasswordComponent extends Component {
	constructor() {
		super();
		// State holds form data
		this.state = {
			form: { email: ""},
			isForgotPasswordSuccess: false,
			errors: [],
			captchaToken: null,
			captchaWidget: null,
		}
	}

	componentDidMount() {
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					grecaptcha.enterprise.ready(() => {
						const widgetID = grecaptcha.enterprise.render(captchaTarget, {
							sitekey: CaptchaKeys.checkbox,
							callback: (token) => {
								this.setState({captchaToken: token});
							},
							"expired-callback": () => {
								this.setState({captchaToken: null});
							},
						});
						this.setState({captchaWidget: widgetID});
					});
				}
			});
	}

	handleChange(event) {
		// A field value needs to be updated in state
		this.setState({
			form: { ...this.state.form, [event.target.name]: event.target.value } // fieldName = fieldValue
		});
	}

	validateForm() {
		var errors = [];
		var errorMessage = "This field is required.";
		if (this.state.form.email.trim() === "") {
			errors.push({"field": "email", "errorMessage": errorMessage});
		} else {
			// TODO: Aggregate this into a js file?
			// Make sure the format is valid.
			if (!this.state.form.email.trim().match(common.EMAIL_REGEX)) {
				errors.push({
					"field": "email",
					"errorMessage": "This email address is not valid."
				});
			}
		}

		if (this.state.captchaWidget !== null && !this.state.captchaToken) {
			errors.push({field: "captcha", errorMessage: "reCAPTCHA is required."});
		}

		this.setState({
			errors: errors.length ? _indexBy(errors, "field") : []
		});
		return errors.length;
	}

	getError(fieldName) {
		var errorMessage = "";
		if (this.state.errors && this.state.errors[fieldName]) {
			errorMessage = this.state.errors[fieldName].errorMessage;
		}

		return errorMessage ?
				<span class="error-item">
					<span class="icon-alert"></span>
					<span class="error-message">
						{errorMessage}
					</span>
				</span> : "";
	}

	getBannerError(fieldName) {
		var errorMessage = "";
		if (this.state.errors && this.state.errors[fieldName]) {
			errorMessage = this.state.errors[fieldName].errorMessage;
		}

		return errorMessage ? <span class="error-message">{errorMessage}</span> : "";
	}

	handleSubmit(event) {
		event.preventDefault();
		event.stopPropagation();
		const errors = this.validateForm();
		if (errors > 0) {
			return;
		}

		// Form has been submitted
		const verifyForgotPassword = (email, captchaToken = null) => {
			auth.verifyForgotPassword(email, captchaToken).then(function() {
				this.setState({isForgotPasswordSuccess: true});
			}.bind(this), function(data) {
				this.handleErrors(data);
			}.bind(this));
		};

		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					grecaptcha.enterprise.ready(() => {
						grecaptcha.enterprise.execute(CaptchaKeys.score, {action: CaptchaActions.FORGOT_PASS_SUB});
					});
					verifyForgotPassword(
						this.state.form.email,
						this.state.captchaToken,
					);
					grecaptcha.enterprise.reset(this.state.captchaWidget);
					this.setState({captchaToken: null});
				} else {
					verifyForgotPassword(this.state.form.email);
				}
			});
	}

	handleErrors(data) {
		var errorMessage = ReactHtmlParser(common.ERROR_MESSAGE);
		if (data.status == 423) {
			errorMessage = ReactHtmlParser("You cannot unlock your account by resetting the password. Please wait 24 hours to unlock and try again. For immediate assistance, please contact <a href='/subscriber-help#contact-customer-service'>Customer Service</a>.");
		}
		var errorMessage = (
			<div className="error-item-banner">
				<span className="icon-alert icon-alert-banner"></span>
				<div class="error-message-banner">
					{errorMessage}
				</div>
			</div>
		);
		var error = {"common": {"field": "common", "errorMessage": errorMessage}};
		this.setState({
			errors: error
		});
	}

	isNoFieldError(fieldName) {
		return !((fieldName in this.state.errors) || ("common" in this.state.errors));
	}

	render() {
		let content = "";
		if(this.state.isForgotPasswordSuccess) {
			content = (
				<div>
					<div class="confirmation-message">If your email is registered with us, you will receive an email with a link to reset your password. Once you've reset your password, click the link below to sign in.</div>
					<div class="input-group clear">
						<input class="f-r  m-w-100" type="submit" value="On to sign in" onClick={this.props.navigateToSignIn.bind(this)}/>
					</div>
				</div>
			)
		} else {
			content = (
				<div>
					<p class="body-description">Enter the email you use for your account and we'll send you a link to reset your password.</p>
					{this.getBannerError("common")}
					<form class="form" onSubmit={this.handleSubmit.bind(this)}>
						<div class="input-group">
							<span class={this.isNoFieldError("email") ? "input-group-label" : "input-group-label label-error"}>Email</span>
							<input type="text" name="email"
										 class={this.isNoFieldError("email") ? "text-input" : "text-input input-error"}
										 value={this.state.form.email} onChange={this.handleChange.bind(this)}/>
							{this.getError("email")}
						</div>
						<div className="input-group">
							<div id={captchaTarget}></div>
							{this.getError("captcha")}
						</div>
						<div class="input-group clear">
							<a href="#" className="btn-back" onClick={this.props.navigateToSignIn.bind(this)}><i className="icon icon-caret-left"></i> Back to sign in</a>
							<input class="f-r" type="submit" value="Send link"/>
						</div>

					</form>
				</div>
			)
		}


		return (
			<div class="piano-card">
				<div class="header inverse">
					<span class="header-close-btn" onClick={() => this.props.onClose() }>
						<i className="icon icon-times"></i>
					</span>
				</div>
				<div class="body">
					<div className="wrapper">
						<h2 class="body-header">Reset password</h2>
						<hr className="body-divider"/>
						{ content }
					</div>
				</div>
			</div>
		);
	}
}

export default ForgotPasswordComponent;
