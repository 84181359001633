import utilities from "../utilities";
import pianoService from "../../services/piano/piano-service"

const PIANO_CRUMB_COOKIE_NAME = "pianoOrderCrumb";

function writeCrumb() {
	utilities.setCookie(PIANO_CRUMB_COOKIE_NAME, "on", 7);
}

function deleteCrumb() {
	utilities.deleteCookie(PIANO_CRUMB_COOKIE_NAME);
}

function hasCrumb() {
	const crumbPresence = utilities.readCookie(PIANO_CRUMB_COOKIE_NAME);

	if (crumbPresence == null) {
		return false;
	} else {
		return true;
	}
}

function checkCrumb() {
	const crumbPresence = utilities.readCookie(PIANO_CRUMB_COOKIE_NAME);

	if (crumbPresence == null) {
		console.log("No Piano Breadcrumb from ordering found.");
		return;
	} else {
		// Call the registration-complete process and remove the crumb.
		pianoService.completeSubscriptionActivation().then(function(_) {
			utilities.deleteCookie(PIANO_CRUMB_COOKIE_NAME);
			console.log("Piano Breadcrumb from ordering found & processed.");
		})
			.catch(function(error) {
				console.error("Error from Piano Breadcrumb processing.");
			});
	}
}

exports.writeCrumb = writeCrumb;
exports.deleteCrumb = deleteCrumb;
exports.hasCrumb = hasCrumb;
exports.checkCrumb = checkCrumb;
