import $ from "jquery";

const actions = {
	FORGOT_PASS: "forgot_pass",
	FORGOT_PASS_MODAL: "forgot_pass_modal",
	FORGOT_PASS_PAYWALL: "forgot_pass_paywall",
	FORGOT_PASS_SUB: "forgot_pass_sub",
	FORGOT_PASS_SYNC_SUB: "forgot_pass_sync_sub",
	REGISTER: "register",
	REGISTER_MODAL: "register_modal",
	REGISTER_PAYWALL: "register_paywall",
	REGISTER_SUB: "register_sub",
	SIGN_IN: "sign_in",
	SIGN_IN_MODAL: "sign_in_modal",
	SIGN_IN_PAYWALL: "sign_in_paywall",
	SIGN_IN_SUB: "sign_in_sub",
	SIGN_IN_SYNC_SUB: "sign_in_sync_sub"
};
const keys = {
	checkbox: "6LdypdcZAAAAAP5-ImlDk0BQSQ3oLpwjcILQ-1bw",
	score: window.captchaScoreKey,
};
let isCaptchaEnabled = null;

class CaptchaService {
	static isCaptchaEnabled() {
		return new Promise((resolve) => {
			if (isCaptchaEnabled === null) {
				$.ajax({url: "/api/recaptcha/enabled"})
					.then(
						(isEnabled) => {
							isCaptchaEnabled = isEnabled;
						},
						() => {
							isCaptchaEnabled = true;
						}
					)
					.always(() => {
						resolve(isCaptchaEnabled);
					});
			} else {
				resolve(isCaptchaEnabled);
			}
		});
	}
}

export {
	CaptchaService as default,
	keys as CaptchaKeys,
	actions as CaptchaActions,
};
