
/**
* function that will abstract the correct url from the url
*
* @param {string} url
*
* @return {string} url
*/
exports.processUrl = function(url) {
	return url;
};
