/**
 * URL Query String Parameters Manipulation: get, set/add, remove
 *
 * @module: support/urlparams
 * @author: Stepan Sokolov
 */

/**
 * @exports urlParamGet
 *
 * Search querystring for the specified parameter
 *      (this is a lower level function;
 *       see getUrlParam, getUrlParamValue)
 *
 * @param {string} href - the URL to be searched
 * @param {string} p    - the URL parameter name
 * @returns {object}    - the result of regex search
 *                        with \1 and \2 corresponding to the parameter
 *                        name and value
 */
exports.urlParamGet = function (href, p) {
	this.urlParamRes =
			(this.urlParamRex = new RegExp("[?&](" + p + "=)([^&#]*)")).exec(href);
	return this.urlParamRes;
};

/**
 * @exports getUrlParam
 *
 * Search querystring for the specified parameter
 *
 * @param {string} href - the URL to be searched
 * @param {string} p    - the URL parameter name
 * @returns {string}    - the result is a "name=value" string
 */
exports.getUrlParam = function (href, p) {
	return (p = this.urlParamGet(href, p)) && (p[1] + p[2]);
};

/**
 * @exports getUrlParamValue
 *
 * Search querystring for the specified parameter, return its value
 *
 * @param {string} href - the URL to be searched
 * @param {string} p    - the URL parameter name
 * @returns {string}    - the parameter value, if found, null otherwise
 */
exports.getUrlParamValue = function (href, p) {
	return (p = this.urlParamGet(href, p)) && p[2];
};

/**
 * @exports setUrlParam
 *
 * Search querystring for the specified parameter, set its value
 *		if no such parameter found, add it and assign it the provided value
 *
 * @param {string} href - the URL to be searched
 * @param {string} p    - the URL parameter name
 * @param {string} v    - the value to assign to the parameter
 * @returns {string}    - the result is the newly constructed URL
 */
exports.setUrlParamValue = function (href, p, v) {
	if (this.urlParamGet(href, p)) {
		var i = this.urlParamRes.index;
		return href.substring(0, i + this.urlParamRes[1].length + 1) + v +
				href.substring(i + this.urlParamRes[0].length);
	} else {
		return href + (href.indexOf("?") < 0 ? "?" : "&") + p + "=" + v;
	}
};

/**
 * @exports removeUrlParam
 *
 * Remove the specified parameter from the querystring
 *
 * @param {string} href - the URL to be searched
 * @param {string} p    - the URL parameter name
 * @returns {string}    - the result is the newly constructed URL
 */
exports.removeUrlParam = function (href, p) {
	if (!this.urlParamGet(href, p)) { return href; }
	var i = this.urlParamRes.index,
		l = this.urlParamRes[0].length + i;
	if (href[i] === "?" && href.length > l) { i++; }
	return href.substring(0, i) + href.substring(l);
};
