
import $ from "jquery";

import contentTemplate from "./template";

var options = {
	requiresSignIn: false
};

/**
 * render
 * @returns {*}
 */
exports.render = function (data) {
	var saveRenderDeferred = $.Deferred();
	saveRenderDeferred.resolve(contentTemplate(data));
	return saveRenderDeferred.promise();
};

/**
 * getData
 * Gets necessary data from the DOM for title and body content
 * @returns {Object}
 */
exports.getData = function ($element) {
	var selector = $element.attr("data-body-selector");
	var content = $(selector).length > 0 ? $(selector).html() : "";
	return {
		title: $element.attr("data-title"),
		body: content
	};
};

/**
 * getOverrideOptions
 * Sign in not required
 * @returns {Object}
 */
exports.getOverrideOptions = function () {
	return options;
};
