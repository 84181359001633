// Components
import $ from "jquery";
import _throttle from "lodash-amd/modern/functions/throttle";
import mixinComponent from "../../mixins/component";
import user from "../../models/user";
import cartContents from "./templates/cart-contents";
import _each from "lodash-amd/modern/collections/forEach";
import SignInModal from "react/AuthModal/Modal";
import SignIn from "../sign-in/sign-in";
import interactionService from "services/hbr/interaction";
import common from "../../common";
import "outside-events";
import headerTitle from "../../services/header-title-utils";
import storageService from "../../services/localstorage-util";
import headlineComponent from "../../components/article-headline/article-headline";

export default function Header($el) {
	this.initializeAsComponent($el);

	SignIn.signInPromise.then(function(u) {
		this.$(".top-header--user-dropdown-container").removeClass("do-not-show");
		this.$("[js-target='global-header-menu-caret']").removeClass("do-not-show");
		this.$(".top-header--user-name").removeClass("do-not-show");
		this.$("[js-target='user-header-name']").text((user.getFirstName()));
		this.$("[js-target='sign-in-register']").addClass("do-not-show");
		this.$("[js-target='user-menu-name']").text((user.getFullName()));
		this.$("[js-target='logout']").removeClass("do-not-show");
		if (user.isSubscriber()) {
			let roleCaption = user.getSubscriberTypeCaption();
			this.$("[js-target='user-menu-subscription-access']").removeClass("do-not-show");
			this.$("[js-target='user-menu-subscription-access-level']").text(roleCaption ? roleCaption : "");
			this.$(".non-subscriber-avatar").addClass("do-not-show");
		} else {
			this.$(".subscriber-avatar").addClass("do-not-show");
		}
		if (user.isHmmEligible()) {
			$(".hbr-learning").addClass("enabled");
		}
		interactionService.request("cart", "get").done(function(data, status, response) {
			if (typeof data.cart !== "undefined") {
				storageService.setCache("cart", data.cart);
				$("body").trigger("update:cart", data.cart);
			}
		});
		if (typeof user.getFollowingUnread() !== "undefined" && user.getFollowingUnread() > 0) {
			this.$("[js-target='global-topic-feeds-anchor']").attr("data-newfollowing-badge", user.getFollowingUnread());
			this.$("[js-target='newfollowing-user-badge']").attr("data-newfollowing-user-badge", user.getFollowingUnread());
		}
		// set user information to Zendesk widget
		if (typeof zE !== "undefined" && typeof zE.identify === "function") {
			zE.identify({
				name: user.getFullName(),
				email: user.getEmail()
			});
		}
	}.bind(this));

	var threshold = 20;
	var lastPosition = window.pageYOffset;

	window.onscroll = _throttle(function() {
		var isNavOpen = $("global-nav.global-nav").hasClass("is-open");
		var yOffset = window.pageYOffset;
		var isVisible = (window.innerWidth <= this.smallScreenWidth) && isNavOpen ? true : yOffset < lastPosition;

		lastPosition = yOffset;

		if (lastPosition < threshold && !(window.innerWidth <= this.smallScreenWidth && isNavOpen)) {
			isVisible = false;
		}

		$("body").toggleClass("reversed", isVisible);
	}, 125);

	var recLimit = 6, topicsLimit = 3,
		searchInput = $("[data-type='search-input']"),
		autoSuggest = $("[data-purpose='search-auto-suggest']"),
		autoSuggestRes = autoSuggest.find("ul"),
		autoSuggest1stRec = autoSuggestRes.children().filter(":first"),
		autoSuggestLastRec = autoSuggestRes.children().filter(":nth-child(" + recLimit + ")"),
		autoSuggest1stTopic = autoSuggestRes.children().filter(":nth-child(" + (recLimit + 2) + ")"),
		autoSuggestTopicHeader = autoSuggestRes.children().filter(":nth-child(" + (recLimit + 1) + ")"),
		shadowContainer = $("[data-container='dimensions']"),
		shadow1stRec = shadowContainer.children().filter(":first"),
		shadowLastRec = shadowContainer.children().filter(":nth-child(" + recLimit + ")"),
		shadowTopicHeader = shadowContainer.children().filter(":nth-child(" + (recLimit + 1) + ")"),
		shadow1stTopic = shadowContainer.children().filter(":nth-child(" + (recLimit + 2) + ")"),
		autoSuggestEndPoint = common.API + "search/auto-suggest?term=",
		searchOverlayFooter = $(".top-header--overlay-footer"),
		cancelSearch = false,
		autoSuggestDisplayed = false,
		animationsStack = [];

	searchInput.on("click", function (ev) {
		cancelSearch = false;
		if (headerTitle.isArticle === true) {
			headerTitle.displayingSearch = true;
			headerTitle.showOrHide();
		}
	});
	searchInput.on("clickoutside", function (ev) {
		cancelSearch = true;
		if ($(ev.target).closest("[data-purpose='search-auto-suggest']").length === 0) {
			autoSuggest.addClass("hide");
			searchOverlayFooter.removeClass("hide");
			searchOverlayFooter.removeClass("fadeout-megamenu-columns--short");
			$(".top-header--search-suggest").removeClass("fade-in-search-suggestions");
			autoSuggestDisplayed = false;
			//clear animations stack
			for (var evtIndex = 0; evtIndex < animationsStack.length; evtIndex++) {
				clearTimeout(animationsStack[evtIndex]);
			}
			animationsStack = [];

			if (headerTitle.isArticle === true) {
				headerTitle.displayingSearch = false;
				headerTitle.showOrHide();
			}
		}
	});
	$(".top-header--overlay").on("clickoutside", this.hideOverlayOnOutsideClick);

	function adjustLink (link) {
		return link.charAt(0) === "?" ? ("/search" + link) : link;
	}

	function hiliString (match) {
		return "<span class=\"font-heavy\">" + match + "</span>";
	}

	function hiliContentType (ctype) {
		return ctype ? "<span class=\"font-size-small small-caps info-color pls spacing-wide\">" +
			ctype.toLowerCase() + "</span>" : "";
	}

	function displayResList (search, list, limit, elt, selt) {
		var i = 0, lim = list ? (list.length < limit ? list.length : limit) : 0;

		for (; i < limit; i++, elt = elt.next(), selt = selt.next()) {
			if (i < lim) {
				var html = "<a href=\"" + adjustLink(list[i].link) + "\">" +
					list[i].title.replace(new RegExp(search, "gi"), hiliString) +
					hiliContentType(list[i].contentType) + "</a>";
				elt.html(html);
				selt.html(html);
				selt.removeClass("hide");
				elt.removeClass("hide");
			}
			else {
				elt.html("");
				selt.html("");
				selt.addClass("hide");
				elt.addClass("hide");
			}
		}
	}

	function displayTopicHeader (topics) {
		if (topics && topics.length > 0) {
			autoSuggestTopicHeader.removeClass("hide");
			shadowTopicHeader.removeClass("hide");
			autoSuggestLastRec.addClass("has-border-bottom");
			shadowLastRec.addClass("has-border-bottom");
		}
		else {
			autoSuggestTopicHeader.addClass("hide");
			shadowTopicHeader.addClass("hide");
			autoSuggestLastRec.removeClass("has-border-bottom");
			shadowLastRec.removeClass("has-border-bottom");
		}

		return topics;
	}

	function syncAutoSuggestWidth () {
		shadowContainer.width(autoSuggestRes.width());
	}

	function showAutoSuggestPanel () {
		if (!cancelSearch) {
			syncAutoSuggestWidth();

			if (window.onresize !== syncAutoSuggestWidth) {
				window.onresize = syncAutoSuggestWidth;
			}
			//animate the transition
			if (!autoSuggestDisplayed) {
				searchOverlayFooter.addClass("fadeout-megamenu-columns--short");
				$(".top-header--search-suggest").addClass("fade-in-search-suggestions");
				setTimeout(function() {
					autoSuggest.removeClass("hide");
					searchOverlayFooter.addClass("hide");
				}, 400);
			}
			autoSuggestDisplayed = true;
		}
	}

	function hideAutoSuggestPanel () {
		autoSuggest.addClass("hide").removeAttr("style");
		searchOverlayFooter.removeClass("hide");
		searchOverlayFooter.removeClass("fadeout-megamenu-columns--short");
		$(".top-header--search-suggest").removeClass("fade-in-search-suggestions");
		//clear animations stack
		for (var evtIndex = 0; evtIndex < animationsStack.length; evtIndex++) {
			clearTimeout(animationsStack[evtIndex]);
		}
		animationsStack = [];
		autoSuggestDisplayed = false;
	}

	Header.prototype.hideAutoSuggestPanel = hideAutoSuggestPanel;

	function showOrHideClearButton() {
		var desktopText = $(".search-box-desktop").val() || "";
		var mobileText = $(".search-box-mobile").val() || "";
		var hideButton = desktopText.length < 1 && mobileText.length < 1;
		if (hideButton) {
			$(".clear-search-box").addClass("do-not-show");
		} else {
			$(".clear-search-box").removeClass("do-not-show");
		}
	}

	$(".top-header--overlay-search input").on("keyup", showOrHideClearButton);

	var throttledSearch = _throttle(function () {
		var search = searchInput.val();
		if (search.length > 2) {
			$.getJSON(autoSuggestEndPoint + encodeURI((search = search.trim().split(/\s+/).join(" "))))
				.then(function (data) {
					if (data && ((data.records && data.records.length > 0) ||
						(data.subjects && data.subjects.length > 0))) {
						displayResList(search, data.records, recLimit, autoSuggest1stRec, shadow1stRec);
						displayResList(search, displayTopicHeader(data.subjects), topicsLimit,
							autoSuggest1stTopic, shadow1stTopic);
						setTimeout(function () {
							showAutoSuggestPanel();
						}, 200);
					}
					else {
						hideAutoSuggestPanel();
					}
				});
		}
		else {
			hideAutoSuggestPanel();
		}
	}, 350, {leading: true, trailing: false});

	searchInput.on("keyup", function (ev) {
		if (ev.which !== 13) {
			throttledSearch();
		}
	});

	_each(["cart", "search", "user"], function(menu) {
		this.$("[js-target='global-header-menu'][data-menu='" + menu + "']").on("clickoutside", function(ev) {
			if (!$(ev.target).parents().addBack().is("[js-target='global-header-menu-toggle'][data-menu='" + menu + "']")) {
				this.setMenuActive(menu, false);
			}
		}.bind(this));
	}.bind(this));

	headlineComponent.activateAll();
}

Header.prototype = {
	shoppingCartDisplayed: !$(".top-header--shopping-cart").hasClass("do-not-show"),
	smallScreenWidth: 767,
	cartOverlayDisplayed: false,
	userOverlayDisplayed: false,
	desktopAnimationsStack: [],
	NOT_SHOW_CLASS: "do-not-show",
	renderSignInModal: function(ev) {
		ev.preventDefault();
		SignInModal.render();
	},

	logout: function(ev) {
		ev.preventDefault();
		user.logout();
	},

	renderRegisterModal: function(ev) {
		ev.preventDefault();
		SignInModal.render("register");
	},

	toggleMenu: function(ev) {
		ev.preventDefault();
		var menu = $(ev.currentTarget).data("menu");
		var active = !this.$("[js-target='global-header-menu'][data-menu='" + menu + "']").hasClass("active");
		this.setMenuActive(menu, active);
		// trigger event to update digital data if mini cart icon is clicked and cart menu is open
		if (menu === "cart" && active) {
			interactionService.request("cart", "get").done(function(data, status, response) {
				if (typeof data.cart !== "undefined") {
					storageService.setCache("cart", data.cart);
					$("body").trigger("update:cart", data.cart);
					$("body").trigger("mini-cart-interaction", storageService.getCache("cart"));
				}
			});
		}
	},

	updateCart: function(ev, cartObj) {
		var cartItems = [];
		if (cartObj.line_items && cartObj.line_items.physical_items && cartObj.line_items.digital_items) {
			cartItems = cartObj.line_items.physical_items.concat(cartObj.line_items.digital_items);
		}
		var subTotal = parseFloat(cartObj.cart_amount).toFixed(2);
		subTotal = subTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		this.$("[js-target='global-header-cart-contents']").html(cartContents({formatter: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format, items: cartItems, subTotal: subTotal}));
		var icon = this.$(".top-header--shopping-cart[data-menu='cart']");
		var numItems = cartItems.length;
		icon.toggleClass("do-not-show", numItems === 0);
		if (numItems > 0) {
			icon.attr("data-cart-badge", numItems);
		}
		this.shoppingCartDisplayed = !$(".top-header--shopping-cart").hasClass("do-not-show");
	},

	setMenuActive: function(menu, active) {
		var mnu = this.$("[js-target='global-header-menu'][data-menu='" + menu + "']");
		if (menu === "user") {
			var btn = this.$(".caret-down-button[js-target='global-header-menu-toggle'][data-menu='user']");
			mnu.find("[js-target='global-header-menu-caret']").css({left: btn.offset().left - mnu.offset().left - 2});
			if (active) {
				btn.addClass("active");
				mnu.addClass("active");
				this.openUserFlyoutAnimations();
				this.userOverlayDisplayed = true;
			} else {
				if (this.userOverlayDisplayed) {
					this.closeUserFlyoutAnimations();
					this.userOverlayDisplayed = false;
				}
			}
		}
		if (menu === "cart") {
			var btn = this.$(".top-header--shopping-cart[js-target='global-header-menu-toggle'][data-menu='cart']");
			mnu.find("[js-target='global-header-menu-caret']").css({left: btn.offset().left - mnu.offset().left + 1});
			if (active) {
				btn.addClass("active");
				mnu.addClass("active");
				this.openCartFlyoutAnimations();
				this.cartOverlayDisplayed = true;
			}
			else {
				if (this.cartOverlayDisplayed) {
					this.closeCartFlyoutAnimations();
					this.cartOverlayDisplayed = false;
				}
			}
			mnu.find("[js-target='global-header-menu-caret']").css({left: btn.offset().left - mnu.offset().left + 1});
		}
		if (active) {
			mnu.one("transitionend", function() {
				mnu.find("input").trigger("focus");
			});
		}
	},

	showSearchOverlay: function(ev) {
		ev.preventDefault();
		ev.stopPropagation();
		var searchOverlayDisplayed = !$(".top-header--overlay").hasClass(this.NOT_SHOW_CLASS);
		$(".top-header--overlay-footer").removeClass("hide");
		this.clearSearchBox(ev);
		if (searchOverlayDisplayed) {
			this.closeMegaMenuAnimations();
		} else {
			$(".top-header--overlay").removeClass(this.NOT_SHOW_CLASS);
			this.openMegaMenuAnimations();
			// set focus on the text input boxes
			$(".search-box-desktop").trigger("focus");
		}
	},

	hideOverlayOnOutsideClick: function(ev) {
		if (!$(".top-header--overlay").hasClass("do-not-show")) {
			Header.prototype.showSearchOverlay(ev);
		}
	},

	validateUserSignedRedirect: function(ev) {
		if(user.isGuest()) {
			ev.preventDefault();
			ev.stopPropagation();
			this.renderSignInModal(ev);
		}
	},

	clearSearchBox: function(ev) {
		$(".top-header--overlay-search input").val("");
		$(".clear-search-box").addClass("do-not-show");
		this.hideAutoSuggestPanel();
	},

	// animations
	openMegaMenuAnimations: function(ev) {
		$(".top-header--overlay").removeClass("close-megamenu-container");
		$(".header-menu--overlay").removeClass("open-overlay");
		$(".close-megamenu--columns").removeClass("close-megamenu--columns");
		//clear animations stack
		for (var evtIndex = 0; evtIndex < this.desktopAnimationsStack.length; evtIndex++) {
			clearTimeout(this.desktopAnimationsStack[evtIndex]);
		}
		$(".top-header--overlay").addClass("open-megamenu-container");
		this.desktopAnimationsStack.push(setTimeout(function() {
			$(".nav--explore").addClass("open-megamenu--columns");
			Header.prototype.desktopAnimationsStack.push(setTimeout(function() {
				$(".nav--popular").addClass("open-megamenu--columns");
				Header.prototype.desktopAnimationsStack.push(setTimeout(function() {
					$(".nav--subscribers").addClass("open-megamenu--columns");
					Header.prototype.desktopAnimationsStack.push(setTimeout(function() {
						$(".nav--account").addClass("open-megamenu--columns");
						Header.prototype.desktopAnimationsStack.push(setTimeout(function() {
							$(".nav--social").addClass("open-megamenu--columns");
						}, 50));
					}, 50));
				}, 50));
			}, 50));
		}, 600));
		this.desktopAnimationsStack.push(setTimeout(function() {
			$(".search-container").addClass("open-megamenu--search-bar");
		}, 400));
	},

	closeMegaMenuAnimations: function(ev) {
		$(".top-header--overlay").removeClass("open-megamenu-container");
		$(".search-container").removeClass("open-megamenu--search-bar");
		$(".open-megamenu--columns").removeClass("open-megamenu--columns");
		//clear animations stack
		for (var evtIndex = 0; evtIndex < this.desktopAnimationsStack.length; evtIndex++) {
			clearTimeout(this.desktopAnimationsStack[evtIndex]);
		}
		//close animations
		$(".top-header--overlay-footer .flex-col, .top-header--overlay-footer .small-flex-col").addClass("close-megamenu--columns");
		this.desktopAnimationsStack.push(setTimeout(function() {
			$(".top-header--overlay").addClass("close-megamenu-container");
		}, 425));
		this.desktopAnimationsStack.push(setTimeout(function() {
		}, 475));
		this.desktopAnimationsStack.push(setTimeout(function() {
		}, 500));
		this.desktopAnimationsStack.push(setTimeout(function() {
			$(".top-header--overlay").addClass(Header.prototype.NOT_SHOW_CLASS);
		}, 700));
	},

	openUserFlyoutAnimations: function(ev) {
		$(".header-menu--user-dropdown").removeClass("fadeout-flyouts");
		$(".header-menu--user-dropdown").addClass("fade-in-flyout");
		this.desktopAnimationsStack.push(setTimeout(function() {
			$(".header--user-flyout-links").addClass("fade-in-flyout--links");
		}, 400));
	},

	closeUserFlyoutAnimations: function(ev) {
		$(".header-menu--user-dropdown").removeClass("fade-in-flyout");
		$(".header--user-flyout-links").removeClass("fade-in-flyout--links");
		$(".header-menu--user-dropdown").addClass("fadeout-flyouts");
		this.desktopAnimationsStack.push(setTimeout(function() {
			$(".top-header--user-dropdown-container[js-target='global-header-menu-toggle'][data-menu='user']").removeClass("active");
			$("[js-target='global-header-menu'][data-menu='user']").removeClass("active");
		}, 450));
		this.desktopAnimationsStack.push(setTimeout(function() {
		}, 200));
	},

	openCartFlyoutAnimations: function(ev) {
		$(".header-menu--cart-dropdown").removeClass("fadeout-flyouts");
		$(".header-menu--cart-dropdown").addClass("fade-in-flyout");
		this.desktopAnimationsStack.push(setTimeout(function() {
			$(".cart-container").addClass("fade-in-flyout--cart-links");
		}, 400));
	},

	closeCartFlyoutAnimations: function(ev) {
		$(".header-menu--cart-dropdown").removeAttr("style");
		$(".header-menu--cart-dropdown").removeClass("fade-in-flyout");
		$(".cart-container").removeClass("fade-in-flyout--cart-links");
		$(".header-menu--cart-dropdown").addClass("fadeout-flyouts");
		this.desktopAnimationsStack.push(setTimeout(function() {
			$(".top-header--cart-dropdown-container[js-target='global-header-menu-toggle'][data-menu='cart']").removeClass("active");
			$("[js-target='global-header-menu'][data-menu='cart']").removeClass("active");
		}, 450));
		this.desktopAnimationsStack.push(setTimeout(function() {
		}, 200));
	},

	events: {
		"click [js-target='sign-in-register']": "renderSignInModal",
		"click [js-target='logout']": "logout",
		"click [js-target='sign-in-register--register']": "renderRegisterModal",
		"click [js-target='global-header-menu-toggle']": "toggleMenu",
		"click [js-target='global-header--search-overlay']": "showSearchOverlay",
		"click [js-target='validate-user-signed']": "validateUserSignedRedirect",
		"click [js-target='clear-search-box']": "clearSearchBox"
	},

	globalEvents: {
		"update:cart": "updateCart"
	}
};

mixinComponent(Header, "header");
