{% if (itemAdded === true){ %}
<section class="small-12 column">
	<section class="clearfix">
		<div class="row cushion"> 
			<div class="small-12 large-9 large-centered column">
				<h3 class="section-label mbl"><i class="icon icon-unfollow-this success-color"></i> Added to cart</h4>
				{% items.forEach(function(item) { %}
				<div class="row mtl">
					<div class="medium-8 column">
						<h4 class="hed mbs"><a href="{{ item.productUrl }}">{{ item.productTitle }}</a></h4>
						<div><span class="topic">{{ item.productMajorSubject }}</span> <span class="content-type">{{ item.productContentType }}</span></div>
						{% if (item.productAvailabilityStatus === "COMING_SOON") { %}<div class="label info mts">Available {{ item.publicationDate }}</div>{% } %}
					</div>
					<div class="medium-4 column">
						<div class="price mbm">{{ item.productPrice }}</div>
						{% if (item.format) { %}
							<h6 class="product-hed mbn">Format</h6>
							<p class="product-text mbm">{% if (item.type === "Perm") { %}(Copyright Permissions){% } else { %}{{ item.format }}{% } %}</p>
						{% } %}
						<h6 class="product-hed mbn">Quantity</h6>
						<p class="product-text mbm">{{ item.quantity }}</p>
					</div>
				</div>
				{% }); %}
				<div class="medium-8 column mvl">
					<div class="medium-6 column"><button class="button--alt-red expand" js-target="add-to-cart-confirmation-close">Continue Shopping</button></div>
					<div class="medium-6 column"><a class="button button--tight expand" href="/shopping-cart">Checkout</a></div>
					<div class="medium-12 column font-size-small text-gray-light">
						*Digital products can be downloaded from the Order Confirmation screen.  Item pre-orders will be available after the publication date listed on the item description.
					</div>
				</div>
			</div>
		</div>
	</section>
</section>
<section class="small-12 column">
	<div class="row">
		<div class="small-12 large-9 large-centered column">
			<h4 class="section-label mbl">You might also like:</h4>
			<personalization-placement class="personalization-placement" data-placement-id="added-to-cart"></personalization-placement>
		</div>
	</div>
</section>
{% } else { %}
	<div class="column large-8 large-push-2 mts">
	<i js-target="add-to-cart-confirmation-close" class="icon icon-times right"></i>
	<h3 class="subheader backdrop-alert-color white pam mvl">Our apologies, something went wrong.</h3>
	<h4 class="subheader line-height-normal mbs">There seems to be an issue with the product you requested. Please call Customer Service at <strong>800.988.0886</strong> (U.S./Canada) or <strong>+1.617.783.7500</strong> (outside U.S./Canada) and reference product ID <strong class="info-color">[	
	{% items.forEach(function(item, index, items) { %}
		{{item.productId}}
			{% if(index !== items.length - 1) { %},{% } %}
		{% }); %}]
	</strong> for assistance.<p></p></h4>
	</div>
{% } %}