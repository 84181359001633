/**
 * @module components/modal-box
 * @requires module:jquery
 * @requires module:mixins/component
 */

// Dependencies
import $ from "jquery";

// The component mixin
import mixinComponent from "../../mixins/component";

export function ModalBox($el) {
	this.initializeAsComponent($el);
}

ModalBox.prototype = {
	render: function(template) {
		var quarterTop = $(window).height() / 4;

		// Let's add a transparent layer on top of the entire site
		createTransparentLayer();

		$("<modal-box/>").appendTo("body");

		$("modal-box").addClass("reveal-modal tiny")
			.html("<div class='text-center lighter-gray font-light font-size-large'>" +
				"<i class='icon icon-spinner icon-spin info-color'></i> Processing...</div>");

		if (template) {
			$("modal-box").html(template);
		}

		var scrollPosition = $("body").scrollTop() || $("html").scrollTop();
		if (scrollPosition === 0) {
			$("modal-box").addClass("show-modal");
		} else {
			$("modal-box").animate({top: scrollPosition + quarterTop}, 1000);
		}

		$("modal-box").addClass("open-modal");
	},

	close: function() {
		$("modal-box").animate({top: -750}, 1000).delay(500).remove();

		$(".transparent-layer").fadeOut(500).remove();
	}
};

/**
* function that will create a transparent layer on top of
* the entire page
*
*/
var createTransparentLayer = function() {
	var width = $(document).width();
	var height = $(document).height();

	$("<div/>", {
		class: "transparent-layer",
		height: height,
		width: width
	}).appendTo("body");
};

mixinComponent(ModalBox, "modal-box");

ModalBox.render = ModalBox.prototype.render;
ModalBox.close = ModalBox.prototype.close;

export default ModalBox;
