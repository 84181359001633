import mixinComponent from "../../mixins/component";
import _throttle from "lodash-amd/modern/functions/throttle";
import FolderList from "../folder-list/folder-list";
import TopicMenu from "../topic-menu/topic-menu";
import $ from "jquery";

export default function TabBar($el) {
	this.initializeAsComponent($el);
}

TabBar.prototype = {
	events: {
		"click [js-target='tab']": "selectTab",
		"click [js-target='tab-opener']": "openTabs"
	},

	selectTab: function(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		var tabname = $(ev.currentTarget).attr("data-stream-name"),
			tabs = $("[js-target='tabs']");
		if (tabs.attr("state-path")) {
			if (tabs.attr("state-pop")) {
				tabs.attr("state-pop", "");
			}
			else {
				if (tabname) {
					window.history.pushState({name: tabname}, tabname, tabs.attr("state-path") + tabname);
				}
			}
		}

		if (tabname === tabs.attr("folderlist-tab-name")) {
			FolderList.updateOnToggleTab();
		} else if (tabname === "topics") {
			TopicMenu.updateOnToggleTab();
		}

		this.$("[js-target='tab']").not(ev.currentTarget).removeClass("active");
		this.$(ev.currentTarget).addClass("active");
		this.$("[js-target='tab-opener-text']").html($(ev.currentTarget).text());
		this.$el.removeClass("open");
		$(ev.currentTarget).trigger("tab-bar:change");
	},

	openTabs: function(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		this.$el.toggleClass("open");
	}
};

$(window).on("scroll", _throttle(function() {$("tab-bar").removeClass("open"); }, 500));

mixinComponent(TabBar, "tab-bar");

