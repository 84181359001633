import $ from "jquery";

// api endpoints
var API_ENPOINTS = {
	LOOKUP: "/api/user/subscriber/search",
	VERIFY: "/api/user/subscribe"
};

/**
 * lookup - Look up the subscription
 * @param {string} emailAddress - Email Address of the subscriber
 * @param {string} accountNumber - Subscription Account number
 * @returns {promise.<object,Error>} - Promise with the subscriber data.
 */
exports.lookup = function (emailAddress, accountNumber) {
	// create a local promise
	const promise = $.Deferred();

	// lookup the subscription
	$.post(API_ENPOINTS.LOOKUP, {
		emailAddress: emailAddress,
		accountNumber: accountNumber
	}).then(function (data) {
		if (data.active) {
			// find the first magazine subscription
			if ($.isArray(data.subscriptions)) {
				for (let i = 0; i < data.subscriptions.length; i++) {
					if (data.subscriptions[i].active &&
							(data.subscriptions[i].subscriptionSourceCode === "CDS_US" ||
									data.subscriptions[i].subscriptionSourceCode === "CDS_UK")) {
						// the subscriber has the subscription we need, resolve the
						// promise with the subscriber
						let subscription = data.subscriptions[i];
						subscription.firstName = data.firstName;
						subscription.lastName = data.lastName;
						subscription.email = data.emailAddress;
						subscription.isNew = data.new;
						subscription.userAccountExists = data.userAccountExists;
						promise.resolve(subscription);
					}
				}
			}
		} else {
			// user is not active
			promise.reject(404, {
				message: "No Active Subscriptions Found"
			});
		}
	}, function (resp) {
		// fail the promise
		promise.reject(resp);
	});

	// return the promise;
	return promise.promise();
};

/**
 * verify - Verify the subscription
 * @param {object} subscriber - Subscriber Object
 * @param {string} password - Subscriber Password
 * @returns {promise.<object,Error>} - Promise with the subscriber data.
 */
exports.verify = function (subscriber, password) {
	var subscriber = {
		emailAddress: subscriber.email,
		accountNumber: subscriber.accountNumber,
		password: password,
		optIn: subscriber.optIn
	};
	return $.ajax({
		type: "post",
		url: API_ENPOINTS.VERIFY,
		data: JSON.stringify(subscriber),
		contentType: "application/json",
		dataType: "json"
	//}).then(function (resp) {
		//note from i.o. chceck server side.
	//	return resp.user;
	});
};
