import create from "zustand"

const initialState = {
	captchaToken: null,
	widgetID: "",
	captchaTarget: "",
	state: "init"
}

const captchaStore = create((set, get) => ({
	...initialState,
	updateValue: (fieldName, value) => {
		set(state => ({
			...state,
			[fieldName]: value
		}))
	},
	reset: () => {
		set(state => ({
			...initialState,
		}))
	}
}))

export default captchaStore
