import React from 'react';

function ErrorMessage(props) {
	if(!props.hasOwnProperty('children') || props.children == undefined)
		return null;
	return (
		<span class="error-item">
			<span class="icon-alert"></span>
			<span class="error-message">
				{props.children}
			</span>
		</span>
	)
}

export default ErrorMessage;
