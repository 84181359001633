
import $ from "jquery";
import _bind from "lodash-amd/modern/functions/bind";
//import historyService from "./history";
import request from "../request";
/**
 * Supported HBR Services
 */
var hbrServices = {
	bulkListItem: require("./bulk-list-item"),
	shared: require("./share"),
	saved: require("./saves"),
	topic: require("./follow"),
	newsletter: require("./subscribe"),
	marketingCampaign: require("./marketing-campaign"),
	lists: require("./lists"),
	listItem: require("./list-item"),
	listUrl: require("./list-url"),
	searchFilter: require("./search-filter"),
	article: require("./article"),
	cart: require("./cart"),
	attributes: require("./attributes"),
	migrate: require("./migrate"),
	publicNewsletter: require("./public-newsletter"),
	publicNewsletterProfile: require("./public-newsletter-profile"),
	publicMarketingCampaign: require("./public-marketing-campaign")
};

/**
   * Make an activity request respective to the type and track history.
   *
   * @param {string} activity - A string notifiying the activity type.
   * @param {string} method - An optional string indicating the HTTP verb.
   * @param {object} contentObject - A normalized object to transmit.
   * @returns {object} A promise that resolves from post and history success.
   */
exports.request = function(activity, method, contentObject) {
	var service = hbrServices[activity];

	// Shift arguments if the method isn't provided.
	if (typeof method !== "string") {
		contentObject = method;
		method = "post";
	}

	if (!service) {
		throw new Error("Unsupported activity: " + activity);
	}

	// Get the URI to post to, passing the contentObject to inform activities.
	var uri = $.isFunction(service.getURI) && service.getURI(contentObject);

	// Use either the post provided to the service or make a custom call using
	// the provided URI.  If the URI provided is incorrect the server will
	// fail helping narrow down the origin of bugs.
	var requestMethod = method || "post";
	var req = $.isFunction(service[method]) ? service[method] : _bind(request.ajax, this, requestMethod, uri);

	// track interaction with history for non-gets
	return req(contentObject);
};

/**
* function that will count the number of stream contents in order
* to see if there are any stream content within a section
*
* @param {object} element - jQuery object of the stream item
*
* @return {number} number of siblings of the stream item
*/
exports.streamContentCount = function(element) {
	return $(element).siblings(".stream-entry").length;
};

/**
* function that will hide the stream-section-label, if there are
* no stream items within the stream section
*
* @param {object} element - element to be inspected for sibling content
*
*/
exports.hideStreamLabel = function(element) {
	if (this.streamContentCount(element) === 0) {
		$(element).siblings(".stream-section-label").hide();
	}

	return this;
};
