/**
 * @module components/add-to-cart-confirmation
 * @requires module:jquery
 * @requires module:mixins/component
 * @requires module:services/cart
 */

// Dependencies
import $ from "jquery";

// Components
import mixinComponent from "../../../mixins/component";

// Templates
import contentTemplate from "./template";
import maintenanceTemplate from "./maintenance";

var component;

export function AddToCartConfirmation($el) {
	this.initializeAsComponent($el);

	component = this;
}

AddToCartConfirmation.prototype = {
	globalEvents: {
		"click [js-target='add-to-cart-confirmation-close']": "closeConfirmation",
		"click [js-target='add-to-cart-confirmation-checkout']": "checkoutCart",
		"click .transparent-layer": "removeModal"
	},

	/**
	* function that will render the confirmation modal
	*
	* @param {object} cartItem
	*
	*/
	render: function(cartItem) {
		if ($("add-to-cart-confirmation").length > 0) {
			component.removeModal();
		}
		var width = $(document).width();
		var height = $(document).height();
		$("<div/>", {
			class: "transparent-layer",
			height: height,
			width: width
		}).appendTo("body");
		$("<add-to-cart-confirmation/>").appendTo("body");

		$("add-to-cart-confirmation").append(contentTemplate(cartItem));

		var scrollPosition = $("body").scrollTop() || $("html").scrollTop();
		if (scrollPosition === 0) {
			$("add-to-cart-confirmation").addClass("show-confirmation");
		} else {
			$("add-to-cart-confirmation").animate({top: scrollPosition}, 1000);
		}
	},
	renderMaintenanceNotice: function() {
		var width = $(document).width();
		var height = $(document).height();

		$("<div/>", {
			class: "transparent-layer",
			height: height,
			width: width
		}).appendTo("body");
		$("<add-to-cart-confirmation/>").appendTo("body");
		$("add-to-cart-confirmation").append(maintenanceTemplate);

		var scrollPosition = $("body").scrollTop() || $("html").scrollTop();
		if (scrollPosition === 0) {
			$("add-to-cart-confirmation").addClass("show-confirmation");
		} else {
			$("add-to-cart-confirmation").animate({top: scrollPosition}, 1000);
		}
	},

	/**
	* function that will remove the confirmation modal
	*
	* @param {object} el
	*
	*/
	removeModal: function(el) {
		$("add-to-cart-confirmation").animate({top: -750}, 1000).delay(500).remove();

		$(".transparent-layer").fadeOut(500).remove();
	},

	/**
	* function that will close the confirmation modal box
	*
	* @param {object} ev - event object
	*
	*/
	closeConfirmation: function(ev) {
		component.removeModal();
	},

	/**
	* function that will go to checkout page
	*
	* @param {object} ev
	*
	*/
	checkoutCart: function(ev) {
		component.removeModal();
		window.location = "/shopping-cart";
	}
};

mixinComponent(AddToCartConfirmation, "add-to-cart-confirmation");

AddToCartConfirmation.render = AddToCartConfirmation.prototype.render;
AddToCartConfirmation.renderMaintenanceNotice = AddToCartConfirmation.prototype.renderMaintenanceNotice;

export default AddToCartConfirmation;
