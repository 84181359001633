
// Components
import "slick-carousel";

// The component mixin.
import mixinComponent from "../../mixins/component";

/**
 * Represents a slick slide carousel component.
 *
 * @public
 * @param {Object} $el - A jQuery object representing the component.
 */
export default function ProductCarousel($el) {
	this.initializeAsComponent($el);

	$el.slick({
		arrows: false,
		autoplay: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	});
}

// Here you register the Component to be decorated.
mixinComponent(ProductCarousel, "product-carousel");

// Export the constructor so that it may be initialized elsewhere.