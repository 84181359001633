
import $ from "jquery";
import "validate";
import _clone from "lodash-amd/modern/objects/clone";
import _assign from "lodash-amd/modern/objects/assign";
import geoip from "../services/geoip";
import storageService from "../services/localstorage-util";
import errorTemplate from "../templates/error/error-box";

function assoc(d, k, v) {
	var d1 = _clone(d);
	d1[k] = v;
	return d1;
}

function assocIn(d, ks, v) {
	return assoc(d, ks[0], ks.length === 1 ? v : assocIn(d[ks[0]] || {}, ks.slice(1), v));
}

function merge() {
	return _assign.bind(undefined, {}).apply(undefined, arguments);
}

function trigger(name, detail) {
	document.body.dispatchEvent(new CustomEvent(name, {bubbles: true, detail: detail}));
}

function isElementInViewport(el) {
	var rect = el.getBoundingClientRect();
	return rect.bottom > 0 &&
		rect.right > 0 &&
		rect.left < (window.innerWidth || document. documentElement.clientWidth) &&
		rect.top < (window.innerHeight || document. documentElement.clientHeight);
}

function setEllipsis(el) {
	var wordArray = el.innerHTML.split(" ");
	/* the 4 is to adjust for the weird Webkit behaviour when scrollHeight == offsetHeigt - 1 even with no scrollable content */
	while (el.scrollHeight - 4 > el.offsetHeight) {
		wordArray.pop();
		el.innerHTML = wordArray.join(" ") + "...";
	}
}

function isElementAboveViewport(el) {
	var rect = el.getBoundingClientRect();
	return rect.top < 0;
}

/* Check if user is domestic. Check local storage first. */
function determineIfUsCaDomesticUser(callback) {
	var isUsCaDomUser = storageService.getCache("isDomUser");
	if (isUsCaDomUser == null) {
		geoip.determineIfDomesticUser().then(function(isUsCaDomUser) {
			storageService.setCache("isDomUser", isUsCaDomUser);
			callback(isUsCaDomUser);
		});
	} else {
		callback(isUsCaDomUser);
	}
}

/* Check if user is US. Check local storage first. */
function determineIfUSDomesticUser(callback) {
	var isUsDomUser = storageService.getCache("isUsDomUser");
	if (isUsDomUser == null) {
		geoip.determineIfUSDomesticUser().then(function(isUsDomUser) {
			storageService.setCache("isUsDomUser", isUsDomUser);
			callback(isUsDomUser);
		});
	} else {
		callback(isUsDomUser);
	}
}

function setupMailValidation(fieldId) {
	//add email address validator
	$.validator.addMethod(fieldId, function(value, element) {
		return /[^\s@]+@[^\s@]+\.[^\s@]+/.test(value);
	}, "Please enter a valid email address.");
}

/**
 * Message to be displayed when an input field is empty.
 */
function getFieldRequiredMessage() {
	return errorTemplate({"message": "This field is required."});
}

/**
 * Message to be displayed when an email field does not comply format.
 */
function getFieldMailMessage() {
	return errorTemplate({"message": "Please enter a valid email address."});
}

/**
 * Setup validation behavior for email field
 */
function  validateEmail($emailLookupForm, submitHandler) {
	$emailLookupForm.validate({
		rules: {
			emailAddress: {
				required: true,
				emailCheck: true
			}
		},
		messages: {
			emailAddress: {
				required: getFieldRequiredMessage(),
				emailCheck: getFieldMailMessage()
			}
		},
		submitHandler: submitHandler
	});
}

(function () {
	if (typeof window.CustomEvent === "function") { return false; }
	function CustomEvent(event, params) {
		params = params || {bubbles: false, cancelable: false, detail: undefined};
		var evt = document.createEvent("CustomEvent");
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}
	CustomEvent.prototype = window.Event.prototype;
	window.CustomEvent = CustomEvent;
})();

function isPianoOfferpage() {
	if ($("#subscription-offers").length > 0) {
		return true;
	} else {
		return false;
	}
}

function setCookie(name, value, days) {
	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function deleteCookie(name) {
	document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function readCookie(name) {
	var nameEq = name + "=";
	var ca = document.cookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(nameEq) === 0) {
			return c.substring(nameEq.length, c.length);
		}
	}
	return null;
}

/**
 * getUnlockAccountTime
 * @param {number} remainingSeconds - data to update
 * @returns {string} the remaining time in which the account will be unlocked.
 */
function getUnlockAccountTime(remainingSeconds) {
	const SECONDS_IN_AN_HOUR = 3600;
	const SECONDS_IN_A_MIN = 60;

	if (remainingSeconds >= SECONDS_IN_AN_HOUR) {
		return (Math.round(remainingSeconds / SECONDS_IN_AN_HOUR)) + " hrs";
	} else if (remainingSeconds < SECONDS_IN_AN_HOUR && remainingSeconds >= SECONDS_IN_A_MIN) {
		return (Math.round(remainingSeconds / SECONDS_IN_A_MIN)) + " mins";
	} else {
		return remainingSeconds + " secs";
	}
}

class OSUtils {
	constructor() {
		this.userAgent = window.navigator.userAgent;
		this.platform = navigator.appVersion;
	}

	isMacOS() {
		let macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
		for (var i = 0; i < macosPlatforms.length; i++) {
			if (this.platform.indexOf(macosPlatforms[i]) > -1) {
				return true;
			}
		}
	}

	isIOs() {
		let iosPlatforms = ["iPhone", "iPad", "iPod"];
		for (var i = 0; i < iosPlatforms.length; i++) {
			if (this.platform.indexOf(iosPlatforms[i]) > -1) {
				return true;
			}
		}
	}

	isWindows() {
		let windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
		for (var i = 0; i < windowsPlatforms.length; i++) {
			if (this.platform.indexOf(windowsPlatforms[i]) > -1) {
				return true;
			}
		}
	}

	isLinux() {
		return !this.nonLinuxBased() && /Linux/.test(this.platform);
	}

	isAndroid() {
		return !this.nonLinuxBased() && /Android/.test(this.platform);
	}

	nonLinuxBased() {
		return this.isMacOS() || this.isIOs() || this.isWindows();
	}
}

exports.assoc = assoc;
exports.assocIn = assocIn;
exports.merge = merge;
exports.trigger = trigger;
exports.isElementInViewport = isElementInViewport;
exports.isElementAboveViewport = isElementAboveViewport;
exports.setEllipsis = setEllipsis;
exports.determineIfUsCaDomesticUser = determineIfUsCaDomesticUser;
exports.determineIfUSDomesticUser = determineIfUSDomesticUser;
exports.setupMailValidation = setupMailValidation;
exports.validateEmail = validateEmail;
exports.isPianoOfferpage = isPianoOfferpage;
exports.setCookie = setCookie;
exports.deleteCookie = deleteCookie;
exports.readCookie = readCookie;
exports.OSUtils = OSUtils;
exports.getUnlockAccountTime = getUnlockAccountTime;
