
import $ from "jquery";
import _bind from "lodash-amd/modern/functions/bind";
import _each from "lodash-amd/modern/collections/forEach";

// Ensure all components also have eventing.
import mixinEvents from "./events";

/**
 * Sets up the Component instance with consistent defaults.
 *
 * @param {Object} $el - A jQuery object.
 */
var initializeAsComponent = function($el) {
	var component = this;

	// Cache this element for later use.
	component.$el = $el;

	// Provide a convenient scoped lookup function.
	component.$ = function() {
		return component.$el.find.apply(component.$el, arguments);
	};

	// Shorthand data access.
	component.dataset = $el.data();

	// Store this component instance inside the element's data.
	$el.data("component", component);

	bindEvents(this.events, $el);
	bindEvents(this.globalEvents, $("body"));

	function bindEvents(eventList, context) {
		_each(eventList, function(fn, eventAndSelector) {
			var parts = eventAndSelector.split(" ");
			var event = parts[0];
			var selector = parts.slice(1).join(" ");

			if(event === "scroll") {
				context.find(selector).on("scroll", _bind(component[fn], component));
			}

			context.on(event, selector, _bind(component[fn], component));
		});
	}
};

/**
 * Activate a component with a given DOM node.
 *
 * @param {Object} el - A DOM node.
 *
 * @return {Object} An instance of the Component being activated.
 */
var activate = function(el) {
	return new this($(el));
};

/**
 * Activates all matching elements on the page.
 *
 * @param {*} context - A valid value that can be passed to jQuery.
 * @returns {Object} jQuery collection of elements.
 */
var activateAll = function(context) {
	// Ensure the activate method know's about this constructor.
	var activateThis = _bind(function(index, element) {
		this.activate(element);
	}, this);

	// Automatically render the existing set of elements.
	return $(this.selector, context || document).each(activateThis);
};

/**
 * Add the activation methods and update the prototype chain.
 *
 * @param {Function} Component - A Component constructor.
 * @param {string} selector - An optional selector to set.
 */
export default function mixinComponent(Component, selector) {
	Component.activate = activate;
	Component.activateAll = activateAll;
	Component.selector = selector;

	Component.prototype.initializeAsComponent = initializeAsComponent;

	// Ensure components have events.
	mixinEvents(Component.prototype);
}

