import React, {Component} from 'react';
import ReactDom from 'react-dom';
import RegistrationComponent from './RegistrationComponent.jsx';
import SignInComponent from "./SignInComponent.jsx"
import ForgotPasswordComponent from "./ForgotPasswordComponent.jsx"
import utilities from "../../support/utilities";
import state from "../../services/state";

class SignInRegistrationModal extends Component {

	constructor(props) {
		super();
		this.state = {
			action: props.action,
			optIn: false,
			isUsDomUser: true,
			isMobile: false
		}
		utilities.trigger("metrics:signin_start");
	}

	componentWillMount() {
		utilities.determineIfUSDomesticUser(function(response) {
			this.setState({isUsDomUser: response, optIn: response})
		}.bind(this));

		if (state.isMobile()) {
			$(document).on("focusin", "input", function() {
				$(".sign-in-modal--container").addClass("modal-open");
			});

			$(document).on("focusout", "input", function() {
				$(".sign-in-modal--container").removeClass("modal-open");
			});

			this.setState({isMobile:true});
		}

		var body = document.querySelector("body");
	}

	componentDidMount() {
		if (this.state.isMobile) {
			$(".sign-in-modal--frame.relative.backdrop-off-white.pal").css({
				"position":"absolute"
			});
		}
		// Remove scroll from body
		var body = document.querySelector("body");
		body.className += " fixed-body";
	}

	componentWillUnmount() {
		// Allow scroll from body
		var body = document.querySelector("body");
		body.className = body.className.replace("fixed-body", "");
	}

	render() {
		let renderHtml;

		if (this.state.action==="registration"){
			 renderHtml = <RegistrationComponent
			 									title={this.props.title}
												subtitle={this.props.subtitle}
			 									isUsDomUser={this.state.isUsDomUser}
												optIn={this.state.optIn}
								 				toggle={this.toggle.bind(this)}
												onClose={this.props.onClose}
												navigateToGoBack={this.navigateToGoBack.bind(this)}
												navigateToForgotPassword={this.navigateToForgotPassword.bind(this)} 
												isGiftOffer={this.props.isGiftOffer} />;
		} else if (this.state.action==="forgot-password") {
			renderHtml = <ForgotPasswordComponent
							onClose={this.props.onClose} navigateToSignIn={this.navigateToSignIn.bind(this)} navigateToRegister={this.navigateToRegister.bind(this)}/>;
		}else {
			renderHtml = <SignInComponent
								title={this.props.title}
								subtitle={this.props.subtitle}
								toggle={this.toggle.bind(this)}
								navigateToGoBack={this.navigateToGoBack.bind(this)}
								navigateToForgotPassword={this.navigateToForgotPassword.bind(this)}
								onClose={this.props.onClose} 
								isGiftOffer={this.props.isGiftOffer} />;
		}
		return (
			<div class="hbr-modal-overlay active" data-close-modal="true">
				{renderHtml}
			</div>
		)
	}

	navigateToGoBack() {
		this.props.goBackWindow.postMessage("OnGoBack", "*");
		this.props.onClose();
	}

	navigateToSignIn() {
		this.setState({ action: "signin"});
	}

	navigateToRegister() {
		this.setState({ action: "registration"});
	}

	navigateToForgotPassword() {
		this.setState({ action: "forgot-password"});
	}

	toggle() {
		if (this.state.action ==="registration"){
			this.setState({ action: "signin"});
		} else {
			this.setState({ action: "registration" });
		}
	}

 	handleTouchMove(event){event.preventDefault(); }
}

export default function triggerSignInRegisterModal(container, mode, title, subtitle, goBackWindow) {
	// Create an event for unmounting component
	let unmountEvent = function() {
		ReactDom.unmountComponentAtNode(container);
		container.remove();
		goBackWindow.postMessage("OnClose", "*");
	}

	// Mount react component
	ReactDom.render(<SignInRegistrationModal action={mode}
			title={title}
			subtitle={subtitle}
			onClose={unmountEvent.bind(this)}
			goBackWindow={goBackWindow}
			isGiftOffer={window.isGiftOffer} />,
			container);
}
