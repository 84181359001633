
import $ from "jquery";

/**
	* Create a jQuery collection from this object, allowing us to
	* proxy events.
 */
export default function mixinEvents(object) {
	var $object = $(object);

	// Borrow remaining jQuery event functions.
	object.on = $.proxy($object.on, $object);
	object.once = $.proxy($object.one, $object);
	object.off = $.proxy($object.off, $object);
	object.trigger = $.proxy($object.trigger, $object);
}

