import $ from "jquery";
import _clone from "lodash-amd/modern/objects/clone";
import _assign from "lodash-amd/modern/objects/assign";
import mixinComponent from "../../mixins/component";
import utils from "../../services/shopping-cart-utils";
import modalBox from "../modal-box/modal-box";
import cartService from "../../services/cart";
import AddToCartConfirmation from "../add-to-cart/add-to-cart-confirmation/add-to-cart-confirmation";
export default function ProductAvailabilities($el) {
	this.initializeAsComponent($el);
	this.addToCartAvailability = $el.data();
}
ProductAvailabilities.prototype = {
	events: {
		"click [js-target='availability-format']>span": "changeFormat",
		"click [js-target='availability-language']>span": "changeLanguage",
		"click [js-target='copyright-checkbox']": "permissionCheckBox",
		"click [js-target='add-product']": "addProduct",
		"change [js-target='opt-format']": "changeOptionFormat",
		"change [js-target='opt-language']": "changeOptionLanguage"
	},
	addToCartAvailability: null,
	/** When click to the format make it bold/strong if it is gray color,
	   Also display related languages and make firs option bold/strong
	*/
	changeFormat: function(ev) {
		/* formats */
		var targetComponent = this.$(ev.currentTarget);
		var activeAvailability = null;
		if (!targetComponent.hasClass("font-heavy")) {
			targetComponent.parent().children().removeClass("font-heavy");
			targetComponent.parent().children().addClass("font-light");
			targetComponent.addClass("font-heavy");
			targetComponent.removeClass("font-light");
			this.markSelectedFormat(targetComponent);
			/* availabilities */
			var $parsedJSONArray = getAllAvailabilities();
			var $selectedFormatAvailabilities = $parsedJSONArray[targetComponent.text()];
			/* reset the languages, rerender them */
			$("product-availabilities").find("[js-target='availability-language']").html("");
			$.each($selectedFormatAvailabilities, function(i, availability) {
				$("<span/>", {
					class: i === 0 ? "idp-option font-heavy mrm darker-gray" : "idp-option font-light mrm darker-gray",
					text: availability.language
				}).appendTo("[js-target='availability-language']");
				if (i === 0) {
					activeAvailability = availability;
				}
			});

			/* update global object */
			this.updateGlobalActiveObject(activeAvailability);
			this.toggleShippable(activeAvailability);
			this.toggleDigital(activeAvailability);
			/* reflect the availabilty values to other part on the page */
			$("product-availabilities").find(".price--large").text(parseFloat(activeAvailability.listPrice).toFixed(2));
			$("product-menu").find("[js-target='idp-product-id']").text(activeAvailability.availabilityId);
			updateBTaddToCartElement(activeAvailability);
			updateSaveShareStreamElement(activeAvailability);
		}
	},

	changeOptionFormat: function(ev) {
		var activeAvailability = null;
		var targetComponent = this.$(ev.currentTarget);
		var $parsedJSONArray = getAllAvailabilities();
		this.$el.find("[js-target='opt-language']").html("");

		var $selectedFormatAvailabilities = $parsedJSONArray[targetComponent.val()];
		$.each($selectedFormatAvailabilities, function(i, availability) {
			$("<option/>", {
				text: availability.language,
				value: availability.language
			}).appendTo("[js-target='opt-language']");
			if (i === 0) {
				activeAvailability = availability;
			}
		});
		/* update global object */
		this.updateGlobalActiveObject(activeAvailability);
		this.toggleShippable(activeAvailability);
		this.toggleDigital(activeAvailability);
		/* reflect the availabilty values to other part on the page */
		$("product-availabilities").find(".price--large").text(parseFloat(activeAvailability.listPrice).toFixed(2));
		$("product-menu").find("[js-target='idp-product-id']").text(activeAvailability.availabilityId);
		updateBTaddToCartElement(activeAvailability);
		updateSaveShareStreamElement(activeAvailability);

	},

	updateGlobalActiveObject: function(activeAvailability) {
		var url = this.addToCartAvailability.productUrl.split("/");
		this.addToCartAvailability.productUrl = "/product/" + url[2] + "/" + activeAvailability.availabilityId;
		this.addToCartAvailability.productId = activeAvailability.availabilityId;
		this.addToCartAvailability.format = activeAvailability.format;
		this.addToCartAvailability.productPrice = activeAvailability.listPrice;
		this.addToCartAvailability.type = utils.getProductType(activeAvailability.availabilityId);
	},
	/** permission checkbox **/
	permissionCheckBox: function(ev) {
		var $targetComponent = this.$(ev.currentTarget);
		var $checked = $targetComponent.is(":checked");
		var $permission = this.$el.find("[js-target='permission']");
		this.$el.find("[js-target='permission-quantity']").val(0);

		if ($checked) {
			$permission.removeClass("is-hidden");
		} else {
			$permission.addClass("is-hidden");
		}
	},

	/**
	When click to the language change it the bold
	*/
	changeLanguage: function(ev) {
		var targetComponent = this.$(ev.currentTarget);
		targetComponent.parent().children().removeClass("font-heavy");
		targetComponent.parent().children().addClass("font-light");
		targetComponent.addClass("font-heavy");
		targetComponent.removeClass("font-light");

		/* selected format */
		var $selectedFormat = $("product-availabilities").find("[data-format-selected='true']");
		var $selectedLanguage = targetComponent.text();
		/* availabilties */
		var $parsedJSONArray = getAllAvailabilities();
		/* selected format availabilities */
		var $selectedFormatAvailabilities = $parsedJSONArray[$selectedFormat.text()];
		var activeAvailability = null;
		/* loop find matching availability */
		$.each($selectedFormatAvailabilities, function(i, availability) {
			if ($selectedLanguage === availability.language) {
				activeAvailability = availability;
			}
		});
		/* reflect the availabilty values to other part on the page */
		this.updateGlobalActiveObject(activeAvailability);
		$("product-availabilities").find(".price--large").text(parseFloat(activeAvailability.listPrice).toFixed(2));
		$("product-menu").find("[js-target='idp-product-id']").text(activeAvailability.availabilityId);
		updateBTaddToCartElement(activeAvailability);
		updateSaveShareStreamElement(activeAvailability);
	},
	changeOptionLanguage: function(ev) {
		var targetComponent = this.$(ev.currentTarget);
		/* selected format */
		var $selectedFormat = this.$el.find("[js-target='opt-format']").val();
		var $selectedLanguage = targetComponent.val();
		/* availabilties */
		var $parsedJSONArray = getAllAvailabilities();
		/* selected format availabilities */
		var $selectedFormatAvailabilities = $parsedJSONArray[$selectedFormat];
		var activeAvailability = null;
		/* loop find matching availability */
		$.each($selectedFormatAvailabilities, function(i, availability) {
			if ($selectedLanguage === availability.language) {
				activeAvailability = availability;
			}
		});
		/* reflect the availabilty values to other part on the page */
		this.updateGlobalActiveObject(activeAvailability);
		this.$el.find(".price--large").text(parseFloat(activeAvailability.listPrice).toFixed(2));
		$("product-menu").find("[js-target='idp-product-id']").text(activeAvailability.availabilityId);
		updateBTaddToCartElement(activeAvailability);
		updateSaveShareStreamElement(activeAvailability);
	},
	addProduct: function(ev) {
		ev.preventDefault();
		ev.stopImmediatePropagation();
		modalBox.activate($("body")).render();
		this.addProductToCart(this.addToCartAvailability).then(function(response) {
			modalBox.close();

			if (response.maintenanceStatus == null) {
				AddToCartConfirmation.activate($("body")).render({items: response.addToCartItems, itemAdded: true});
				$("body").trigger("update:cart", response.cart);
				$("body").trigger("add-to-cart:success", [response.cart, response.addToCartItems]);
			} else {
				AddToCartConfirmation.activate($("body")).renderMaintenanceNotice();
			}
		}, function(error) {
			modalBox.close();
			AddToCartConfirmation.activate($("body")).render({items: error.addToCartItems, itemAdded: false});
		});
	},

	/* DIGITAL */
	toggleDigital: function(availability) {
		/*reset checkbox */
		this.$el.find("[js-target='copyright-checkbox']").attr("checked", false);
		var $digital = this.$el.find("[js-target='digital']");
		var $permission = this.$el.find("[js-target='permission']");
		var $copyPermissionQty = this.$el.find("[js-target='permission-quantity']");

		if (availability.lineType.toUpperCase() !== "SHIP" && availability.lineType.toUpperCase() !== "EBOOK" && availability.lineType.toUpperCase() !== "BUNDLE" && availability.permissionsAvailable) {
			/* display */
			$copyPermissionQty.val(0);
			$digital.removeClass("do-not-show");
		} else {
			/* hide */
			$permission.addClass("is-hidden");
			$digital.addClass("do-not-show");
			$copyPermissionQty.val(0);
		}
	},

	/* SHIPPABLE */
	toggleShippable: function(availability) {
		var $shippable = this.$el.find("[js-target='shippable']");
		var $inputQty = this.$el.find("[js-target='shippable'] [js-target='item-quantity']");
		var $volPricing = this.$el.find("[js-target='volume-pricing']");
		var $downloadable = $("product-menu").find("[js-target='idp-product-downloadable']");
		if (availability.lineType.toUpperCase() === "SHIP" || availability.lineType.toUpperCase() === "EBOOK" || availability.lineType.toUpperCase() === "BUNDLE") {
			/* display */
			$shippable.removeClass("is-hidden");
			$inputQty.removeClass("is-hidden");
			if (availability.isVolumePricingAvailable) {
				$volPricing.removeClass("is-hidden");
			}
		} else {
			/* hide */
			$inputQty.val(1);
			$shippable.addClass("is-hidden");
			$inputQty.addClass("is-hidden");
			$volPricing.addClass("is-hidden");
		}
		if (availability.lineType.toUpperCase() === "SHIP" || availability.lineType.toUpperCase() === "BUNDLE") {
			$downloadable.addClass("is-hidden");
		} else {
			$downloadable.removeClass("is-hidden");
		}
	},
	/**
	* function that will process all the necessary steps and
	* prepare the item to be added to the cart
	*
	* @param {object} cartEl - add-to-cart element
	*
	* @return {promise.object} promise returned for adding to cart
	*/
	addProductToCart: function(cartAvailability) {
		var dfd = new $.Deferred();
		var items = [];
		var permQuantity = 0;
		var itemQuantity = 0;
		/* assign all data properties to item object */
		var item = cartAvailability;
		/* referral */
		var referral = item.productUrl && item.productUrl.match(/\?.*referral=(\d+)/);
		if (referral && referral.length > 1) {
			item.referral = referral[1];
		}
		/* digital, ship,ebook */
		if (!item.type && item.productId) {
			item.type = utils.getProductType(item.productId);
		}
		if (item.type.toUpperCase() !== "SHIP" && item.type.toUpperCase() !== "EBOOK" && item.type.toUpperCase() !== "BUNDLE") {
			var $permissionQuantityEl = this.$el.find("[js-target='permission-quantity']");
			if ($permissionQuantityEl.length > 0 && $permissionQuantityEl.is(":visible") && $permissionQuantityEl.val() > 0) {
				itemQuantity = 1;
				permQuantity = parseInt($permissionQuantityEl.val(), 10);
				var permItem = _clone(item, true);
				permItem = _assign(permItem, {type: "Perm", quantity: permQuantity});
				items.push(permItem);
			} else if (!$permissionQuantityEl.is(":visible")) {
				itemQuantity = 1;
			}
		} else {
			var $itemQuantityEl = this.$el.find("[js-target='item-quantity']");
			if ($itemQuantityEl.length > 0 && $itemQuantityEl.is(":visible") && $itemQuantityEl.val() > 0) {
				itemQuantity = parseInt($itemQuantityEl.val(), 10);
			}
		}

		/* add item */
		if (itemQuantity > 0) {
			item.quantity = itemQuantity;
			items.push(item);
		}

		if (items.length > 0) {
			cartService.addToCart(items).then(function(response) {
				response.addToCartItems = items;
				dfd.resolve(response);
			}, function(response) {
				response.addToCartItems = items;
				dfd.reject(response);
			});
		} else {
			console.log("Error:Unable to identify item count or permission count.");
		}
		return dfd;
	},

	markSelectedFormat: function(targetComponent) {
		/* marke all as false(reset) */
		var $formats = $("product-availabilities").find("[js-target='availability-format']>span");
		$.each($formats, function(i, mSpan) {
			$(mSpan).attr("data-format-selected", "false");
		});
		/* mark target as selected */
		targetComponent.attr("data-format-selected", "true");
	}
};


/** All AVAIL. **/
var getAllAvailabilities = function() {
	var $relatedLanguages = $("product-availabilities").find("[js-target='format-language']");
	var $data = $relatedLanguages.attr("data-availabilities");
	var $parsedJSON = JSON.parse($data);
	return $parsedJSON;
};

var updateBTaddToCartElement = function(selectedAvailability) {
	/* update better together cart component  */
	var BTaddToCartItems = $("body").find("add-to-cart[data-group-name='better-together']");
	var BTfirstAddToCart = $(BTaddToCartItems[0]);
	var url = BTfirstAddToCart.data("productUrl").split("/");
	BTfirstAddToCart.attr("data-product-price", selectedAvailability.listPrice);
	BTfirstAddToCart.attr("data-product-id", selectedAvailability.availabilityId);
	BTfirstAddToCart.attr("data-product-url", "/product/" + url[2] + "/" + selectedAvailability.availabilityId);
	BTfirstAddToCart.attr("data-type", utils.getProductType(selectedAvailability.availabilityId));
	BTfirstAddToCart.attr("data-format", selectedAvailability.format);
	BTfirstAddToCart.attr("data-product-availability-status", selectedAvailability.status);
	/* update BT first content format */
	var btFormatElement = $("body").find("[js-target='bt-content-format']");
	$(btFormatElement[0]).html(selectedAvailability.format);
	updateBTGroupPrice();
};

var updateBTGroupPrice = function() {
	var totalPrice = 0;
	var btCheckElems = $("[js-target='checkbox-better-together']");
	if (btCheckElems) {
		btCheckElems.each(function(i, element) {
			if ($(element).is(":checked")) {
				var itemPrice = parseFloat($(element).closest("add-to-cart").data("product-price"));
				totalPrice += itemPrice;
			}
		});
	}
	$("[js-target='price-better-together']").html(totalPrice.toFixed(2));
};

var updateSaveShareStreamElement = function(selectedAvailability) {
	var StreamItems = $("[js-target='save-share']");
	var url = $(StreamItems[0]).data("url").split("/");
	$.each(StreamItems, function(i, StreamItem) {
		$(StreamItem).attr("data-id", selectedAvailability.availabilityId);
		$(StreamItem).attr("data-url", "/product/" + url[2] + "/" + selectedAvailability.availabilityId);
	});
};

mixinComponent(ProductAvailabilities, "product-availabilities");
