
import $ from "jquery";
import common from "../common";
import _each from "lodash-amd/modern/collections/forEach";

// Create a jQuery collection from this object, allowing us to proxy events.
var $exports = $(exports);

/**
 * Switch from one state to another.  Updates the DOM and memory.
 *
 * @param {string} previous - state to phase out.
 * @param {string} current - state to phase in.
 */
var transitionStates = function(previous, current) {
	// Remove all existing classes from previous state.
	this.rootElement.removeClass(previous.replace(/:/g, " "));

	// Add new classes for current state.
	this.rootElement.addClass(current.replace(/:/g, " "));

	// Set the previous state (useful for back);
	this.previous = previous;

	// Set the current state.
	this.current = current;

	// Trigger a state change event.
	this.trigger("transition", [previous, current]);
};

// Document root element.
exports.rootElement = $("html");

// By default previous state is empty.
exports.previous = "";

// Set the current state to initialize with the default or empty.
// Query the DOM for state on the global-nav, if that exists, use that as
// the default, otherwise, use the common state or nothing
var htmlState = exports.rootElement.hasClass("subscribers") ? common.SUBSCRIBER_STATE : exports.rootElement.hasClass("registered") ? common.REGISTERED_STATE : undefined;
exports.current = $("global-nav").data("state") || htmlState || common.DEFAULT_STATE || "";

// Borrow remaining jQuery event functions.
exports.on = $.proxy($exports.on, $exports);
exports.once = $.proxy($exports.one, $exports);
exports.off = $.proxy($exports.off, $exports);
exports.trigger = $.proxy($exports.trigger, $exports);

exports.viewportDimensions = {
	width: window.innerWidth,
	height: window.innerHeight
};

/**
 * Update the current state in memory and markup.
 *
 * @param {string} state - The current state of the page.
 */
exports.set = function(state) {
	// State is a required argument.
	if (typeof state !== "string") {
		throw new Error("Missing state to set.");
	}

	// Update the document root to reflect the new state.
	transitionStates.call(this, this.current, state);
};

/**
 * Unsets a state point (along with all nested) or all states.
 *
 * @param {string} state - Optional state.
 */
exports.unset = function(state) {
	// Ensure state always has a value.
	state = state || "";

	var previous = this.current.split(":");

	// Truncate the state from the index of the passed state.
	if (previous.indexOf(state) > -1) {
		state = previous.slice(0, previous.indexOf(state)).join(":");
	}
	// A valid state to unset is required otherwise we are in an invalid state.
	else if (state) {
		throw new Error("Attempted to unset an invalid state.");
	}

	// Transition the states.
	transitionStates.call(this, this.current, state);
};

/**
* evaluate whether the viewport width is within the mobile
* dimension
*
* @param {number} breakpointWidth - the width of mobile
* @return {boolean}
*/
exports.isMobile = function(breakpointWidth) {
	var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
		mobileWidth = breakpointWidth || (common.MOBILE_BREAKPOINT);
	return width <= mobileWidth;
};

/**
* Evaluate whether the user is on the homepage, and hero is visible.
* Use to determine weather to open the nav or leave it alone
*
* @return {boolean}
*/
exports.homeHeroVisible = function() {
	var isVisible = false,
		$window = $(window),
		$container = $("#home-poster-container");
	if ($container.length) {
		isVisible = $window.scrollTop() < ($container.offset().top + $container.outerHeight());
	}
	return isVisible;
};

/**
* function that will evaluate whether there is space available
* to display the flyout (or any module) at the bottom of the click
* or at a better position.
*
* @param {number} posTop - top position of the element
* @param {number} posLeft - left position of the element
* @param {number} moduleHeight - height of the given element
* @param {number} moduleWidth - width of the given element
*
* @return {object}
*/
exports.getModulePosition = function (posTop, posLeft, moduleHeight, moduleWidth) {
	var modulePosition = {
		top: "",
		left: "",
		move: "0",
		className: "pointer-top-left",
		adjustPos: false
	};

	var verticalPosition = this.viewportDimensions.height + $(window).scrollTop();
	var horizontalPosition = this.viewportDimensions.width + $(window).scrollLeft();
	// if the the flyout fits in the available viewport above, then place it at the top
	// of the element
	if (moduleHeight < posTop - $(window).scrollTop()) {
		modulePosition.top = posTop - moduleHeight;
		modulePosition.left = posLeft;
		modulePosition.className = "pointer-bottom-left";
		modulePosition.adjustPos = true;
	} else if (posTop + moduleHeight > verticalPosition) {
		// record how much the window is off by
		modulePosition.move = posTop + moduleHeight - verticalPosition;
	} else {
		// If it doesn't fit into either of these clauses then let's just render where the user clicked. 
		modulePosition.adjustPos = true;
		modulePosition.top = posTop;
		modulePosition.left = posLeft;
	}

	// if the element extends beyond the viewport's Y axis, place
	// it on the left of the element.
	if (posLeft + moduleWidth > horizontalPosition) {
		// does it fit on the left?
		if (posLeft < moduleWidth) {
			modulePosition.left = posLeft / 2;
			modulePosition.className = "pointer-top-center";
		} else {
			modulePosition.left = posLeft - (moduleWidth * 0.75);
			modulePosition.className = "pointer-top-right";

		}
		modulePosition.top = "";
		modulePosition.adjustPos = true;
	}

	return modulePosition;
};

/**
* function that will center a flyout in the middle of the current viewport
* taking into consideration flyout height/width and whether the 
* positioning is fixed or not
*
* @param {number} moduleHeight - height of the given element
* @param {number} moduleWidth - width of the given element
* @param {boolean} includeScroll - include scrollTop/scrollLeft in calculation
* (if we're fixed positioning e.g share flyouts - we don't want the scroll values included)
* @return {object} 
*/
exports.centerModulePosition = function(moduleHeight, moduleWidth, includeScroll) {
	let modulePosition = {
		adjustPos: false,
		top: "",
		left: ""	
	};

	// If the module passed in has a vertical height larger than the current viewport
	// let's render it 1/3rd from the top of the viewport and allow the rest to render below
	if(moduleHeight >= this.viewportDimensions.height) {
		modulePosition.top = this.viewportDimensions.height / 3; 
	} else {
		// If the module can safely fit on the viewport, let's render it
		// in the middle of the current visible viewport.
		modulePosition.top = (this.viewportDimensions.height - moduleHeight) / 2;
	}

	// Include scroll - needed for save flyouts/other non-fixed positioning flyouts
	if(includeScroll) {
		modulePosition.top += $(window).scrollTop();
		modulePosition.adjustPos = true;
	}

	// Viewport width minus the flyout width divided by two gives us the center of the screen to start rendering at.
	modulePosition.left = (this.viewportDimensions.width - moduleWidth) / 2;

	return modulePosition;
}

// When the DOM is ready, transition the previous and current.
$(function() {
	transitionStates.call(exports, exports.previous, exports.current);
});

var allComponents = [];

exports.addInitializer = function(component) {
	allComponents.push(component);
};

exports.initializeElement = function(el) {
	_each(allComponents, function(component) {component.activateAll(el); });
	return el;
};
