
import common from "../../common";
import request from "../request";
var BASE_URI = common.API + "user/list/url/";

function parseURI (listName) {
	return BASE_URI + listName;
}

/**
 * get
 * returns the url for this reading list
 * @param {String} name
 * @returns {*}
 */
exports.get = function (name) {
	// this uses a different endpoint
	return request.ajax("get", parseURI(name));
};
