/**
 * @module components/topic-circulator/popular-topic/popular-topic
 */

import mixinComponent from "../../../mixins/component";
import _bind from "lodash-amd/modern/functions/bind";

// Models.
import user from "../../../models/user";

// Services.
import personalization from "../../../services/personalization";

/**
 * Represents a popular topic component.
 *
 * @constructor
 * @public
 */
export default function PopularTopic($el) {
	this.initializeAsComponent($el);

	// Always start off refreshed.
	this.refresh();
}

PopularTopic.prototype = {
	events: {
		"click [js-target='popular-topic-refresh']": "handleClick"
	},

	/**
	 * Setup the custom events for this component.  The top level key
	 * represents a scoped selection.  Empty string key is the component
	 * itself.
	 */
	handleClick: function(evt) {
		evt.preventDefault();
		evt.stopPropagation();

		var completeAnimation = _bind(function() {
			window.clearTimeout(this.timeout);

			// Refresh this component.
			this.$el.removeClass("refresh-animation");
		}, this);

		// Add a fancy bounce effect.
		this.$el.addClass("refresh-animation");

		// Wait for the event to complete, and then remove.
		this.$el.one("animationend", completeAnimation);

		// For good measure on older browsers or ones that don't emit the event.
		this.timeout = window.setTimeout(completeAnimation, 500);

		// Reset the follow subscribe disabled class.
		this.$("follow-subscribe").removeClass("disabled");

		// Refresh a little after the animation is in progress.
		window.setTimeout(_bind(function() {
			this.$el.data("component").refresh();
		}, this), 0);
	},

	/**
	* function that will fetch a new topic from the personalization
	* service and renders.
	*/
	refresh: function() {
		this.render(personalization.getPopularTopic());
	},

	/**
	 * Renders a new popular topic.
	 *
	 * @public
	 * @param {Object} context - Object to render.
	 */
	render: function(context) {
		this.$("[js-target='link']").attr("href", context.link).text(context.title);
		this.$("follow-subscribe").attr("data-name", context.title);
		this.$("follow-subscribe").data("name", context.title);
		this.$("[js-target='body']").text(context.body);

		// Ensure the disabled class is properly set.
		if (user.getFollowing().indexOf(context.title.toUpperCase()) > -1) {
			this.$("follow-subscribe").addClass("disabled");
		}
	}
};

mixinComponent(PopularTopic, "popular-topic");

