
import $ from "jquery";
import mixinComponent from "../../mixins/component";

export default function ShowMore($el) {
	this.initializeAsComponent($el);
}

ShowMore.prototype = {
	events: {
		"click [js-target='show']": "showMore"
	},
	showMore: function(ev) {
		ev.preventDefault();
		this.$("[js-target='more']").removeClass("hide-small");
		$(ev.target).remove();
	}
};

mixinComponent(ShowMore, "show-more");

