import React, { Component } from "react";

function withModal(WrappedComponent) {
	return class extends Component {
		constructor(props) {
			super(props)
		}
		
		render() {
			return (
				<div class="hbr-modal-overlay active">
					<div class="hbr-modal-wrapper">
						<div class="hbr-modal">
							<div class="hbr-modal--header">
								<a href="#" onClick={(ev) => {ev.preventDefault(); this.props.onClose}} aria-label="Close">
									<img class="hbr-modal--close" 
									src="/resources/css/images/close.svg" />
								</a>
							</div>
							<div class="hbr-modal--body">
								<WrappedComponent {...this.props} />
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

export default withModal;
