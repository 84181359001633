
import $ from "jquery";
import interactionService from "../../../services/hbr/interaction";
import discard from "../discard/discard";
import _map from "lodash-amd/modern/collections/map";
var $component = $(exports);

var discardItems = null;

/**
 * render
 * @returns {*}
 */
exports.render = function (flyoutData) {
	return discard.render(flyoutData);
};

/**
 * getData
 * Gets necessary data from the DOM
 * @returns {Object}
 */
exports.getData = function () {
	discardItems = _map($("stream-list").find("[js-target='bulk-action-checkbox']:checked"), function(el) {
		var item = {
			$element: $(el),
			data: {
				id: $(el).attr("id")
			}
		};
		if ($(el).data("list")) {
			item.data.list = $(el).data("list");
		}
		return item;
	});
	return discardItems;
};

var removeFromSaves = function (e) {
	e.preventDefault();
	e.stopImmediatePropagation();
	$.each(discardItems, function () {
		var $streamItem = this.$element.closest(".stream-entry");

		if (this.data.list) {
			var request = {
				"name": this.data.list,
				data: {
					"id": this.data.id
				}
			};
			interactionService.request("listItem", "delete", request).then(function () {
				// remove the row
				interactionService.hideStreamLabel($streamItem);
				$streamItem.remove();
				const $bulkUi = $("[js-target='bulk-discard-flyout'], [js-target='bulk-organize-flyout']");
				$bulkUi.prop("disabled", $("[js-target='bulk-action-checkbox']:checked").length === 0);
			});
		} else {
			interactionService.request("saved", "delete", this.data).then(function () {
				// remove the row
				interactionService.hideStreamLabel($streamItem);
				$streamItem.remove();
				const $bulkUi = $("[js-target='bulk-discard-flyout'], [js-target='bulk-organize-flyout']");
				$bulkUi.prop("disabled", $("[js-target='bulk-action-checkbox']:checked").length === 0);
			});
		}
	});

	$component.trigger("destroy", {message: discardItems.length + (discardItems.length === 1 ? " item" : " items") + " removed from My Library"});
};

exports.bindInteractions = function () {
	$("[js-target='yes']").one("click", removeFromSaves);
	$("[js-target='cancel']").one("click", function() {
		$component.trigger("destroy");
	});

};
