import React, { Component, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import FieldGroup from '../Form/FieldGroup';
import CaptChaField from '../Form/CaptchaField';
import SubmitButton from '../Form/SubmitButton';
import { SignInRequiredEmailField, RequiredPasswordField } from "../Form/InputField";
import ConditionalLoader from "../Commons/ConditionalLoader";
import CaptchaService, {CaptchaActions, CaptchaKeys} from "../../services/captcha-service";
import signInStore from "../../stores/authentication/sign-in";
import captchaStore from "../../stores/authentication/captcha";
import SignIn from "../../components/sign-in/sign-in";
import { trigger } from "../../support/utilities";
import user from "../../models/user";
import common from "../../common";
import utilities from "support/utilities";

function SignInForm(props) {
	const isValid = signInStore((state) => state.valid)
	const form = signInStore((state) => state.form)
	const isLoading = signInStore((state) => state.isLoading)
	const setLoading = signInStore((state) => state.setLoading)
	const updateError = signInStore((state) => state.updateError)
	const reset = signInStore((state) => state.reset)
	const {widgetID, updateValue} = captchaStore((state) => state)
	const authenticate = (evt) => {
		evt.preventDefault();
		setLoading(true)
		user.authenticate(form.email, form.password, form.captcha)
			.then(function(user) {
				SignIn.signInPromise.resolve(user);
				if (props.fromPaywall == "true") {
					trigger("metrics:gbbPWSigninSuccess");
				} else {
					trigger("metrics:signin_success", {user: user});
				}
				$("body").trigger("piano:signin-success");
				$("[js-target='email']").removeClass("error-border");
				setLoading(false)
				//reload the page
				window.location.reload();
				props.onClose();
			})
			.catch(function(data) {
				var triesLeftMsg = ". You have %s tries left.";
				var errorMessage = "You have entered an invalid email and password combination. Please try again";
				var extendedErrorMessage = "";

				if (data.responseJSON.hasOwnProperty(common.LOGIN_TRIES_LEFT)) {
					let message = triesLeftMsg.replace("%s", data.responseJSON.tries_left)
					if(data.responseJSON.tries_left == 1) {
						message = message.replace("tries", "try")
					}
					updateError(
						"password",
						errorMessage + message,
						["password"]
					)
				} else if (data.responseJSON.hasOwnProperty(common.ACCOUNT_LOCKOUT_DURATION)){
					let accountLockTime = utilities.getUnlockAccountTime(data.responseJSON.lockout_duration)
					updateError(
						"password",
						<span>
							Your account is blocked, please wait {accountLockTime} to unlock and try again. For immediate assistance, please contact <a href='/subscriber-help#contact-customer-service'>Customer Service</a>.
						</span>,
						["password"]
					)
				} else {
					updateError(
						"password",
						"You have entered an invalid email and password combination. Please try again.",
						["password", "email"]
					)
				}
				setLoading(false)
				$("[js-target='email']").addClass("error-border");
				$("body").trigger("piano:signin-failure");
				if (props.fromPaywall == "true") {
					trigger("metrics:gbbPWSigninFail");
				} else {
					trigger("metrics:signin_fail");
				}
				
				CaptchaService.isCaptchaEnabled()
					.then((isEnabled) => {
						if (isEnabled) {
							grecaptcha.enterprise.ready(() => {
								grecaptcha.enterprise.execute(CaptchaKeys.score, {action: CaptchaActions.REGISTER_MODAL});
							});
							grecaptcha.enterprise.reset(widgetID);
							updateValue("captchaToken", null)
							updateError(
								"captcha", 
								"This field is required.",
								["captcha"]
							)
						}
					});
			})
	}
	useEffect(reset, []);
	return (
		<form class="form auth-content" onSubmit={authenticate} id="modalSignInForm">
			<FieldGroup withLabel="Email" for="email">
				<SignInRequiredEmailField
					id="emailAddress"
					name="email"
					js-target='email'
					{...props["email"]}
					store={signInStore}
					/>
			</FieldGroup>
			<FieldGroup withLabel="Password" for="password">
				<RequiredPasswordField
					id="password"
					name="password"
					{...props["password"]}
					store={signInStore}
					showRequirments={false}
					/>
			</FieldGroup>
			<FieldGroup>
				<CaptChaField 
					id="captcha"
					name="captcha"
					{...props["captcha"]}
					store={signInStore}
					/>
			</FieldGroup>
			<FieldGroup>
				<ConditionalLoader isEnabled={isLoading}>
					<SubmitButton
						value="Sign In"
						enabled={isValid}
					/>
				</ConditionalLoader>
			</FieldGroup>
		</form>
	)
}

export default SignInForm
