
// Dependencies
import _shuffle from "lodash-amd/modern/collections/shuffle";

// Services
import storageService from "./localstorage-util";

var queue = [];

/**
 * Returns a popular topic for the homepage.
 */
exports.getPopularTopic = function() {
	if (!queue.length) {
		queue = _shuffle(storageService.getCache("pop_topics"));
	}
	return queue.pop();
};
