
import $ from "jquery";

// Sharing a lot of features with save.
import save from "../save/save";

var destroy = function () {
	$(exports).trigger("destroy", {message: "Folders Updated"});
};

/**
 * Pass through to save flyout
 * @returns {*|Object}
 */
exports.render = function () {
	return save.render();
};

/**
 * getData
 * Pass through to save flyout
 * @param {Object} $element
 * @returns {Object}
 */
exports.getData = function ($element) {
	return save.getData($element); // load up save data
};

exports.bindInteractions = function () {
	// Bind the save interactions
	save.bindInteractions();

	// We pass off lots of functionality to the save flyout
	// But the save flyout destroy event won't destroy this flyout
	$(save).on("destroy", function() {
		destroy();
	});
};
