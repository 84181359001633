
import $ from "jquery";
import interactionService from "./hbr/interaction";
import _filter from "lodash-amd/modern/collections/filter";
import _each from "lodash-amd/modern/collections/forEach";

// Services
import storageService from "./localstorage-util";

/**
 * function that will evaluate the carts modified date. If same, cached cart
 * should be used. If different, request new cart
 *
 * @param {object} cartInfo
 *
 * @return {boolean} - cart has been modified or not
 */
var isCartModified = function(cartInfo) {
	var cachedCart = storageService.getCache("cart");
	if (cartInfo) {
		if (cachedCart) {
			return cartInfo.modifiedTime !== cachedCart.modifiedTime;
		}

		return true;
	} else {
		getModifiedDate().done(function(data, status, response) {
			if (cachedCart) {
				return response.getResponseHeader("Last-Modified") !== cachedCart.modifiedTime;
			}

			return true;
		});
	}
};

/**
 * function that will get the last modified date on a cart
 *
 * @return {promise.object}
 */
var getModifiedDate = function() {
	return interactionService.request("cart", "checkFreshness");
};

/**
 * function that will create cart object to be passed to
 * the server to be added
 *
 * @param {object} items
 *
 * @return {array} array of updateCart objects
 */
var createCartItem = function(items) {
	var tempArr = $.isArray(items) ? items : [items];
	var updateCart = new UpdateCart(deduceReferralCode(items));
	var newItems = tempArr.map(function(item) {
		return new CartItem(item.itemid, item.productId, parseFloat(item.productPrice), parseInt(item.quantity), item.type);
	});

	return updateCart.addItems(newItems);
};

/**
 * class that will create a single cartItem
 *
 * @param {string} itemId - id of the item in the cart
 * @param {string} productId - id of the product placed in the cart
 * @param {number} listPrice - listPrice of the item
 * @param {number} quantity - value of the quantity of item in the cart
 * @param {string} type - type of item
 */
var CartItem = function(itemId, productId, listPrice, quantity, type) {
	this.itemid = itemId || "";
	this.productId = productId;
	this.listPrice = listPrice;
	this.quantity = quantity;
	this.type = type;
};

/**
* Class that will create an object to update the cart. This
* object contains an empty array of CartItem objects
*
*/
var UpdateCart = function(referral) {
	this.items = [];
	if (referral) {
		this.referral = referral;
	}
};

var deduceReferralCode = function (items) {
	for (var i = 0, l = items.length; i < l; i++) {
		if (items[i].referral) {
			return items[i].referral;
		}
	}
	return null;
};

/**
* function that will add the CartItem object
* to the UpdateCart object
*
* @param {array} items
*
* @return {object} UpdateCart
*/
UpdateCart.prototype.addItems = function(items) {
	this.items = items;

	return this;
};

/**
* class that will create an address
*
* @param {string} id
* @param {string} firstName
* @param {string} lastName
* @param {string} address1
* @param {string} address2
* @param {string} city
* @param {string} state
* @param {string} postalCode
* @param {string} countryCode
*
*/
var Address = function (id, firstName, lastName, address1, address2, city, state, postalCode, countryCode) {
	this.id = id;
	this.firstName = firstName;
	this.lastName = lastName;
	this.address1 = address1;
	this.address2 = address2;
	this.city = city;
	this.state = state;
	this.postalCode = postalCode;
	this.countryCode = countryCode;
};

/**
* function that will massage the key to the type that
* can be used
*
* @param {string} key
*
* @return {string} key
*/
var massageAddressKey = function(key) {
	key = key.replace("shipping", "");
	key = key.replace("billing", "");

	return key.charAt(0).toLowerCase() + key.slice(1);
};

/**
 * Abstracted function that will make the ajax call for the
 * shopping experience.
 *
 * @param {string} method - method of GET, POST, PUT, or DELETE
 * @param {object} contentObject - object to be passed in
 *
 * @return {promise.object}
 */
exports.shoppingAjax = function(method, contentObject) {
	return interactionService.request("cart", method, contentObject);
};

/**
 * function that will get all the cart information
 *
 * @return {object} - cartInfo
 */
exports.getCart = function() {
	if (isCartModified()) {
		this.shoppingAjax("GET").done(function(response) {
			if (response.status === 200) {
				var cartInfo = response.cart;
				storageService.setCache("cart", cartInfo);

				return cartInfo;
			}
		});
	} else {
		return storageService.getCache("cart");
	}
};

/**
 * function   save the referralCode to include it in the url when a request is made (see interaction.request())
 *
 * @param {string} referralCode - The referral code associated with the item being added to the cart.
 */
exports.setReferralCode = function (referralCode) {
	interactionService.setReferralCode(referralCode);
};

/**
 * function that will add an item to the cart
 *
 * @param {Object} cartItem - The item to add to the cart.
 *
 * @return {object} cartInfo - new cart object
 */
exports.addToCart = function(cartItem) {
	var updateThisItem = createCartItem(cartItem);

	return this.shoppingAjax("post", updateThisItem)
		.done(function(response) {
			var cartInfo = response.cart;
			storageService.setCache("cart", cartInfo);
			return cartInfo;
		}).fail(function(error) {
			return error;
		});
};

/**
 * function that will remove given items from the cart
 *
 * @param {array} cartItems - ids of items to remove
 *
 * @return {object} cartInfo - new cart object
 */
exports.removeFromCart = function(cartItems) {
	var cartItemsToRemove = createCartItem(cartItems);

	return this.shoppingAjax("delete", cartItemsToRemove)
		.then(function(response) {
			var cartInfo = response.cart;
			storageService.setCache("cart", cartInfo);
			return cartInfo;
		});
};

/**
 * function that will update the cart with changed information
 *
 * @param {array} cartItems - array of cartItems to be updated
 *
 * @return {object} cartInfo - new cart object
 */
exports.updateCart = function(cartItems) {
	var cartItemsToUpdate = createCartItem(cartItems);

	return this.shoppingAjax("POST", cartItemsToUpdate)
		.then(function(response) {
			var cartInfo = response.cart;
			storageService.setCache("cart", cartInfo);
			return cartInfo;
		});

};

/**
* function that will get the cartItem from the
* cart object based on the given itemId
*
* @param {object} cart
* @param {string} itemId
*
* @return {object cartItem}
*/
exports.getUpdatedItem = function (cart, itemId) {
	var updatedItemArr = _filter(cart, function (cartItem) {
		return cartItem.itemid === itemId;
	});

	return updatedItemArr.length > 0 ? updatedItemArr[0] : null;
};

/**
* function that will get the estimated shipping and handling
* cost for the current cart
*
* @param {object} shippingInfo
*
* @return {object} cart
*/
exports.getShippingEstimate = function (shippingInfo) {
	return this.shoppingAjax("shippingEstimate", shippingInfo)
		.then(function (response) {
			var cartInfo = response.cart;
			storageService.setCache("cart", cartInfo);
			return cartInfo;
		});
};

/**
* function that will create an address object for consumption
*
* @param {array} address - individual address information are stored in an
* array of object with the name and value of the information
*
* @return {Address} addressObj
*/
exports.createAddressObject = function(address) {
	var addressObj = new Address();

	_each(address, function(info) {
		var thisKey = Object.keys(info).join("");
		var newKey = massageAddressKey(thisKey);
		addressObj[newKey] = info[thisKey];
	});

	return addressObj;
};

/**
* function that will convert the array of payment info
* to an object
*
* @param {array} payment
*
* @return {object} payment
*/
exports.createPaymentObject = function(payment) {
	var tempObj = {};

	_each(payment, function(val) {
		var thisKey = Object.keys(val).join("");
		tempObj[thisKey] = val[thisKey];
	});

	return tempObj;
};

/**
* function that will post the shipping, billing and payment
* information
*
* @param {object} shippingPaymentData
*
* @return {promise.object}
*/
exports.postShippingPayment = function(shippingPaymentData) {
	return interactionService.request("cart", "shippingPayment", shippingPaymentData);
};

/**
* function that will return the availble products for a given productId
*
* @param {string} productId
*
* @return {array}
*/
exports.getAvailableProducts = function(productId) {
	return interactionService.request("cart", "productAvailability", productId);
};

/**
* function that will get the states or provinces for
* US and canada
*
* @param {string} countryCode
*
* @return {promise.object}
*/
exports.getStates = function(countryCode) {
	return interactionService.request("cart", "statesProvinces", countryCode);
};

/**
 * Submit the current order
 * @return {promise.object}
 */
exports.submitOrder = function() {
	return interactionService.request("cart", "submitOrder");
};

/**
 * Update the shipping method on the current order
 * @return {promise.object}
 */
exports.updateShippingMethod = function(shippingMethod) {
	return interactionService.request("cart", "updateShippingMethod", shippingMethod);
};

/**
 * Apply the promo code
 * @return {promise.object}
 */
exports.applyPromoCode = function(promoCode) {
	return interactionService.request("cart", "applyPromoCode", promoCode);
};
