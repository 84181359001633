// Components
import mixinComponent from "../../mixins/component";
import user from "../../models/user";
import SignInModal from "../../react/AuthModal/Modal";

let currentBanner;
let targetPosition;
let globalNavHeight;
let articleToolsOffset;
let articleSummaryOffset;
let firstParagraphOffset;
let firstParagraphOffsetOld;
let articleTemplateParagraphOffset;
let podcastPageOffset;
let isArticleRedesign;
let subscriberIsLoggedIn = false;
let isAscend = false;

export default function PersistentBanner($el) {
	this.initializeAsComponent($el);
	currentBanner = this;
	
	window.addEventListener("paywall:render", this.updateBanner);
	isAscend = document.getElementsByClassName("ascend-head-container").length > 0;

	user.authPromise.then(function(authUser) {
		if(authUser.subscriber && authUser.subscriber.active) {
			subscriberIsLoggedIn = true;
			this.simpleClose();
		}
		if(isAscend) {
			this.simpleClose();
		}
	}.bind(this));

	// add external event handling for Piano
	let tpGlobalObject = window["tp"] || [];
	tpGlobalObject.push(["addHandler", "checkoutCustomEvent", function(event) {
		switch(event.eventName) {
		case "counter-cta":
			window.open("/subscriptions?ab=redstrip-subtout&tpcc=houseads.site.redstrip-subtout","_blank");
			break;
		case "counter-close-button":
			currentBanner.simpleClose();
			break;
		}
	}]);


}

PersistentBanner.prototype = {

	globalEvents: {
		"keyup": "escClose"
	},

	events: {
		"click [js-target='sign-in-register--register']": "renderRegisterModal",
		"click [js-target='close-persistent-banner']": "close",
		"mousedown [js-target='close-persistent-banner']": "preventFocusLost",
		"mousedown [js-target='persistent-banner--focus']": "preventFocusLost"
	},

	renderRegisterModal: ev => {
		ev.preventDefault();
		SignInModal.render("register");
	},

	setToHidden: ev => {
		currentBanner.$el.find("section").addClass("do-not-show");
	},

	close: ev => {
		ev.preventDefault();
		ev.stopPropagation();
		currentBanner.simpleClose();
	},

	simpleClose: ev => {
		currentBanner.$el.find("section").addClass("deactivated");
		currentBanner.$el.find("section").attr("aria-hidden", "true");
		setTimeout(currentBanner.setToHidden, 1000);
	},

	escClose: ev => {
		if (ev.key === "Escape") {
			currentBanner.close(ev);
		}
	},

	preventFocusLost: ev => {
		ev.preventDefault();
	},

	open: () => {
		currentBanner.$el.find("section").removeClass("do-not-show");
		currentBanner.$el.find("section").removeClass("deactivated");
		currentBanner.$el.find("section").attr("aria-hidden", "false");
	},

	calculateMetrics: () => {
		globalNavHeight = document.querySelector(".top-header--bar").offsetHeight;
		isArticleRedesign = document.getElementsByClassName("article-content-flex2019").length > 0;

		if (isArticleRedesign) {
			// JQuery offset calculation is necessary here due that the native implementation of VanillaJS is dependent on the offset parent
			articleSummaryOffset = $(".article-summary").first().offset();
			firstParagraphOffset = $(".article-body > p").first().offset();
			articleTemplateParagraphOffset = $(".article-content-flex2019 p").first().offset();
			if (articleSummaryOffset) {
				targetPosition = articleSummaryOffset.top - globalNavHeight;
			} else if (firstParagraphOffset) {
				targetPosition = firstParagraphOffset.top - globalNavHeight;
			} else if (articleTemplateParagraphOffset) {
				targetPosition = articleTemplateParagraphOffset.top - globalNavHeight;
			}
		} else {
			articleToolsOffset = $(".article-tools").offset();
			firstParagraphOffsetOld = $(".article-first-row > p").offset();
			podcastPageOffset = $(".podcast-post__main").offset();
			if (articleToolsOffset) {
				targetPosition = articleToolsOffset.top - globalNavHeight;
			} else if (firstParagraphOffsetOld) {
				targetPosition = firstParagraphOffsetOld.top - globalNavHeight;
			} else if (podcastPageOffset) {
				targetPosition = podcastPageOffset.top - globalNavHeight;
			}
		}
	},

	calculateOpenValidator: () => {
		currentBanner.calculateMetrics();
		currentBanner.openValidator();
	},

	openValidator: ev => {
		if (targetPosition <= window.pageYOffset && !subscriberIsLoggedIn && !isAscend) {
			currentBanner.open();
			window.removeEventListener("scroll", currentBanner.calculateOpenValidator);
		}
	},

	updateBanner: ev => {
		// Only register load and scroll events after updateBanner is called
		window.addEventListener("scroll", currentBanner.calculateOpenValidator);
		window.addEventListener("load", currentBanner.calculateOpenValidator);
	}
};

mixinComponent(PersistentBanner, "persistent-banner");
