
// Dependencies
import $ from "jquery";
var ua = window.navigator.userAgent;
import "moment";
import "moment-duration-format";
import {removeCache} from "../services/localstorage-util";

// Services
import "../services/smooth-scrolling";

//Update the user state
import user from "../models/user";
import state from "../services/state";
import FollowSubscribe from "../components/follow-subscribe/follow-subscribe";

var trigger = require("../support/utilities").trigger;

FollowSubscribe.activateAll();

if (state.current.indexOf("guest") !== 0) {
	user.fetch().then(() => {
		FollowSubscribe.updateSubscriptions(user.getNewsletters());
		FollowSubscribe.updateFollowing(user.getFollowing());
		FollowSubscribe.updateMarketingCampaignSubscriptions(user.getMarketingCampaigns());
		trigger("follow:updated");
	});
} else {
	removeCache("user");
}

// Loading Subscriber On-boarding from PIANO
import PianoMessageFlow from "./piano/message-flow.js";

if (document.getElementById("subscriber-onboarding")) {
	console.log("display")
	var tpObject = window["tp"] || [];
	var pianoOfferingFlow = new PianoMessageFlow(tpObject);

	// Placeholder function in order to attend messages from piano
	// with ID subscriber-onboarding
	pianoOfferingFlow.handle();

}

// Gift redemption handling
import initializeGiftRedeemFlow from "../components/piano/gift-redeem-flow.js";
if (document.location.href.indexOf("voucher_code=") > 0) {
	initializeGiftRedeemFlow(window["tp"]);
}

// Polyfill Function.prototype.bind` for the sake of PhantomJS tasks and old WebKit browsers.
import "../components/bind-polyfill/bind-polyfill";

// IE11 polyfills
import "../support/polyfills/includes-polyfill";  /* String.prototype.includes */
import "../support/polyfills/remove-polyfill";  /* childNode.remove */
import smoothscroll from "../support/polyfills/smoothscroll-polyfill";  /* window.scrollTo */
smoothscroll.polyfill();

import svg4everybody from "svg4everybody";

svg4everybody({polyfill: true});

// Include commonly found components that will be shared amongst all pages.
import GlobalNav from "./global-nav/global-nav";
GlobalNav.activateAll();
import Header from "./header/header";
Header.activateAll();
import PersistentBanner from "./persistent-banner/persistent-banner";
PersistentBanner.activateAll();
import StreamSection from "../components/stream-section/stream-section";
StreamSection.activateAll();
import Flyout from "../components/flyout/flyout";
Flyout.activateAll();
import FindIssue from "../components/find-issue/find-issue";
FindIssue.activateAll();
import AddToCart from "../components/add-to-cart/add-to-cart";
AddToCart.activateAll();
import ShowMore from "../components/show-more/show-more";
ShowMore.activateAll();

import FooterMenu from "../components/footer-menu/footer-menu";
FooterMenu.activateAll();

import ProductMenu from "../components/product-menu/product-menu";
ProductMenu.activateAll();

import ProductAvailabilities from "../components/product-availabilities/product-availabilities";
ProductAvailabilities.activateAll();

import ProductCarousel from "../components/product-carousel/product-carousel";
ProductCarousel.activateAll();

import "fixedsticky";
FixedSticky.tests.sticky = false;
$(".fixedsticky").fixedsticky();

const smallScreenWidth = 767;
const mediumScreenWidth = 1025;

//Load responsive images polyfill
require("picturefill");

// Temp fix for iOS8 until we hear from filament group regarding fixfix.js
// pull request.
// look for Safari in iOS8
if (ua.match(/iPhone OS 8/) && ua.indexOf("AppleWebKit") > -1) {
	if (window.removeEventListener) {
		window.removeEventListener("scroll", window.FixedFixed, false);
	} else {
		window.detachEvent("onscroll", window.FixedFixed);
	}
}

if (ua.toLowerCase().match(/safari/) && ua.toLowerCase().match(/version\/6/)) {
	$(".safari-6-message").show();
}

if (window.location.search.match(/printMe/)) {
	window.print();
}

import HelpBox from "components/help-box/help-box";
if (!state.isMobile()) {
	HelpBox.show();
}

const SCROLL_TOP_HIDE_NAV = 100;

// Check for an ad banner component on top of the page for the rest of the templates
if ($("div[data-params='region=openx;location=leaderboard']").length > 0 && $("div[data-params='region=openx;location=leaderboard']").outerHeight() > 0) {
	let topbar, globalNav, readingListTrigger, body, initialPos, topbarFixed = false, topHeaderHeight;

	$(window).on("scroll", function (e) {
		if (!topbar) {
			if (ua.toLowerCase().match(/chrome|safari/)) {
				body = document.scrollingElement || document.getElementsByTagName("body")[0];
			}
			else {
				body = document.documentElement;
			}

			topbar = body.getElementsByClassName("sticky-top-header")[0];
			globalNav = $(".global-nav")[0];
			topHeaderHeight = $(".top-header--bar").outerHeight();
			readingListTrigger = $(".top-header .right.trigger");
		}

		if (topbarFixed) {
			if ($("div[data-params='region=openx;location=leaderboard']").outerHeight() === 0 || $(".article-content-flex2019").length > 0 || $(".insight-center-1").length > 0) {
				if ($(window).scrollTop() <= 100) {
					globalNav.removeAttribute("style");
					$.event.trigger({
						type: "navbarVisibility",
						isVisible: true
					});
				} else {
					globalNav.style.top = "0px";
					$.event.trigger({
						type: "navbarVisibility",
						isVisible: false
					});
				}
			}
			if (body.scrollTop < initialPos) {
				topbar.style.top = initialPos + "px";
				topbar.style.zIndex = "initial";

				if (ua.toLowerCase().match(/msie/)) {
					topbar.style.position = "static";
				}
				else {
					topbar.style.position = "initial";
				}

				globalNav.removeAttribute("style");
				$.event.trigger({
					type: "navbarVisibility",
					isVisible: true
				});
				readingListTrigger.css("top", topHeaderHeight + globalNav.offsetHeight);
				topbarFixed = false;
				if (screen.width <= smallScreenWidth || screen.width <= mediumScreenWidth) {
					$("body").css("margin-top", "initial");
				}
			}
		}
		else {
			if (body.scrollTop >= topbar.offsetTop) {
				initialPos = topbar.offsetTop;
				topbar.style.position = "fixed";
				topbar.style.top = "0px";
				topbar.style.zIndex = 9999;
				globalNav.style.top = "0px";
				readingListTrigger.css("top", topHeaderHeight);
				// $("div[data-params='region=header']").css("height", topHeaderHeight);
				topbarFixed = true;
				$.event.trigger({
					type: "navbarVisibility",
					isVisible: false || ($(".article-content-flex2019").length > 0 && body.scrollTop <= SCROLL_TOP_HIDE_NAV)
				});
				// If this is a mobile view, add some margin at the article top to make the transition smoother
				if($("div[data-params='region=openx;location=leaderboard']").outerHeight() === 0 || screen.width < smallScreenWidth || screen.width < mediumScreenWidth) {
					$("body").attr("style", "margin-top: " + $(".top-header").outerHeight() + "px");
				}
			}
		}
	});
} else {
	// If there is no ad banner component, the navigation bar can be fixed
	// at the top of the viewport due to no ad-space above. This is to prevent
	// the navigation bar exhibiting jerky behaviour on scroll.
	let fixedTopbar = document.getElementsByClassName("sticky-top-header");

	// Check for topbar (embedded video pages do not have a navbar element)
	if (fixedTopbar && fixedTopbar.length > 0) {
		fixedTopbar[0].style.position = "fixed";
		if($("div[data-params='region=openx;location=leaderboard']").outerHeight() === 0 && screen.width < smallScreenWidth || screen.width < mediumScreenWidth) {
			$("body").attr("style", "margin-top: " + $(".top-header").outerHeight() + "px");
		}
	}
	// Check for an ad banner component on top of the page for the video template
	if ($("div[data-params='region=header']").length > 0) {
		$(window).on("scroll", function(e) {
			let globalNav = $(".global-nav")[0];
			let readingListTrigger = $(".top-header .right.trigger");
			let topHeaderHeight = $(".top-header--bar").outerHeight();
			if($(window).scrollTop() <= SCROLL_TOP_HIDE_NAV) {
				globalNav.style.top = topHeaderHeight + "px";
				readingListTrigger.css("top", topHeaderHeight + globalNav.offsetHeight);
				$.event.trigger({
					type: "navbarVisibility",
					isVisible: true
				});
			} else {
				globalNav.style.top = "0px";
				readingListTrigger.css("top", topHeaderHeight);
				$.event.trigger({
					type: "navbarVisibility",
					isVisible: false
				});
			}
		});
	}
}
$(".top-header--overlay").on("touchstart", function (evt) {
	$(".top-header--mobile-mega-menu").trigger("focus");
});
// Check for a search text field
if ($(".search-form-container").length > 0) {
	var showOrHideClearButton = function (evt) {
		let searchText = $(".search-form-container input[type='search']").val();
		if (searchText === "") {
			$(".clear-search-box").addClass("do-not-show");
		} else {
			$(".clear-search-box").removeClass("do-not-show");
		}
	}
	$(".search-form-container input[type='search']").on("keyup", showOrHideClearButton);

	$(showOrHideClearButton);

	$("a[js-target='clear-search-box']").on("click", function(evt) {
		evt.preventDefault();
		$(".search-form-container input[type='search']").val("");
		$(".clear-search-box").addClass("do-not-show");
	});

}

import utilities from "support/utilities";

var enableCaseSelection = function() {
	utilities.determineIfUsCaDomesticUser(function(isUsCaDomUser) {
		if(isUsCaDomUser) {
			$("[track-target='global-nav-link--case-selection']").removeClass("do-not-show");
		}
	});
}

enableCaseSelection();

if ($(".article-content-flex2019").length > 0) {
	// add the article redesign flag on the main container
	document.getElementsByTagName("body")[0].setAttribute("template-type","article-redesign");
} else if ($(".insight-center-1").length > 0) {
	// add the insight center flag on the main container
	document.getElementsByTagName("body")[0].setAttribute("template-type","insight-center-1");
}

import "support/digital-data/handler/cart";
import "support/digital-data/handler/content-actions";
import "support/digital-data/handler/signin-register";
import "support/digital-data/handler/forms";
import "support/digital-data/handler/page-setting";
