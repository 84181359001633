import React, { Component } from "react";
import { Screen } from "../Authentication/Commons";
import RegistrationForm from "../Authentication/RegistrationForm";

class RegistrationComponent extends Component {
	constructor(props) {
		super(props)
	}
	
	render() {
		return (
			<div class="paywall-content-wrapper small justify-center">
				<div class="auth-content text-center registration-modal">
					<h2 class="title with-mbxs">Create an account</h2>
                    <div class="cta reg">Already have an account? <a href="#" onClick={(ev) => {ev.preventDefault(); this.props.sendTo(Screen.SIGN_IN)}}>Sign in.</a></div>
					<div class="wrapper with-mts">
					    <RegistrationForm 
							sendTo={this.props.sendTo.bind(this)}/>
					</div>
				</div>
			</div>
		)
	}
}

export default RegistrationComponent;
