import Common from "../../common";

const ErrorMessages = {
	FIELD_REQUIRED: "This field is required.",
	INVALID_EMAIL: "Email address is not valid.",
	INVALID_PASSWORD: "Passwords must meet the following criteria: must have at least one number, one lower case letter, one upper case letter, and one special character (@ ! # $ % ^ & + =), no spaces, and be at least 8 characters long",
	CAPTCHA: "This field is required."
}

const validResult = { isValid: true }

function RequiredField(value) {
	if(value == undefined || value == null || value == "" || /^\s*$/.test(value)) {
		return {
			errorMessage: ErrorMessages.FIELD_REQUIRED
		}
	}
	return validResult;
}

function NoSpacesSignIn(value) {
	if (!value.trim().match(Common.SIGN_IN_EMAIL_REGEX)) {
		return {
			errorMessage: ErrorMessages.FIELD_REQUIRED,
		}
	}
	return validResult;
}

function EmailRegularExpresion(value) {
	if (!value.trim().match(Common.EMAIL_REGEX)) {
		return {
			errorMessage: ErrorMessages.INVALID_EMAIL,
		}
	}
	return validResult;
}

function PasswordRegularExpresion(value) {
	if (!value.trim().match(Common.PASSWORD_REGEX)) {
		return {
			errorMessage: ErrorMessages.INVALID_PASSWORD,
		}
	}
	return validResult;
}

function NotNull(value) {
	if(value == null || value == "") {
		return {
			errorMessage: ErrorMessages.CAPTCHA,
		}
	}
	return validResult;
}

function NoValidation(value) {
	return validResult;
}

exports.RequiredField = RequiredField;
exports.NoSpacesSignIn = NoSpacesSignIn;
exports.EmailRegularExpresion = EmailRegularExpresion;
exports.PasswordRegularExpresion = PasswordRegularExpresion;
exports.NotNull = NotNull;
exports.NoValidation = NoValidation;
