<div class="field-container" js-target="share-email">
{% if (!shareNetworkLoggedIn) { %}
	<div class="row collapse">
		<div class="small-2 columns">
			<label for="from-address" class="mbs inline">FROM</label>
		</div>
		<div class="small-10 columns">
			<input type="text" name="from-address" class="mbs" placeholder="Email" js-target="share-from" required="required">
		</div>
	</div>
{% } %}
	<div class="row collapse">
		<div class="small-2 columns">
			<label for="to-address" class="mbs inline">TO</label>
		</div>
		<div class="small-10 columns">
			<input type="text" name="to-address" class="mbs" placeholder="Separate addresses with commas" js-target="share-to" required="required">
		</div>
	</div>

	<label for="custom-message">CUSTOM MESSAGE</label>
	<textarea name="custom-message" rows="4" cols="24" placeholder="Enter your message here" js-target="share-message"></textarea>
</div>


<button class="expand mbn" js-target="share-post">SEND</button>
