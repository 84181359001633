
import $ from "jquery";
import mixinComponent from "../../mixins/component";
import _throttle from "lodash-amd/modern/functions/throttle";
import template from "./template";
import helpBoxService from "services/hbr/help-boxes";
var trigger = require("support/utilities").trigger;
var merge = require("support/utilities").merge;

function offset(el) {
	if (!el) {
		return {top: 0, left: 0, fixed: true};
	}
	else if (el.tagName === "BODY") {
		return {top: 0, left: 0, fixed: false};
	}
	else {
		const o = offset(el.offsetParent);
		return {top: el.offsetTop + o.top, left: el.offsetLeft + o.left, fixed: o.fixed};
	}
}

const boxes = {
	"sign-in-benefits": {
		target: "[data-help-box-target='sign-in-benefits']",
		vertical: "bottom",
		horizontal: "left",
		headline: "Want more out of HBR.org?",
		body: "<a href='/register'>Sign in to get more free content</a> each month and build your own personal library on HBR.org."
	},
	"article-saves": {
		target: "[data-help-box-target='article-saves']",
		vertical: "bottom",
		horizontal: "right",
		headline: "Pressed for time?",
		body: "Tap this folder icon to save this article to your personal library."
	},
	"firmographics": {
		target: "[data-stream-name='settings']",
		vertical: "bottom",
		horizontal: "left",
		headline: "Want To See More Relevant Articles?",
		body: "Tell us a little more about yourself in Account Settings to see articles more relevant to your industry and role."
	},
	"my-library": {
		target: "[data-help-box-target='my-library']",
		vertical: "bottom",
		horizontal: "left",
		headline: "Welcome to HBR!",
		body: "Tap this icon at any time to visit your personal library and account information."
	},
	"insider-newsletter": {
		target: "[data-help-box-target='insider-enl']",
		vertical: "bottom",
		horizontal: "right",
		headline: "",
		body: "Get the most out of your subscription by signing up for The Insider - a weekly subscriber-only newsletter dedicated to giving you the inside scoop on all that HBR has to offer."
	},
	"subscriber-search": {
		target: "[data-help-box-target='search-type-subscription']",
		vertical: "bottom",
		horizontal: "right",
		headline: "Subscriber Search!",
		body: "Turn on \"Subscriber Search\" here to see only content that's free with your subscription."
	}
};

export default function HelpBox($el, $target, box) {
	this.initializeAsComponent($el);
	this.$target = $target;
	this.box = box;
	this.periodicPosition = _throttle(this.position.bind(this), 50);
	this.closeBox = this.close.bind(this);
	this.position();
	$target.on("click", this.closeBox);
	$(window).on("scroll resize", this.periodicPosition);
}

HelpBox.prototype = {
	events: {
		"click [js-target='close-help-box']": "closeButton"
	},

	closeButton: function(ev) {
		ev.preventDefault();
		this.close();
	},

	close: function() {
		this.$el.remove();
		$(window).off("scroll resize", this.periodicPosition);
		this.$target.off("click", this.closeBox);
		trigger("metrics:helpbox_closed", {helpbox: this.box.id});
	},

	position: function() {
		const tpos = offset(this.$target[0]);
		const twidth = this.$target.outerWidth();
		const theight = this.$target.outerHeight();
		const bwidth = this.$el.outerWidth();
		const bheight = this.$el.outerHeight();
		const left = tpos.left + {left: twidth - bwidth, center: (twidth - bwidth) / 2, right: 0}[this.box.horizontal];
		this.$el.css({position: tpos.fixed ? "fixed" : "absolute", left: left, top: tpos.top + (this.box.vertical === "bottom" ? theight + 14 : -bheight - 14)});
		this.$("[js-target='help-box-caret']").css({left: tpos.left - left + twidth / 2 - 14});
	}
};

mixinComponent(HelpBox, "help-box");

HelpBox.render = (id) => {
	const box = merge(boxes[id], {id: id});
	const $target = $(box.target).first();
	if ($target.is(":visible")) {
		trigger("metrics:helpbox_shown", {helpbox: id});
		return new HelpBox($(template({box: box})).appendTo("body"), $target, box);
	}
};

HelpBox.show = () => {
	helpBoxService.evaluate().then((id) => {
		if (id) {
			HelpBox.render(id);
		}
	});
};
