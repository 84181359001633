
import common from "../../common";
import request from "../request";
var BASE_URI = common.API + "user/list/";

function parseURI (listName) {
	return BASE_URI + listName;
}

/**
 * post
 * Adds saved content to a reading list
 * @param {Object} contentObject
 * @returns {*}
 */
exports.post = function (contentObject) {
	return request.ajax("post", parseURI(contentObject.name), contentObject.data);
};

/**
 * get
 * Returns an array of all the reading lists a given item is a member of
 * @param {String} id
 * @returns {*}
 */
exports.get = function (id) {
	// this uses a different endpoint
	return request.ajax("get", common.API + "user/saves/" + encodeURIComponent(id) + "/lists");
};

/**
 * delete
 * Removes saved content from a reading list
 * @param {Object} contentObject
 * @returns {*}
 */
exports.delete = function (contentObject) {
	return request.ajax("DELETE", parseURI(contentObject.name), contentObject.data);
};
