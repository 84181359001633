import React, {Component} from 'react';
import ErrorMessage from "./ErrorMessage";

const justUpdate = function(fieldName, updateValue, event) {
	if(event.hasOwnProperty('preventDefault')) event.preventDefault();
	let fieldValue = event.target.type == "checkbox" ? event.target.checked : event.target.value
	updateValue(fieldName, fieldValue, '')
}

const validateAndUpdate = function(validations, fieldName, updateValue, event) {
	if(event.hasOwnProperty('preventDefault')) event.preventDefault();
	let fieldValue = event.target.type == "checkbox" ? event.target.checked : event.target.value
	validations.some( rule => {
		let result = rule(fieldValue);
		if(!result.isValid) {
			updateValue(fieldName, fieldValue, result.errorMessage)
			return true;
		} else {
			updateValue(fieldName, fieldValue, '')
			return false;
		}
	})
}

function  withFieldValidation(WrappedComponent, ...validations) {
	return function functionComponent(props) {
		let fieldName = props.name
		let fieldValue = props.store((state) => state.form[fieldName])
		let fieldError = props.store((state) => state.errors[fieldName])
		let hasError = fieldError && fieldError != ""
		let { updateValue } = props.store()
		let onChange = hasError ? validateAndUpdate.bind(this, validations, fieldName, updateValue) : justUpdate.bind(this, fieldName, updateValue)
		
		return (
			<>
				<WrappedComponent  
					{ ...props }
					value={fieldValue}
					valid={fieldError == ""}
					onChange={onChange}
					onBlur={validateAndUpdate.bind(this, validations, fieldName, updateValue)}
				/>
				{ hasError && 
					<span class="error-item">
						<span class="icon-alert"></span>
						<span class="error-message">
							{fieldError}
						</span>
					</span>
				}
			</>
			
		)
	}
}

export default withFieldValidation;
