import mixinComponent from "../../mixins/component";

export default function ProductMenu($el) {
	this.initializeAsComponent($el);
}

ProductMenu.prototype = {
	events: {
		"click [js-target='product-menu-item__header']": "showProductItem"
	},

	showProductItem: function(ev) {
		if (this.$(ev.currentTarget).parent().children("div.product-menu-item__content").height() > 0) {
			this.$(ev.currentTarget).parent().removeClass("expand-for-large");
			this.$(ev.currentTarget).parent().addClass("product-menu-item--show");
			this.$(ev.currentTarget).parent().toggleClass("product-menu-item--hide");
		}
		else {
			this.$(ev.currentTarget).parent().removeClass("product-menu-item--hide");
			this.$(ev.currentTarget).parent().addClass("product-menu-item--show");
		}
	}
};

mixinComponent(ProductMenu, "product-menu");