<div class="row">
	<div class="content-area column">
		<div class="stream-item-activity-label ordered text-centered"></div>
		<stream-item class="mvl backdrop-cool-3">
			<h2 class="hed text-center ptl"><i class="icon icon-alert primary-color baseline-shift-down"></i> Site is in maintenance mode.</h2>
			<div class="pbl description-text text-center">We're migrating to our new store! <strong>Ordering and order history will be unavailable between 7:30am and 10am EST.</strong></div>
			<div class="pbl text-center">
				<button class="button button--tight" js-target="add-to-cart-confirmation-close">Continue Shopping</button>
			</div>
		</stream-item>
	</div>
</div>
