
export const Screen = {
	SIGN_IN: "sign-in",
	REGISTRATION: "registration",
	FORGOT_PASSWORD: "forgot-password",
	PROFILE_SETTINGS: "profile_settings"
}

export function isValidScreen(screen) {
	for (const [key] of Object.entries(Screen)) {
		if(Screen[key] == screen)
			return true;
	}
	return false;
}

export function InvalidScreenSelectionException(value) {
	this.value = value;
	this.message = " is not a valid screen";
	this.toString = function() {
		return this.value + this.message;
	};
}
