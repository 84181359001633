import SignIn from "../sign-in/sign-in";
import pianoService from "../../services/piano/piano-service";
import triggerSignInRegisterModal from "../../react/SubscriptionOffers/SignInRegistrationModal.jsx";
import state from "../../services/state";
import $ from "jquery";
import utilities from "../../support/utilities";

export default function initializeGiftRedeemFlow(tpObject) {
	var offeringFlow = new PianoOfferingFlow(tpObject);

	SignIn.signInPromise
		.then(offeringFlow.handleSignInSuccess.bind(offeringFlow));

	tpObject.push(["addHandler", "loginRequired",
		offeringFlow.handleSigninRequired.bind(offeringFlow)]);


	tpObject.push(["addHandler", "checkoutComplete",
		offeringFlow.handleCheckoutComplete.bind(offeringFlow)]);

	// when starting checkout screen, add scrolling to iframe in small screen. This is required in case resize event is not fired.
	tpObject.push(["addHandler", "startCheckout", function (param) {
		// code after the checkout starts
		if (state.isMobile(600)) {
			$(".tp-iframe-wrapper>iframe").attr("scrolling", "yes");
		}
	}]);

	tpObject.push(["addHandler", "checkoutCustomEvent", function(event) {
		var path = event.params.path;
		var navType = event.params.navtype;
		switch(event.eventName) {
		case "link-navigation":
			if ((typeof path != "undefined") && (path.length > 0)) {
				if ((typeof navType != "undefined") && navType == "absolute") {
					window.location.href = path;
				} else {
					window.location.href = "/" + path;
				}
			}
			break;
		}
	}]);

	// event handling whenever piano screen(or state) changes
	tpObject.push(["addHandler", "checkoutStateChange", function (stateView) {
		if (stateView) {
			$("body").trigger("piano:checkoutStateChange", stateView);

			// navigate to home page for sub onboarding flow
			if (stateView.stateName && stateView.stateName == "receipt") {
				$(".tp-close").on("click", function () {
					window.location.href = "/";
				});
			}
		}
	}]);

	// event handling when user selects a term
	tpObject.push(["addHandler", "checkoutSelectTerm", function(termDetails, iframeId) {
		if (termDetails) {
			$("body").trigger("piano:checkoutSelectTerm", termDetails);
		}
	}]);

}

function PianoOfferingFlow(tpObject) {
	this.tpObject = tpObject;
	this.onPageLoad();
	this.bindEvents();
}

PianoOfferingFlow.prototype = {
	initializePianoExperience: function() {
		this.tpObject.push(["init", () => {}]);
	},

	bindEvents: function() {
		const boundFunction = this.setStateToCheckout.bind(this);
		$("body").on("piano:reg-success piano:signin-success", () => boundFunction("CHECKOUT"));
		$("body").on("piano:registration-success", () => boundFunction("CHECKOT"));
	},

	setStateToCheckout: function (state) {
		this.state = state;
	},

	setUserReference: function (pianoUserRef) {
		this.tpObject.push(["setUserRef", pianoUserRef]);
		return pianoUserRef;
	},

	onPageLoad: function() {
		this.state = "INITIAL";
		pianoService.getUserRef()
			.then(this.setUserReference.bind(this))
			.finally(this.initializePianoExperience.bind(this));
	},

	onExternalSignInSuccess: function() {
		if(!this.tpObject.user.isUserValid()) {
			pianoService.getUserRef()
				.then(this.setUserReference.bind(this))
				.then(this.initializePianoExperience.bind(this));
		}
	},

	handleSignInSuccess: function(response) {
		var _this = this;
		pianoService.getUserRef()
			.then((pianoUser) => {
				// once sign in is successfull, add a custom handler so as to message the iframe to do a fast checkout, skipping redundant step.
				_this.tpObject.push(["addHandler", "startCheckout", function (param) {
					document.querySelector(".tp-modal iframe").contentWindow.postMessage("redeem-checkout", "*");
					// code after the checkout starts
					if (state.isMobile(600)) {
						$(".tp-iframe-wrapper>iframe").attr("scrolling", "yes");
					}
				}]);			
				_this.setUserReference(pianoUser);
				
				// configure voucher code if null
				if (!_this.params.voucherCode) {
					var queryParam = location.href.split("voucher_code=")[1];
					if (queryParam) {
						var voucherCode = queryParam.split("&")[0];
						_this.params.voucherCode = voucherCode;
					}
				}
				_this.tpObject.offer.startCheckout(_this.params);
			});
	},

	handleSigninRequired: function(params) {
		this.tpObject.offer.close();
		let container = document.body.appendChild(document.createElement("div"));
		triggerSignInRegisterModal(container, "signin", "", "", window);
		this.params = params;
	},


	handleCheckoutComplete: function(conversion) {
		utilities.setCookie("_pc_pso", conversion.rid, 10);
		// subscriber activation in hbr and set data layer
		pianoService.completeSubscriptionActivation().then(function(subscriber) {
			$("body").trigger("piano:completeSubscriptionActivation", subscriber);
		});

		// set other data layer attributes required on checkout completion
		if (conversion && conversion.uid) {
			$("body").trigger("piano:checkoutComplete", conversion);
			// fetch data layer attributes from Piano which are not available in conversion object
			pianoService.getTransactionDigitalData(conversion.uid).then(function (transactionCompleteData) {
				if (transactionCompleteData && transactionCompleteData.data) {
					$("body").trigger("piano:transactionComplete", transactionCompleteData.data);
				}
			});
		}
		$("body").trigger("piano:subscription-complete");
	}
}
