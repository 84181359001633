/**
 * @module components/stream/stream-list
 * @requires module:moment
 * @requires module:jquery
 * @requires module:common
 * @requires module:mixins/component
 * @requires module:services/stream
 */

// Dependencies
import $ from "jquery";
import moment from "moment";
import _compact from "lodash-amd/modern/arrays/compact";

// Component mixin.
import mixinComponent from "../../../mixins/component";

// Models
import user from "../../../models/user";

// Services.
import stream from "../../../services/stream";
import state from "services/state";

// Utility
import urlParams from "../../../support/urlparams";

// Templates.
import page from "./templates/page";
import spinnerTemplate from "./templates/spinner";

// Types.
import types from "./templates/types";
import vlTypes from "./templates/vl-types";
import icTypes from "./templates/ic-types";
import issue from "./templates/issue";
import maintenanceTemplate from "./templates/maintenance";


export default function StreamList($el) {
	this.initializeAsComponent($el);

	this.pagesLoaded = parseInt(urlParams.getUrlParamValue(window.location.href, "loaded"), 10);
	this.pagesLoaded = (isNaN(this.pagesLoaded)) ? 0 : this.pagesLoaded;

	this.pageNumber = $el.data("page-number") !== undefined ? $el.data("page-number") : this.pagesLoaded ? this.pagesLoaded : 1;

	// default the page size to 8, same as the number
	// of items per page.
	this.pageSize = $el.attr("data-page-size") !== undefined ? 10 : 8;

	let breadcrumbs = document.querySelectorAll(".clear-breadcrumb");
	if (breadcrumbs) {
		for (let index = 0; index < breadcrumbs.length; index++) {
			breadcrumbs[index].addEventListener("click", clearAnalyticServicesBreadcrumb);
		}
	}

	this.$el.on("request:placement", function() {
		if (this.pageNumber === 0 && !this.changeInProgress) {this.load(); }
	}.bind(this));

	var component = this;
	$("body").on("update:placement", function(ev, data) {
		ev.stopImmediatePropagation();

		if (data.skus && data.skus.length > component.pageSize) {
			data.skus = data.skus.slice(0, component.pageSize);
			data.showLoadMore = true;
		}
	});
}

StreamList.prototype = {
	events: {
		"click [js-target='load-ten-more-link']": "loadMore",
		"change [js-target='bulk-action-checkbox']": "bulkUi",
		"click [js-target='reload-stream-list']": "reload",
		"click [js-target='click-pagination']": "paginationLoad"
	},

	bulkUi: function (ev) {
		var $bulkUi = $("[js-target='bulk-discard-flyout'], [js-target='bulk-organize-flyout']");
		$bulkUi.prop("disabled", $("[js-target='bulk-action-checkbox']:checked").length === 0);
	},

	loadMore: function(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		var loaderEntry = $(ev.currentTarget).closest(".stream-entry");
		if (this.isPlacement) {
			var placementEl = $(ev.currentTarget).closest("personalization-placement");
			var placementName = this.placementName || $(placementEl).data().placementName;
			this.updatePlacement(storageService.getCache(placementName));
			loaderEntry.remove();
		} else {
			$(ev.currentTarget).replaceWith(spinnerTemplate());
			this.load().always(function() {loaderEntry.remove(); });
		}
	},

	paginationLoad: function(ev) {
		ev.preventDefault();
		ev.stopPropagation();

		var loaderEntry = $(ev.currentTarget).closest(".stream-entry");
		var activeNumber = $(ev.currentTarget).siblings(".pagination-number.active").text();

		if (this.isPlacement) {
			var placementEl = $(ev.currentTarget).closest("personalization-placement");
			var placementName = this.placementName || $(placementEl).data().placementName;
			this.updatePlacement(storageService.getCache(placementName));
			loaderEntry.remove();
		} else {
			var paginationNumber = $(ev.currentTarget).text();
			paginationNumber = $.trim(paginationNumber);
			if (paginationNumber == "Next") {
				paginationNumber = parseInt(activeNumber);
				paginationNumber++;
			} else if (paginationNumber == "Previous") {
				paginationNumber = parseInt(activeNumber);
				paginationNumber--;
			} else {
				paginationNumber = parseInt(paginationNumber);
			}
			this.paginationNumber(paginationNumber).always(function() {loaderEntry.remove(); });
		}
	},

	change: function(endpoint, params) {
		this.$el.data("stream-endpoint", endpoint);
		this.$el.data("stream-params", params);
		this.pageNumber = 0;
		if (this.changeInProgress !== true) {
			this.load();
		}
	},

	reload: function(ev) {
		if (this.changeInProgress === false) {
			ev.preventDefault();
			this.pageNumber = 0;
			this.load();
		}
	},

	load: function() {
		var endpoint = this.$el.data("stream-endpoint");
		if(endpoint.includes("myhbr/following")) {
			this.setUserName();
		}
		var icFormat = this.$el.data("ic-format");
		this.pagesLoaded++;
		this.changeInProgress = true;
		return stream.loadMore(endpoint, {number: (endpoint.match(/search/) || endpoint.match(/webinars/) || endpoint.match(/visual-library/) || endpoint.match(/hbr-analytic-services/) || endpoint.match(/ascend/)) ? this.pagesLoaded : this.pageNumber, size: this.pageSize},
			_compact([
				this.$el.data("pass-query") ? window.location.search.slice(1) : null,
				this.$el.data("page-context") ? "id=" + this.$el.data("page-context") : null,
				this.$el.data("stream-params")
			]).join("&")
		).always(function(data) {
			var referral = this.getReferralCode();
			var isPaywallEnabled = false;
			var context = "";
			if (window.location.href.indexOf("my-library/library") > -1) {
				context = "library-distinction";
			}
			if (data && data.isPaywallEnabled) {
				isPaywallEnabled = data.isPaywallEnabled;
			}
			this.$("> ul ")[this.pageNumber === 0 ? "html" : "append"](data.maintenanceStatus ? maintenanceTemplate : state.initializeElement($(page({
				hide: data.page.last || !this.$el.data("allow-unlimited") && this.pageNumber >= 2,
				entries: data.entry || [],
				icFormat: icFormat,
				contentType: data.type || (endpoint.match(/following/) ? "topics" : endpoint.match(/user\/history$/) ? "activity" : (endpoint.indexOf("user/saves") !== -1 || endpoint.indexOf("user/list") !== -1) ? (this.$el.data("stream-params") === "newFolderCreated" ? "newFolderCreated" : "library") : (endpoint.match(/ordered/) ? "ordered" : "content")),
				renderEntry: function(entry) {
					entry.endpoint = endpoint;
					entry.category = entry.category || "";
					return (endpoint.match(/magazine/) ? issue : (endpoint.match(/visual-library/) ? vlTypes : icFormat ? icTypes : types))({entry: entry, moment: moment, user: user, referral: referral, isPaywallEnabled: isPaywallEnabled, context: context});
				},
				user: user
			}))));
			this.pageNumber++;
			// if search, replace history
			if (endpoint.match(/search/) || endpoint.match(/webinars/) || endpoint.match(/visual-library/) || endpoint.match(/hbr-analytic-services/) || endpoint.match(/ascend/)) {
				window.history.replaceState(null, this.pagesLoaded, urlParams.setUrlParamValue(window.location.href, "loaded", this.pagesLoaded));
			}
			this.changeInProgress = false;
			var streamListInfo = {};
			streamListInfo.streamEndpoint = this.$el.data("stream-endpoint");
			streamListInfo.dataLength = data.entry ? data.entry.length : 0;
			if (this.$el.data("stream-endpoint").match(/following/)) {
				streamListInfo.topicName = this.$el.data("topic-name");
			}
			$(document).trigger("stream-list:loaded", streamListInfo);
			this.bulkUi();
		}.bind(this));
	},

	paginationNumber: function(number) {
		var endpoint = this.$el.data("stream-endpoint");
		var totalPages = this.$el.data("total-pages");
		var icFormat = this.$el.data("ic-format");
		var location = this.$el.parent().data("stream-name");
		this.pageNumber = number;
		var pagesArray = Array.from({length: totalPages}, (_, i) => i + 1);
		var pages = handlePagination(pagesArray, this.pageNumber, totalPages)
		return stream.loadMore(endpoint, {number: this.pageNumber - 1, size: this.pageSize},
			_compact([
				this.$el.data("pass-query") ? window.location.search.slice(1) : null,
				this.$el.data("page-context") ? "id=" + this.$el.data("page-context") : null,
				this.$el.data("stream-params")
			]).join("&")
		).always(function(data) {
			var referral = this.getReferralCode();
			var isPaywallEnabled = false;
			var context = "";
			if (this.pageNumber === 1 && location == "latest") {
				window.location.href = window.location.href;
			} else {
				this.$("> ul ").empty()[this.pageNumber === 0 ? "html" : "append"](data.maintenanceStatus ? maintenanceTemplate : state.initializeElement($(page({
					hide: true,
					entries: data.entry || [],
					totalPages: totalPages,
					currentPage: this.pageNumber,
					icFormat: icFormat,
					pages : pages,
					contentType: data.type || (endpoint.match(/following/) ? "topics" : endpoint.match(/user\/history$/) ? "activity" : (endpoint.indexOf("user/saves") !== -1 || endpoint.indexOf("user/list") !== -1) ? (this.$el.data("stream-params") === "newFolderCreated" ? "newFolderCreated" : "library") : (endpoint.match(/ordered/) ? "ordered" : "content")),
					renderEntry: function(entry) {
						entry.endpoint = endpoint;
						entry.category = entry.category || "";
						return (endpoint.match(/magazine/) ? issue : (endpoint.match(/visual-library/) ? vlTypes : icFormat ? icTypes : types))({entry: entry, moment: moment, user: user, referral: referral, isPaywallEnabled: isPaywallEnabled, context: context, icFormat: icFormat, location: location});
					},
					user: user
				}))));
				this.changeInProgress = false;
				var streamListInfo = {};
				streamListInfo.streamEndpoint = this.$el.data("stream-endpoint");
				streamListInfo.dataLength = data.entry ? data.entry.length : 0;
				streamListInfo.currentPage = this.pageNumber;
				if (this.$el.data("stream-endpoint").match(/following/)) {
					streamListInfo.topicName = this.$el.data("topic-name");
				}
				$(document).trigger("stream-list:loaded", streamListInfo);
				this.bulkUi();
				$(window).scrollTop(0); 
			}
		}.bind(this));
	},

	/**
	* function that will return the appropriate referral code for current page
	*
	*/
	getReferralCode: function() {
		var REFERRAL_CODE = "03069";
		if (this.$el.data("stream-endpoint").match(/topics/)) {
			return REFERRAL_CODE;
		} else if (this.$el.data("stream-endpoint").match(/search\/more-results/)) {
			return "02749";
		} else {
			var pageContext = this.$el.data("page-context");
			var pageId, pageVars;
			if (pageContext !== undefined && pageContext !== "") {
				pageVars = pageContext.split(".");
				pageId = pageVars[pageVars.length - 1];
				if (pageId.toUpperCase() === "HOME") {
					return REFERRAL_CODE;
				}
			}
		}
		return "";
	},

	setUserName: ()=>{
		user.fetch().then((data)=>{
			if(data) (document.getElementById("user_name").innerHTML=data.firstName);
		});
	}
};

function clearAnalyticServicesBreadcrumb(e) {
	e.preventDefault();
	const ANALYTICS_URL = "/hbr-analytic-services";
	const ASCEND_URL = "/ascend";
	let params = e.currentTarget.attributes["breadcrumb-nav"];
	params = (params) ? params.value : "";
	if (window.location.href.indexOf(ANALYTICS_URL) > -1) {
		window.location.href = ANALYTICS_URL + params + "#browse-reports-filter";
	} else {
		window.location.href = ASCEND_URL + params + "#browse-reports-filter";
	}
}

/**
* function that will handle the pagination progress and positions
*
*/

function handlePagination(pages, currentPage, totalPages) {
	var pagesToShow = [];
	var maxPages = 7;
	// var pages = [...Array(this.state.numberOfPages).keys()].map(number => number + 1);
	var lastpage = pages.length-1;
	var firstpage = 0;       
	var current = currentPage-1;
	const isMobile = $(window).width() < 768;

	if(pages.length > maxPages) {
		// CASES
		// first
		// first 4
		// in between 
		// last 4
		// last
		if(isMobile) {
			switch (true) {
			case current == firstpage:
				pagesToShow = [pages[current],pages[current+1],pages[current+2],"...",pages[lastpage]]
				break;
			case current > firstpage && current < 3:
				if(current == 1) {
					pagesToShow = [pages[firstpage],pages[current],pages[current+1],"...",pages[lastpage]]
				}else if(current == 2) {
					pagesToShow = [pages[firstpage],pages[current-1],pages[current],pages[current+1],"...",pages[lastpage]]
				}
				break;
			case current > 2 && current < lastpage-2:
				pagesToShow = [pages[firstpage],"...",pages[current-1],pages[current],pages[current+1],"...",pages[lastpage]]
				break;
			case current < lastpage && current > lastpage-3:
				if(current == lastpage-2) {
					pagesToShow = [pages[0],"...",pages[current-1],pages[current],pages[current+1],pages[lastpage]]
				}else if(current == lastpage-1) {
					pagesToShow = [pages[0],"...",pages[current-1],pages[current],pages[lastpage]]
				}
				break;
			case current == lastpage:
				pagesToShow = [pages[firstpage],"...",pages[current-2],pages[current-1],pages[current]]
				break;
			default:
				break;
			}
		} else {
			switch (true) {
			case current == firstpage:
				pagesToShow = [pages[current],pages[current+1],pages[current+2],pages[current+3],pages[current+4],"...",pages[lastpage]]
				break;
			case current > firstpage && current < 5:
				if(current == 1) {
					pagesToShow = [pages[firstpage],pages[current],pages[current+1],pages[current+2],pages[current+3],"...",pages[lastpage]]
				}else if(current == 2) {
					pagesToShow = [pages[firstpage],pages[current-1],pages[current],pages[current+1],pages[current+2],"...",pages[lastpage]]
				}else if(current == 3) {
					pagesToShow = [pages[firstpage],pages[current-2],pages[current-1],pages[current],pages[current+1],"...",pages[lastpage]]
				}else if(current == 4) {
					pagesToShow = [pages[firstpage],pages[current-3],pages[current-2],pages[current-1],pages[current],pages[current+1],"...",pages[lastpage]]
				}
				break;
			case current > 4 && current < lastpage-4:
				pagesToShow = [pages[firstpage],"...",pages[current-1],pages[current],pages[current+1],pages[current+2],"...",pages[lastpage]]
				break;
			case current < lastpage && current > lastpage-5:
				if(current == lastpage-4) {
					pagesToShow = [pages[0],"...",pages[current-1],pages[current],pages[current+1],pages[current+2],pages[current+3],pages[lastpage]]
				}else if(current == lastpage-3) {
					pagesToShow = [pages[0],"...",pages[current-2],pages[current-1],pages[current],pages[current+1],pages[current+2],pages[lastpage]]
				}else if(current == lastpage-2) {
					pagesToShow = [pages[0],"...",pages[current-3],pages[current-2],pages[current-1],pages[current],pages[current+1],pages[lastpage]]
				}else if(current == lastpage-1) {
					pagesToShow = [pages[0],"...",pages[current-4],pages[current-3],pages[current-2],pages[current-1],pages[current],pages[lastpage]]
				}
				break;
			case current == lastpage:
				pagesToShow = [pages[firstpage],"...",pages[current-4],pages[current-3],pages[current-2],pages[current-1],pages[current]]
				break;
			default:
				break;
			}	
		}
		pages = pagesToShow;
	}
	return pages
}

mixinComponent(StreamList, "stream-list");
