import common from "../../common";
import request from "../request";

/**
 * Augment the URI based on passed method.
 *
 * @returns {string} A string URI.
 */
exports.getURI = function() {
	return common.API + "newsletters/subscribe";
};

/**
 * delete
 * Removes saved content from a reading list
 * @param {Object} data
 * @returns {*}
 */
exports.delete = function (data) {
	return request.ajax("POST", common.API + "newsletters/unsubscribe", data);
};
