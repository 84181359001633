import React, {Component, useState, useEffect} from 'react';
import CaptchaService, {CaptchaActions, CaptchaKeys} from "../../services/captcha-service";
import withFieldValidation from "../Form/withFieldValidation";
import { NotNull } from './FieldValidators';
import captchaStore from '../../stores/authentication/captcha'

function eventWrapper(value) {
	return {
		target: {
			value: value
		}
	}
}

function CaptchaField(props) {
	const {captchaToken, widgetID, captchaTarget, state} = captchaStore((state) => state)
	const [resetAction, setResetAction] = useState(state == "reset");
	const updateValue = captchaStore((state) => state.updateValue)
	const reset = captchaStore((state) => state.reset)
	const className = props.valid ? "" : "error";
	
	useEffect(() => {
		reset()
		const genCaptchaTarget = `captchaTarget-${Math.random().toString().substring(2, 6)}`
		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if(isEnabled) {
					grecaptcha.enterprise.ready(() => {
						const aWidgetID = grecaptcha.enterprise.render(genCaptchaTarget, {
							sitekey: CaptchaKeys.checkbox,
							callback: (token) => {
								updateValue("captchaToken", token)
								props.onBlur(eventWrapper(token))
							},
							"expired-callback": () => {
								updateValue("captchaToken", null)
								props.onBlur(eventWrapper(null))
							},
						});
						updateValue("widgetID", aWidgetID)
					});
				} else {
					updateValue("widgetID", null)
					updateValue("captchaToken", "*")
					props.onBlur(eventWrapper("*"))
				}
			});
		updateValue("captchaTarget", genCaptchaTarget)
	}, []);
	
	return (
		<div id={captchaTarget} 
			className={`captcha-target ${className}`}>
		</div>
	)
}

export default withFieldValidation(
	CaptchaField,
	NotNull
);
