<div class="cart-items-container has-border-bottom">
{% if (items.length !== 0) { %}
	{% items.forEach(function(item) { %}
		<div class="flex-row {% if (item.name !== items[items.length - 1].name) { %} has-border-bottom {% } %} pbm ptm">
			<div class="flex-1">
				<a href="{{ item.url }}" class="link--black">{{ item.name }}</a> {% if (item.type == "Perm") { %} (Copyright Permissions) {% } %}
				<br/>
				<span class="text-gray-light font-light">{{ item.quantity }}</span>
			</div>
			<div class="mlm">{{ formatter(item.quantity * item.list_price) }}</div>
		</div>
	{% }); %}
</div>
	<div class="flex-row ptl pbs">
		<div class="flex-1 text-gray-light">Total</div><div class="font-bold">${{ subTotal }}</div>
	</div>
	<a href="/shopping-cart" class="button button--tight mbn mtm">View Cart</a>
{% } else { %}
	<div class="message mbs mtm">Your Shopping Cart is empty.</div>
	<a href="/store" class="button button--tight mbn mtm">Visit Our Store</a>
{% } %}
