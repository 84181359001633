import React, { Component } from "react";

export default function(props) {
    if(props.isEnabled) {
        return (
            <div class="loading mbl is-centered">
                <div class="pie rotation"></div>
                <div class="pie filler"></div>
                <div class="mask"></div>
            </div>
        )
    } else {
        return (
            <>
                {props.children}
            </>
        )
    }
}