import $ from "jquery";

(function() {
	$("body").on("add-to-cart:success", setAddToCartInfo);
	$("body").on("remove-from-cart:success", setRemoveFromCartInfo);
	$("body").on("remove-from-cart:success", setShoppingCartInfo);
	$("body").on("update:digital-data-cart", setShoppingCartInfo);
	$("body").on("mini-cart-interaction", setMiniCartInfo);
	$("body").on("update:digital-data-cart-totals", updateCartTotals);

	/**
	 * Set digital data for each item added to cart.
	 *
	 * @param {object} ev - Event object.
	 * @param {object} cartObject - Cart object received from server.
	 * @param {object} itemsAdded - Element data of product added to cart.
	 */
	function setAddToCartInfo (ev, cartObject, itemsAdded) {
		if (window.digitalData && cartObject && itemsAdded) {
			setCartPageInfo(cartObject);

			// empty existing items
			window.digitalData.cart.addItem = [];

			// add new cart items
			$.each(itemsAdded, function (index, itemAdded) {
				setCartDetails(cartObject, itemAdded);
			});
		}
	}

	/**
	 * Set digital data page information related to cart operation.
	 *
	 * @param {object} cartObject - Cart object received from server.
	 */

	function setCartPageInfo (cartObject) {
		// set page name and type
		if (window.digitalData.page && window.digitalData.page.pageInfo) {
			window.digitalData.page.pageInfo.pageName = "Add to Cart Modal";
			window.digitalData.page.pageInfo.pageType = "Modal";
		}

		// set event info
		var eventInfo = {};
		eventInfo.eventName = "Cart Interaction";
		eventInfo.eventAction = "Add to Cart";
		var event = {};
		event.eventInfo = eventInfo;
		window.digitalData.event = [];
		window.digitalData.event.push(event);

		// create cart
		if (!window.digitalData.cart) {
			window.digitalData.cart = {};
		}
		window.digitalData.cart.cartID = cartObject.shoppingCartId;
	}

	/**
	 * Set cart information to digital data.
	 *
	 * @param {object} cartObject - Cart object received from server.
	 * @param {object} itemAdded - Element data of product added to cart.
	 */
	function setCartDetails (cartObject, itemAdded) {
		// get lineitem for the product added
		var lineItemInfo;
		$.each(cartObject.items, function(index, lineItem) {
			if (lineItem.productId.toLowerCase() === itemAdded.productId.toLowerCase() && lineItem.lineType) {
				if(lineItem.lineType.toLowerCase() === itemAdded.type.toLowerCase()) {
					lineItemInfo = lineItem;
					return false;
				}
			}
		});

		if (lineItemInfo) {
			// create product info
			var productInfo = getProductInfo(lineItemInfo);

			// get addition source attribute
			var additionSource = getAdditionSource(itemAdded);

			// create new cart item to be added in digital data
			var newCartItem = createNewCartItem(additionSource, productInfo, lineItemInfo);

			// add the new cart item to digital data
			window.digitalData.cart.addItem.push(newCartItem);
		}
	}

	/**
	 * Create new cart item containing required attributes for digital data.
	 *
	 * @param {object} additionSource - Contains value of where the customer is adding the product to cart from.
	 * @param {object} productInfo - Contains required attributes for digital data.
	 * @param {object} lineItemInfo - Product information received from server.
	 */
	function createNewCartItem (additionSource, productInfo, lineItemInfo) {
		var attributes = {};
		attributes.additionSource = additionSource;
		var newCartItem = {};
		newCartItem.productInfo = productInfo;
		newCartItem.attributes = attributes;
		newCartItem.quantity = {};
		newCartItem.quantity.productQuantity = lineItemInfo.quantity;
		newCartItem.price = {};
		newCartItem.price.basePrice = lineItemInfo.unitPrice;
		if (lineItemInfo.product) {
			newCartItem.category = {};
			newCartItem.category.primaryCategory = lineItemInfo.product.marketingCategory;
			newCartItem.category.subCategory = lineItemInfo.product.primaryCategory;
		}
		return newCartItem;
	}

	/**
	 * Create product information to be added to digital data.
	 *
	 * @param {object} lineItemInfo - Information of the product added to cart.
	 */
	function getProductInfo (shoppingCartItem) {
		var productInfo = {};
		if (shoppingCartItem.product) {
			if (shoppingCartItem.product.availabilities && shoppingCartItem.product.availabilities[0]) {
				productInfo.productID = shoppingCartItem.product.availabilities[0].productId;
			}
			productInfo.productKind = getLineType(shoppingCartItem.lineType);
			productInfo.type = shoppingCartItem.product.contentType;
			productInfo.language = shoppingCartItem.product.language;
		}
		productInfo.productSKU = shoppingCartItem.productId;
		productInfo.productName = shoppingCartItem.title;
		productInfo.format = shoppingCartItem.format;
		return productInfo;
	}

	/**
	 * Finds out where the customer is adding the product to cart from.
	 *
	 * @param {object} itemAdded - Element data of product added to cart.
	 */
	function getAdditionSource (itemAdded) {
		var addToCartElement = itemAdded.component.$el;

		// check for overlay cross sells
		var overlayConfirmation = $(addToCartElement).closest("add-to-cart-confirmation");
		if (overlayConfirmation.length > 0) {
			return "Add to Cart Overlay Cross Sells";
		}

		// check for my library
		if (location.pathname.indexOf("/my-library") >= 0) {
			return "My Library";
		}

		// check for store category page
		if (/store\/([a-zA-Z]+)/.test(location.pathname)) {
			return "Category Page";
		}

		// check for store page
		if (location.pathname.indexOf("/store") >= 0) {
			// check for store page stream lists
			var streamListComponent = $(addToCartElement).closest("stream-list");
			if (streamListComponent.length > 0) {
				var endpoint = streamListComponent.attr("data-stream-endpoint");
				if (endpoint) {
					if (endpoint.indexOf("home_page.store_classics") >= 0) {
						return "Top Products - Classics";
					} else if (endpoint.indexOf("home_page.store_must_read") >= 0) {
						return "Top Products - Must Read";
					} else if (endpoint.indexOf("home_page.store_for_you") >= 0) {
						return "Top Products - For You";
					} else if (endpoint.indexOf("home_page.store") >= 0) {
						return "Top Products - Top This Month";
					}
				}
			}
		}

		// check for product detail page
		if (location.pathname.indexOf("/product/") >= 0) {
			return "Product Detail Page";
		}

		return "Landing Page";
	}

	/**
	 * Set digital data for each item removed from cart.
	 * @param {object} ev - Event object.
	 * @param {object} itemRemoved - Element data of product removed from cart.
	 */
	function setRemoveFromCartInfo (ev, cartData) {
		if (window.digitalData) {
			// set event info
			var eventInfo = {};
			eventInfo.eventName = "Cart Interaction";
			eventInfo.eventAction = "Remove from Cart";
			var event = {};
			event.eventInfo = eventInfo;
			window.digitalData.event = [];
			window.digitalData.event.push(event);

			// set cart id
			if (!window.digitalData.cart) {
				window.digitalData.cart = {};
			}
			var itemRemoved = cartData.itemRemoved;
			window.digitalData.cart.cartID = itemRemoved.cartId;

			// create product info
			var productInfo = {};
			productInfo.productId = itemRemoved.productCoreId;
			productInfo.productKind = itemRemoved.type;
			productInfo.format = itemRemoved.productFormat;
			productInfo.language = itemRemoved.productLanguage;
			productInfo.productSKU = itemRemoved.productId;
			productInfo.productName = itemRemoved.productName;
			productInfo.type = itemRemoved.productContentType;

			// create cart item information for the removed item
			var removedCartItem = {};
			removedCartItem.productInfo = productInfo;
			removedCartItem.quantity = {};
			removedCartItem.quantity.productQuantity = itemRemoved.quantity;
			removedCartItem.price = {};
			removedCartItem.price.basePrice = itemRemoved.unitPrice;
			removedCartItem.category = {};
			removedCartItem.category.primaryCategory = itemRemoved.productMarketingCategory;
			removedCartItem.category.subCategory = itemRemoved.productPrimaryCategory;

			// add the removed cart item to digital data
			if (!window.digitalData.cart.removeItem) {
				window.digitalData.cart.removeItem = [];
			}
			window.digitalData.cart.removeItem[0] = removedCartItem;
		}
	}

	/**
	 * Set shopping cart digital data.
	 * @param {object} ev - Event object.
	 * @param {object} shoppingCartData - Shopping cart data.
	 */
	function setShoppingCartInfo (ev, shoppingCartData) {
		if (window.digitalData && window.digitalData.cart && shoppingCartData && shoppingCartData.cart) {
			var	shoppingCart = shoppingCartData.cart;
			var digitalDataCartObj = window.digitalData.cart;
			if (!digitalDataCartObj.item) {
				digitalDataCartObj.item = [];
			}
			// update cart items
			if (shoppingCart.line_items) {
				// add items to digital data
				var cartItems = shoppingCart.line_items.physical_items.concat(shoppingCart.line_items.digital_items);
				$.each(cartItems, function(shoppingCartItemIndex, shoppingCartItem) {
					shoppingCartItem.currency = shoppingCart.currency && shoppingCart.currency.code ? shoppingCart.currency.code : "USD";
					addItemToDigitalData(shoppingCartItem);
				});
			} else {
				digitalDataCartObj.item = [];
			}
			
			// set cart total
			updateCartTotals(ev, shoppingCart);
			
			// set discount and cart promo
			digitalDataCartObj.price.orderLevelPromoTotal = parseFloat(shoppingCart.discount_amount).toFixed(2);
			digitalDataCartObj.price.discountTotal = parseFloat(shoppingCart.discount_amount).toFixed(2);
			if (shoppingCart.coupons && shoppingCart.coupons.length > 0) {
				digitalDataCartObj.price.orderLevelPromoCode = shoppingCart.coupons[0].code;
			}
		}
	}
	
	/**
	 * Update cart pricing details
	 * @param {object} ev - Event object.
	 * @param {object} shoppingCart - Shopping cart data.
	 */
	function updateCartTotals (ev, shoppingCart) {
		if (window.digitalData && window.digitalData.cart && shoppingCart) {
			var price = {};
			price.basePrice = parseFloat(shoppingCart.base_amount).toFixed(2);
			//price.shipping = parseFloat(shoppingCart.shippingCost).toFixed(2);
			//price.taxAmount = parseFloat(shoppingCart.tax).toFixed(2);
			price.cartTotal = parseFloat(shoppingCart.cart_amount).toFixed(2);
			window.digitalData.cart.price = price;
		}
	}
	
	/**
	 * Returns line type value for provided string.
	 * @param {String} productLineType Line type of the cart item.
	 */
	function getLineType (productLineType) {
		if (productLineType && productLineType.toLowerCase() === "ship") {
		  return "Physical Product";
		} else if (productLineType && productLineType.toLowerCase() === "perm") {
		  return "Copyright Permissions";
		} else {
		  return productLineType;
		}
	}
	
	/**
	 * Add new item to digital data object.
	 * @param {object} shoppingCartItem - Shopping cart item data.
	 */
	function addItemToDigitalData (shoppingCartItem) {
		var newCartItem = {};
		var productInfo = {};
		productInfo.productID = shoppingCartItem.product_id;
		productInfo.productKind = getLineType(shoppingCartItem.lineType);
		productInfo.productURL = shoppingCartItem.url;
		productInfo.productImage = shoppingCartItem.image_url;
		productInfo.productThumbnail = shoppingCartItem.image_url;
		productInfo.productSKU = shoppingCartItem.sku;
		productInfo.productName = shoppingCartItem.name;
		productInfo.format = shoppingCartItem.format;
		newCartItem.productInfo = productInfo;
		if (shoppingCartItem.product) {
			productInfo.type = shoppingCartItem.product.contentType;
			productInfo.language = shoppingCartItem.product.language;
			
			// set publication date
			if (shoppingCartItem.product.publicationDate) {
				var publishedDate = new Date(shoppingCartItem.product.publicationDate);
				attributes.publicationDate = publishedDate.getFullYear() + "-" + ("0" + (publishedDate.getMonth() + 1)).slice(-2) + "-" + ("0" + publishedDate.getDate()).slice(-2);
			}
			// set authors
			var authorsObject = shoppingCartItem.product.authors;
			if (authorsObject && authorsObject.length > 0) {
				attributes.primaryAuthor = authorsObject[0];
				if (authorsObject.length > 1) {
					var authors = [];
					$.each(authorsObject, function(index, author) {
						if (author.toLowerCase() !== attributes.primaryAuthor.toLowerCase()) {
							authors += author;
							if (index < authorsObject.length - 1) {
								authors += "|";
							}
						}
					});
					attributes.author = authors;
				}
			}
			// set categories
			var categoriesObject = shoppingCartItem.product.categories;
			if (categoriesObject && categoriesObject.length > 0) {
				var categories = "";
				$.each(categoriesObject, function(index, category) {
					if (category.toLowerCase() !== shoppingCartItem.product.marketingCategory.toLowerCase()) {
						categories += category;
						if (index < categoriesObject.length - 1) {
							categories += "|";
						}
					}
				});
				attributes.topic = categories;
			}
			newCartItem.attributes = attributes;
			// set primary and sub category
			newCartItem.category = {};
			newCartItem.category.primaryCategory = shoppingCartItem.product.marketingCategory;
			newCartItem.category.subCategory = shoppingCartItem.product.primaryCategory;
		}

		// set item price
		newCartItem.price = {};
		if (shoppingCartItem.unitPrice) {
			newCartItem.price.basePrice = parseFloat(shoppingCartItem.list_price).toFixed(2);
			if (shoppingCartItem.quantity) {
				newCartItem.price.totalPrice = (shoppingCartItem.unitPrice * shoppingCartItem.quantity).toFixed(2);					
			}
		}
		newCartItem.price.currency = shoppingCartItem.currency;
		// set quantity
		newCartItem.quantity = {};
		newCartItem.quantity.productQuantity = shoppingCartItem.quantity;
		window.digitalData.cart.item.push(newCartItem);
	}

	/**
	 * Set mini cart digital data.
	 * @param {object} ev - Event object.
	 * @param {object} cart - Shopping cart data.
	 */
	function setMiniCartInfo(ev, cart) {
		if (window.digitalData) {
			// set page name and type to digital data
			if (window.digitalData.page && window.digitalData.page.pageInfo) {
				window.digitalData.page.pageInfo.pageName = "Mini Cart Modal";
				window.digitalData.page.pageInfo.pageType = "Modal";
			}
			// skip populating cart to digital data in case of shopping cart pages as the cart object is already set in digital data for these pages.
			if (location.pathname.indexOf("/shopping-cart") >= 0 || location.pathname.indexOf("/shipping-payment") >= 0 || location.pathname.indexOf("/review-order") >= 0) {
				return;
			}
			// set cart info to digital data
			if (cart) {
				if (!window.digitalData.cart) {
					window.digitalData.cart = {cartID: cart.id};
				}
				setShoppingCartInfo(ev, {cart: cart});
			}
		}
	}
})();
