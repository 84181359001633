import mixinComponent from "../../mixins/component";
import popularTopicsModel from "../../support/popularTopics";

// Services
import storageService from "../../services/localstorage-util";

// At the moment this component only worries about including the popular
// topic.
import PopularTopic from "./popular-topic/popular-topic";

export default function TopicCirculator($el) {
	this.initializeAsComponent($el);

	var topics = popularTopicsModel.getTopics();
	storageService.setCache("pop_topics", topics);
	PopularTopic.activateAll($el);
}

mixinComponent(TopicCirculator, "topic-circulator");

