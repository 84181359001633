import _bind from "lodash-amd/modern/functions/bind";
import common from "../../common";
import request from "../request";

var API_ENDPOINTS = {
	URL_SHORTENER: common.API + "integration/url/shorten",
	EMAIL_ENGINE: common.API + "user/share"
};

var providers = {
	email: {
		post: sendEmail,
		truncate: _bind(truncateMessage, this, 100000)
	}
};

function sendEmail(contentObject) {
	request.ajax("post", API_ENDPOINTS.EMAIL_ENGINE, contentObject);
}

function truncateMessage(limit, msg) {
	var message = msg;
	if (msg.length >= limit) {
		message = msg.substr(0, limit - 4); // limit - 1 - 3 (ellipsis)
		message = message.substr(0, message.lastIndexOf(" ")) + "&hellip;";
	}
	return message;
}

exports.post = function (contentObject) {
	providers[contentObject.provider].post(contentObject);
};
