
// Components
import issuesModel from "../../support/issues";

// Templates
import monthTemplate from "./templates/month";
import yearTemplate from "./templates/year";

// The component mixin.
import mixinComponent from "../../mixins/component";

export default function FindIssue($el) {
	this.initializeAsComponent($el);
	this.load();
	this.update();
}

// Declare all instance methods for the component here.  This must
// come before the `mixinComponent` call.
FindIssue.prototype = {
	load: function() {
		var component = this;
		var allIssues = issuesModel.getIssues();
		component.issues = allIssues;
		component.$("button").prop("disabled", false);
	},
	update: function(yearIndex) {
		var months = this.issues.year[0].issue;
		if (typeof yearIndex !== "number") {
			this.$(".year-select").html(yearTemplate({years: this.issues.year}));
			this.$(".month-select").html(monthTemplate({months: months, selected: months.length - 1}));
		} else {
			this.$(".month-select").html(monthTemplate({months: this.issues.year[yearIndex].issue}));
		}
	},
	updateForYear: function(event) {
		this.update(this.$(".year-select").prop("selectedIndex"));
	},
	goToIssue: function(event) {
		event.preventDefault();
		location.href = this.$(".month-select").val();
	},
	events: {
		"change .year-select": "updateForYear",
		"submit form": "goToIssue"
	}
};

// Here you register the Component to be decorated.
mixinComponent(FindIssue, "find-issue");

// Export the constructor so that it may be initialized elsewhere.
