/**
 * @module components/sign-in/sign-in
 * @requires module:jquery
 * @requires module:model/user
 * @requires module:services/state
 */

// jquery
import $ from "jquery";

// user model
import user from "../../models/user";

// state service
import state from "../../services/state";

// Component mixin.
import mixinComponent from "../../mixins/component";

import CaptchaService, {CaptchaActions, CaptchaKeys} from "../../services/captcha-service"

import common from "../../common"
import utilities from "support/utilities";

const captchaTarget = document.getElementById("captchaTargetSignIn");
let captchaToken;
let captchaWidgetID;

if (captchaTarget) {
	CaptchaService.isCaptchaEnabled()
		.then((isEnabled) => {
			if (isEnabled) {
				grecaptcha.enterprise.ready(() => {
					captchaWidgetID = grecaptcha.enterprise.render(captchaTarget, {
						sitekey: CaptchaKeys.checkbox,
						callback: (token) => {
							captchaToken = token;
						},
						"expired-callback": () => {
							captchaToken = null;
						},
					});
				});
			}
		});
}

// global authentication promise
var signInPromise = $.Deferred();

// constructor
export default function SignIn($el) {
	this.initializeAsComponent($el);
}

// events
SignIn.prototype = {
	events: {
		"click [js-target='submit-sign-in']": "signIn",
	},

	signIn: function(e) {
		e.preventDefault();
		e.stopPropagation();

		const signIn = (token) => {
			// pull the username and password from the form
			var username = this.$el.find("input[js-target='username-input']").val();
			var password = this.$el.find("input[js-target='password-input']").val();
			let signInButton = this.$el.find("button[js-target='submit-sign-in']");

			// set the pending state
			state.set("guest:sign-in:pending");
			// set success text on button prior to displaying, after auth callback it takes 1 second to update on DOM
			signInButton.text("Success, you will now be redirected");

			// submit the authentication
			user
				.authenticate(username, password, token)
				.then(function(user) {
					// resolve the sign in promise
					signInPromise.resolve(user);
					window.location.reload();
				}, function(data) {
					// reset the state to failed and reset button text
					signInButton.text("Sign In");
					state.set("guest:sign-in:fail");
					let error = common.ERROR_USER_PASSWORD.substring(0,77);;
					if (data.responseJSON.hasOwnProperty(common.LOGIN_TRIES_LEFT)) {
						error = common.ERROR_USER_PASSWORD.replace("%s", data.responseJSON.tries_left);
						if(data.responseJSON.tries_left === 1) error = error.replace("tries", "try");
					} else if (data.responseJSON.hasOwnProperty(common.ACCOUNT_LOCKOUT_DURATION)) {
						error = common.ERROR_ACCOUNT_LOCK.replace("%s", utilities.getUnlockAccountTime(data.responseJSON.lockout_duration));
					}
					document.getElementById("error-message").innerHTML = error;
					utilities.trigger("metrics:signin_fail");
				});
			$("body").trigger("form-interaction", ["sign-in", "Form Submission"]);
		}

		CaptchaService.isCaptchaEnabled()
			.then((isEnabled) => {
				if (isEnabled) {
					const captchaError = document.getElementById("captchaError");
					captchaError.classList.add("is-hidden");

					if (!captchaToken) {
						captchaError.classList.remove("is-hidden");
						return null;
					}

					grecaptcha.enterprise.ready(() => {
						grecaptcha.enterprise.execute(CaptchaKeys.score, {action: CaptchaActions.SIGN_IN});
					});
					signIn(captchaToken);
					grecaptcha.enterprise.reset(captchaWidgetID);
					captchaToken = null;
				} else {
					signIn();
				}
			});
	}
};

// Decorate this constructor as a component.
mixinComponent(SignIn, "sign-in");

// expose the authentication promise
SignIn.signInPromise = signInPromise;

// export the component
